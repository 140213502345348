import React from 'react'
import { ApiMeResponseT } from 'services/Session/context'
import { MeObject } from 'types'
import { initializeDataDogDatadogUser } from 'utils/datadog'
import { isNativeNoOverride } from './platform'

interface ISetSessionDataProps {
  data: MeObject | undefined
  update: (value: React.SetStateAction<ApiMeResponseT>) => void
  setPushActiveOnDevice: (value: React.SetStateAction<boolean>) => void
}

export const setSessionData = ({ data, update, setPushActiveOnDevice }: ISetSessionDataProps) => {
  if (!data) return

  const sessionData: ApiMeResponseT = {
    auth: data.auth,
    phone: data.user.phoneNumber,
    name: `${data.user.firstName} ${data.user.lastName}`,
    personIds: data.personIds,
    personsAndPermissions: data.personsAndPermissions,
    user: data.user,
  }

  update(sessionData)
  initializeDataDogDatadogUser(data.user.userId)

  if (isNativeNoOverride()) {
    setPushActiveOnDevice(false)
  }
}
