import React from 'react'
import ArrowButtonGroup from 'redesign/components/atoms/ArrowButtonGroup'
import { Button } from './styles'
import type { CalendarTodayButtonProps } from './types'

const CalendarTodayButton: React.FC<CalendarTodayButtonProps> = ({
  handlePreviousButton,
  handleNextButton,
  handleToday,
  label,
  hideArrows,
}) => (
  <ArrowButtonGroup
    handlePreviousButton={handlePreviousButton}
    handleNextButton={handleNextButton}
    hideArrows={hideArrows}
  >
    <Button onClick={handleToday} variant="text">
      {label}
    </Button>
  </ArrowButtonGroup>
)

export default CalendarTodayButton
