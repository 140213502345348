import { useCallback, useEffect, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getCurrentDate } from 'pages/AbsencePlanner/components/MonthSwitcherBar/MonthSelector/getCurrentDate'
import { formatDate } from 'redesign/shared/date'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import type { MonthSwitcherProps } from './types'

export const useMonthSwitcher = ({ baseDate, onChange, monthIndex }: Omit<MonthSwitcherProps, 'handleToday'>) => {
  const [datePickerDate, setDatePickerDate] = useState<Date>(baseDate)
  const { t, locale } = useFavurTranslation()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const onMonthChange = useCallback(
    (date: Date) => {
      setDatePickerDate(date)
      onChange(date)
    },
    [onChange],
  )
  const monthLabel = formatDate({ date: datePickerDate, locale, t, dateFormat: DATE_FORMAT_VARIANTS.monthOnly })
  const todayLabel = t('page.shifts.content.today')

  useEffect(() => {
    setDatePickerDate(getCurrentDate(baseDate, monthIndex))
  }, [baseDate, monthIndex])

  return {
    onMonthChange,
    monthLabel,
    datePickerDate,
    setDatePickerDate,
    todayLabel,
    isSmallScreen,
  }
}
