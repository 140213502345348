import React, { useCallback, useContext } from 'react'
import { SimpleLoading } from 'components/LoadingIcon'
import usePSN from 'hooks/usePushNotifications'
import useSessionContext from 'hooks/useSessionContext'
import useUserLanguage from 'hooks/useUserLanguage'
import { validationQuery } from 'pages/EntryPoint/queries'
import { useHistory } from 'react-router'
import FlashMessagesContext from 'services/FlashMessages/context'
import routes from 'services/RoutesProvider/routes'
import { generateRedirectUrl, isCmsRedirect } from 'services/RoutesProvider/utils'
import type { AuthValidateResponse } from 'types'
import { setSessionData } from 'utils/login'
import { useMutation } from '@apollo/client'
import { modules } from '../../../../shared/constants'
import ValidateComponent from '../../components/ValidateComponent/AutofillSmsCode'
import type { ILoginStepProps } from '../types'

const hasAccessToCms = (data: AuthValidateResponse | null | undefined) => {
  if (!data?.authValidate?.personsAndPermissions) return false

  return data.authValidate.personsAndPermissions.some((person) => person.modules?.includes(modules.cms))
}

const Validate: React.FC<ILoginStepProps> = ({ setStep }) => {
  const history = useHistory()
  const { update } = useSessionContext()
  const goBack = () => setStep('START')
  const { setPushActiveOnDevice } = usePSN({ refresh: false })
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const [mutate, { loading }] = useMutation<AuthValidateResponse>(validationQuery)
  const { setUserLanguage } = useUserLanguage()
  const onConfirm = useCallback(
    async (validationCode: string, rememberMe: boolean) => {
      const queryParams = new URLSearchParams(location.search)

      try {
        removeAll()
        const mutationResult = await mutate({ variables: { validationCode, rememberMe } })
        const { data } = mutationResult

        setSessionData({ data: data?.authValidate, update, setPushActiveOnDevice })
        setUserLanguage(data?.authValidate?.user.language ?? null)

        if (hasAccessToCms(data) && isCmsRedirect(queryParams)) {
          history.push(generateRedirectUrl(queryParams))
        } else {
          history.push(routes.dashboard)
        }
      } catch (_error) {
        setFlashMessage('login.validate.error.validationCodeNoMatch')
      }
    },
    [removeAll, mutate, update, setPushActiveOnDevice, setUserLanguage, history, setFlashMessage],
  )

  if (loading) {
    return <SimpleLoading />
  }

  return <ValidateComponent backButton={goBack} withRememberMe onConfirm={onConfirm} />
}

export default Validate
