import { shiftTypes } from 'constants/shift'
import { TFunction } from 'i18next'
import { groupBy, sortBy, uniq } from 'lodash/fp'
import { TeamplanDetailT } from 'pages/Shifts/TeamplanDetailPage/types'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { PlannedShiftT, ShiftsByTenantCCT, TenantT } from 'types'
import { TeamplanDayT, TeamplanPersonsCostCenterT, TeamplanPersonsT } from 'types/teamplan'
import { getTodayFormatted, newDateWithoutTime } from 'utils/date'
import { shiftText, sortPlannedShifts } from 'utils/shifts'
import { ShiftsByDateT, ShiftUpdateStatus } from '../types'
import { sortTeamPlanShifts } from './ShiftCard/TeamsSection/utils'
import { IEffectiveShiftProps, TeamplanCostCenterT } from './types'

const prepareAbsences = (absenceList: PlannedShiftT[]) => {
  const groupByCompositeKey = (absence: PlannedShiftT) => `${absence.personId}-${absence.timeOfDay}`

  const groupedAbsences = absenceList.reduce((acc, absence) => {
    const key = groupByCompositeKey(absence)
    return {
      ...acc,
      [key]: acc[key] ? [...acc[key], absence] : [absence],
    }
  }, {} as { [key: string]: PlannedShiftT[] })

  return Object.values(groupedAbsences).map((absenceGroup: PlannedShiftT[]) => absenceGroup[0])
}

const prepareShifts = (shiftList: PlannedShiftT[]) => {
  const groupedByTenantAndDescription = Object.values(groupBy('personId', shiftList))
    .map((list) => Object.values(groupBy('shortDescription', list)).map((byDesc) => byDesc[0]))
    .flat()
  return sortBy(['personId', 'from', 'until'], groupedByTenantAndDescription)
}

export const prepareShowingShifts = (shiftList: PlannedShiftT[]) => {
  const absences = shiftList.filter((shift) => shift.type === shiftTypes.absence)
  const shifts = shiftList.filter((shift) => shift.type !== shiftTypes.absence)

  const preparedShifts = [...prepareShifts(shifts), ...prepareAbsences(absences)]

  return Object.values(groupBy('personId', preparedShifts))
    .map((shiftsGrouped) => sortPlannedShifts(shiftsGrouped))
    .flat()
}

export const getTaskIds = (shifts: ShiftsByDateT[]) => {
  const ids = shifts
    .flatMap((date) =>
      date.tenants.flatMap((tenant) => tenant.shifts.map((shift) => (shift as PlannedShiftT).updateTaskId)),
    )
    .filter((taskId) => Boolean(taskId)) as number[]
  return uniq(ids)
}

export const hasAcknowledgedShiftUpdate = (shift: PlannedShiftT, shitUpdateTasks: TaskT<ShiftUpdateTaskT>[]) => {
  const isPast = Boolean(
    shift.date && newDateWithoutTime(shift.date, 'yyyy-MM-dd') < newDateWithoutTime(getTodayFormatted(), 'yyyy-MM-dd'),
  )

  const task: TaskT<ShiftUpdateTaskT> | undefined = shitUpdateTasks.find(
    (updateTask) => `${updateTask.id}` === `${shift.updateTaskId}`,
  )

  return isPast || task === undefined || task.status === ShiftUpdateStatus.reviewFrontlineSeen
}

export const getShiftText = (
  t: TFunction,
  { isMultiTenant, shift }: Pick<IEffectiveShiftProps, 'isMultiTenant' | 'shift'>,
) => {
  const shiftTypeLabel = shiftText(t, shift.timeType)

  if (isMultiTenant && shift.type === shiftTypes.absence) {
    return `${t('page.shifts.content.absence')}: ${shiftTypeLabel}`
  }

  return shiftTypeLabel
}

export const getCurrentTeamPlan = (
  loading: boolean,
  teamplanData: TeamplanDayT,
  date: string,
  tenantId: string,
): 'loading' | TeamplanPersonsT[] | undefined => {
  if (loading) return 'loading'
  if (!teamplanData || !teamplanData[date] || !teamplanData[date][tenantId]) return undefined
  return teamplanData[date][tenantId]
}

export const anyTeamplanData = (teamplanData: TeamplanDayT): boolean => {
  const days = Object.values(teamplanData)
  return days.some((day) => {
    const teams = Object.values(day)
    return teams.length > 0
  })
}

export const anyEffectiveShifts = (effectiveShifts: ShiftsByDateT[]): boolean => {
  return effectiveShifts.length > 0
}

export type TenantByIdT = {
  [tenantId: string]: TenantT
}

export const getTenantsList = (
  shifts: ShiftsByDateT[],
  teamplanTenants: TenantT[],
  effectiveShifts: ShiftsByDateT[],
): TenantByIdT => {
  const shiftTenants =
    shifts && shifts.length > 0
      ? shifts
          .flatMap((shift) => shift.tenants)
          .map((tenant) => ({ id: tenant.tenantId, name: tenant.tenantName } as TenantT))
      : []
  const effectiveShiftTenants =
    effectiveShifts && effectiveShifts.length > 0
      ? effectiveShifts
          .flatMap((shift) => shift.tenants)
          .map((tenant) => ({ id: tenant.tenantId, name: tenant.tenantName } as TenantT))
      : []
  return [...shiftTenants, ...teamplanTenants, ...effectiveShiftTenants].reduce((acc, tenant) => {
    return acc[tenant.id] !== undefined ? acc : { [tenant.id]: tenant, ...acc }
  }, {} as TenantByIdT)
}

export const sortTenantsListToIds = (tenantList: TenantByIdT, shifts: Map<string, ShiftsByTenantCCT[]>) => {
  const onlyAbsenceTenants = Object.values(tenantList).filter((tenant) => {
    const tenantShifts = shifts.get(tenant.id) ?? []
    return tenantShifts.every((tenantShiftList) =>
      tenantShiftList.shifts.every((shift) => shift.type === shiftTypes.absence),
    )
  })

  const sortedOnlyAbsenceTenantsIds = sortBy('name', onlyAbsenceTenants).map((tenant) => tenant.id)
  const otherTenants = Object.values(tenantList).filter((tenant) => !sortedOnlyAbsenceTenantsIds.includes(tenant.id))
  const sortedOtherTenantIds = sortBy('name', otherTenants).map((tenant) => tenant.id)

  return [...sortedOtherTenantIds, ...sortedOnlyAbsenceTenantsIds]
}

export const groupCostCentersByTenant = (tenants: ShiftsByTenantCCT[]) =>
  tenants.reduce((acc, tenant) => {
    const currentTenants = acc.get(tenant.tenantId)
    if (currentTenants) return acc.set(tenant.tenantId, [...currentTenants, tenant])
    return acc.set(tenant.tenantId, [tenant])
  }, new Map<string, ShiftsByTenantCCT[]>())

export const findEffectiveShiftListByTenant = (
  effectiveShifts: ShiftsByDateT[],
  effectiveShiftLoading: boolean,
  tenantId: string,
) => {
  if (effectiveShiftLoading) {
    return undefined
  }

  if (effectiveShifts.length === 0) {
    return []
  }

  return effectiveShifts[0].tenants.filter((tenant) => tenant.tenantId === tenantId)
}

export const getAllCostCenterFromTeamplan = (teamplanData: TeamplanDayT) => {
  return Object.values(teamplanData)
    .flatMap((teamplanDay) => Object.values(teamplanDay))
    .flat()
    .filter((teamplanPerson) => teamplanPerson.type === 'cost_center')
    .map((teamplanPerson) => {
      const tpcc = teamplanPerson as TeamplanPersonsCostCenterT
      return {
        id: tpcc.id,
        name: tpcc.name,
        tenantName: tpcc.tenantName,
      } as TeamplanCostCenterT
    })
}

export const getTeamPlanDetail = (
  loading: boolean,
  teamplan: TeamplanDayT,
  currentDay: string,
  tenantId: string,
  teamId: string,
): TeamplanDetailT => {
  const tenantTeamplan = getCurrentTeamPlan(loading, teamplan, currentDay, tenantId)
  const { attendances, otherAbsences, vacationAbsences } = sortTeamPlanShifts(tenantTeamplan)

  return {
    attendances: attendances[teamId] ?? [],
    otherAbsences: otherAbsences[teamId] ?? [],
    vacationAbsences: vacationAbsences[teamId] ?? [],
  }
}
