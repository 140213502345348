import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { cardVariants } from 'constants/componentsBasics'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { UserAnnouncement } from 'shared/graphql/graphql'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { classes } from './styles'
import { getBody, getTitle } from './utils'

interface IAnnouncementCardProps {
  announcement: UserAnnouncement
  onClick?: () => void
}

const AnnouncementCard: React.FC<IAnnouncementCardProps> = ({ announcement, onClick }) => {
  const { language } = useFavurTranslation()
  const isNew = !announcement.seen
  const createdAt = announcement.insertedAt ?? ''
  const title = useMemo(() => getTitle(announcement, language), [announcement, language])
  const body = useMemo(() => getBody(announcement, language), [announcement, language])

  return (
    <Box data-testid={`announcement_${announcement.id}`} sx={classes.root} onClick={onClick}>
      <Card {...(isNew ? { variant: cardVariants.highlight } : {})}>
        <Box sx={classes.container}>
          <Box sx={classes.bodyContainer}>
            <Box sx={classes.titleContainer}>
              <Typography variant="subtitle2" sx={classes.title}>
                {title}
              </Typography>
            </Box>
            <Box sx={classes.textBodyContainer}>
              <Typography variant="body2" sx={classes.body}>
                {body}
              </Typography>
            </Box>
            <Typography variant="caption" sx={classes.date}>
              {getFormattedFromISOString(createdAt, uiDateFormat, undefined)}
            </Typography>
          </Box>
          <Box sx={classes.dotContainer}>{isNew && <Box sx={classes.newDot} />}</Box>
        </Box>
      </Card>
    </Box>
  )
}

export default AnnouncementCard
