import { Button, IconButton, css, styled } from '@mui/material'

export const MainContainer = styled('nav')`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.hamburgerHeader};
  height: ${({ theme }) => theme.spacing(6)};
  position: relative;
`

export const NavContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showLogo',
})<{
  showLogo: boolean
}>`
  display: flex;
  justify-content: ${({ showLogo }) => (showLogo ? 'space-between' : 'right')};
  align-items: center;
  max-width: ${({ theme }) => theme.spacing(75)};
  margin: 0 auto;
  width: 100%;

  ${({ theme }) =>
    theme &&
    css`
      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(0, 2)};
      }
    `};
`

export const FavurButton = styled(Button)`
  padding: 0;
`

export const HamburgerMenuButton = styled(IconButton)`
  padding: 0;
`
