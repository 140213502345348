import { SvgIcon, styled } from '@mui/material'
import { convertPxToMuiSpacing } from 'redesign/themes/main/utils'

export const Svg = styled(SvgIcon, {
  shouldForwardProp: (prop) =>
    prop !== 'width' && prop !== 'height' && prop !== 'fill' && prop !== 'size' && prop !== 'rotate',
})<{
  width?: number
  height?: number
  size?: number
  fill?: string
  rotate?: number | string
}>`
  fill: ${({ fill }) => fill || 'currentcolor'};
  width: ${({ size, width, theme }) => theme.spacing(convertPxToMuiSpacing((width || size) ?? 0))};
  height: ${({ size, height, theme }) => theme.spacing(convertPxToMuiSpacing((height || size) ?? 0))};
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : 'none')};
  transition: ${({ theme }) =>
    theme.transitions.create(['transform'], { duration: theme.transitions.duration.standard })};
`
