import React from 'react'
import SwitchFormGroup from 'redesign/components/molecules/SwitchFormGroup'
import { Container } from './styles'
import { useGeneralSettings } from './useGeneralSettings'

const GeneralSettings: React.FC = () => {
  const { options, headlineTitle } = useGeneralSettings()

  return (
    <Container>
      <SwitchFormGroup legend={headlineTitle} options={options} />
    </Container>
  )
}

export default GeneralSettings
