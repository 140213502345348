import React, { useEffect, useState } from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import { AuthenticationKind } from 'constants/authentication'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useSessionContext from 'hooks/useSessionContext'
import Background from './Background'
import SubHeader from './SubHeader'
import NewToolbar from './Toolbar'
import { classes } from './styles'

type HeaderProps = {
  header?: React.ReactElement | string | null
  banner?: React.ReactElement | string | null
  navigate: boolean
  toggleDrawer: () => void
  showHeader?: boolean
  hideBackground?: boolean
  hideWebToolbar?: boolean
  hideHamburger?: boolean
  growSubheader?: boolean
  sxHeader?: SxProps<Theme>
}

const Header: React.FC<HeaderProps> = ({
  showHeader,
  header,
  banner,
  navigate,
  toggleDrawer,
  hideBackground,
  hideWebToolbar,
  hideHamburger,
  growSubheader,
  sxHeader,
}) => {
  const { auth } = useSessionContext()
  const [showBackground, setShowBackground] = useState(true)
  const redesignFF = Boolean(useFeatureFlag(FF_FAVUR_REDESIGN))

  useEffect(() => {
    if (hideBackground) {
      setShowBackground(hideBackground && navigate)
    } else {
      setShowBackground(true)
    }
  }, [navigate, hideBackground, setShowBackground])

  if (auth < AuthenticationKind.AUTHENTICATED && !showHeader) {
    return (
      <>
        <Box sx={classes.bannerUnauthenticated}>{banner}</Box>
        <Background menu={navigate} opaque />
      </>
    )
  }

  return (
    <Box sx={classes.root}>
      {showBackground && <Background menu={navigate && !redesignFF} />}
      <NewToolbar
        auth={auth}
        navigate={navigate}
        toggleDrawer={toggleDrawer}
        hideWebToolbar={hideWebToolbar}
        hideHamburger={hideHamburger}
      />
      {banner}
      {header && <SubHeader header={header} growSubheader={growSubheader} sx={sxHeader} />}
    </Box>
  )
}

export default Header
