import React from 'react'
import { FilterProvider } from 'components/Filter/context'
import { NewAbsenceButton } from 'redesign/components/atoms/Buttons/NewAbsenceButton'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import { DrawerHeaderWrapper } from 'redesign/components/molecules/DrawerHeaderWrapper'
import SideMenuHeader from 'redesign/components/organisms/Headers/SideMenuHeader'
import { SideMenuTaskListContent } from 'redesign/components/organisms/SideMenuTaskListContent'
import Filters from '../../Filters'
import { SIDE_MENU_ROOT_ID } from '../../SideMenu/constants'
import type { MenuPageProps } from '../../SideMenu/types'
import TaskDetail from '../TaskDetail'
import { useAbsences } from './useAbsences'

const PersonalAbsences: React.FC<MenuPageProps> = ({ onClose, setPage, isDrawer, t, ...props }) => {
  const {
    configuration,
    configLoading,
    headerBackgroundColor,
    initialFilters,
    onTaskClick,
    selectedTaskUuid,
    setSelectedTaskUuid,
  } = useAbsences({ isOffice: false })
  const footer = isDrawer ? null : <NewAbsenceButton t={t} onClick={() => setPage('newAbsenceRequest')} />

  if (configLoading) return <LoadingSpinner />

  if (selectedTaskUuid) {
    return (
      <TaskDetail
        {...props}
        onGoBack={() => setSelectedTaskUuid(null)}
        onClose={() => {
          setSelectedTaskUuid(null)
          onClose()
        }}
        t={t}
      />
    )
  }

  return (
    <>
      <FilterProvider configuration={configuration} name="personal_absence_list">
        <DrawerHeaderWrapper
          header={
            <SideMenuHeader
              titleKey="absencePlanner.taskbar.frontlinerTasks.title"
              onClose={onClose}
              isDrawer={Boolean(isDrawer)}
              setPage={setPage}
            />
          }
          footer={footer}
          backgroundColor={headerBackgroundColor}
        >
          <SideMenuTaskListContent initialFilters={initialFilters} onTaskClick={onTaskClick} />
        </DrawerHeaderWrapper>
        <Filters rootId={SIDE_MENU_ROOT_ID} />
      </FilterProvider>
    </>
  )
}

export default PersonalAbsences
