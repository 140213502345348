import React from 'react'
import { CheckBoxRounded, IndeterminateCheckBoxRounded } from '@mui/icons-material'
import { FormControlLabel } from '@mui/material'
import { Checkbox as MuiCheckbox } from '@mui/material'
import { Label } from './styles'
import type { CheckboxProps } from './types'

const Checkbox: React.FC<CheckboxProps> = ({ value, label, labelPlacement, dataTestId, ...rest }) => (
  <FormControlLabel
    value={value}
    control={
      <MuiCheckbox
        {...rest}
        indeterminateIcon={<IndeterminateCheckBoxRounded />}
        checkedIcon={<CheckBoxRounded />}
        data-testid={dataTestId}
      />
    }
    label={<Label variant="body1">{label}</Label>}
    labelPlacement={labelPlacement}
  />
)

export default Checkbox
