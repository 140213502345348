import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsSizeOverrides {
    large: true
  }
}

const variants: ComponentsVariants['MuiCheckbox'] = [
  {
    props: {
      size: 'large',
    },
    style: {
      height: '2.5rem',
      width: '2.5rem',
      padding: '0.5rem',
      '> .MuiSvgIcon-fontSizeLarge': {
        height: '1.75rem',
        width: '1.75rem',
      },
    },
  },
]

const styleOverrides: ComponentsOverrides<Theme>['MuiCheckbox'] = {
  sizeMedium: ({ theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
    '> .MuiSvgIcon-fontSizeMedium': {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }),
  sizeSmall: ({ theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
    '> .MuiSvgIcon-fontSizeSmall': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  }),
}

const MuiCheckbox = {
  variants,
  styleOverrides,
}

export default MuiCheckbox
