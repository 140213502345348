import React from 'react'
import AbsencePlanner from 'pages/AbsencePlanner'
import BalancesPage from 'pages/Balances'
import ContentIndexEvent from 'pages/Cms/EventsList'
import ContentIndexNewsletter from 'pages/Cms/NewsletterList'
import ContentOverviewPage from 'pages/Cms/OverviewPage'
import ContentIndexPage from 'pages/Cms/PagesList'
import RedirectCmsPage from 'pages/Cms/Redirect'
import ContentViewPage from 'pages/Cms/View'
import ContactPage from 'pages/Contact'
import DocumentsPage from 'pages/Documents'
import EmployeeCardPage from 'pages/EmployeeCard'
import InvitationPage from 'pages/EntryPoint/InvitationPage'
import LoginPage from 'pages/EntryPoint/LoginPage'
import ForgotPhonePage from 'pages/ForgotPhone'
import RequestEmployeeData from 'pages/RequestEmployeeData'
import SecureConnectionProcessPage from 'pages/SecuritySetup/SecureConnection'
import SuccessProcess from 'pages/SecuritySetup/SecureConnection/SuccessProcess'
import SettingsPage from 'pages/Settings'
import ShiftsPage from 'pages/Shifts'
import FrontlinerTasks from 'pages/Tasks'
import DetailsAbsenceRequestPage from 'pages/Tasks/AbsenceRequest/Details'
import NewAbsenceRequest from 'pages/Tasks/AbsenceRequest/New'
import MonthlySheetPage from 'pages/Tasks/MonthlySheet'
import SecureConnectionDetails from 'pages/Tasks/SecureConnection'
import UserDataDetails from 'pages/Tasks/ShareUserData/Details'
import EmployersSelectionPage from 'pages/Tasks/ShareUserData/EmployersSelectionPage'
import ShiftUpdateDetails from 'pages/Tasks/ShiftUpdate/Details'
import TeamsPage from 'pages/Teams'
import TeamPage from 'pages/Teams/TeamPage'
import MemberDetail from 'pages/Teams/TeamPage/Tabs/Members/Detail'
import EditMembers from 'pages/Teams/TeamPage/Tabs/Members/Edit'
import EditPermissionMembers from 'pages/Teams/TeamPage/Tabs/Rights/Edit'
import EditTeamSettings from 'pages/Teams/TeamPage/Tabs/Settings/Edit'
import PersonDetail from 'pages/Teams/components/TeamsList/PersonDetail'
import PersonList from 'pages/Teams/components/TeamsList/PersonList'
import SuccessDeleteUserPage from 'pages/UserSettings/pages/DeactivateAccount/Confirm/SuccessDeleteUserPage'
import EmployerPage from 'pages/UserSettings/pages/Employer'
import EmployersPage from 'pages/UserSettings/pages/Employers'
import EmployerPersonTeamPage from 'pages/UserSettings/pages/Team'
import EmployerPersonTeamsPage from 'pages/UserSettings/pages/Teams'
import TenantPage from 'pages/UserSettings/pages/Tenant'
import TenantReminderListPage from 'pages/UserSettings/pages/TenantReminderList'
import TenantRemindersPage from 'pages/UserSettings/pages/TenantReminders'
import TenantsPage from 'pages/UserSettings/pages/Tenants'
import { Route } from 'react-router-dom'
import AbsencePlannerPage from 'redesign/pages/AbsencePlannerPage'
import NewDocumentsPage from 'redesign/pages/Documents'
import ShiftPlanPage from 'redesign/pages/ShiftPlan'
import { default as NewShiftUpdateDetails } from 'redesign/pages/ShiftUpdateDetails'
import TaskListPage from 'redesign/pages/TaskList'
import { modules } from 'shared/constants'
import ProtectedRoute from 'utils/ProtectedRoute'
import routes from './routes'
import { isCmsRedirect } from './utils'

type RoutesParamsT = {
  hasAccessToModule: (module: string) => boolean
  isAuthenticated: boolean
  redesignFF?: boolean
  cmsAccess?: boolean
}

/**
 * This file contains the organised list of application paths,
 * separated by modules or functionalities to improve readability.
 *
 * Used in `RoutesProvider/index.tsx` to avoid a single huge list of routes.
 */

export const absencePlannerRoutes = ({ isAuthenticated, hasAccessToModule, redesignFF }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.absencePlanner}
    path={routes.absencePlanner}
    // @ts-ignore
    component={redesignFF ? AbsencePlannerPage : AbsencePlanner}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.absencesPlan)}
    isRedesignTheme={redesignFF}
  />,
]

export const absenceWorkflowRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.absenceRequestDetail}
    path={`${routes.absenceRequestDetail}/:taskUuid`}
    // @ts-ignore
    component={DetailsAbsenceRequestPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowAbsences)}
  />,
  <ProtectedRoute
    key={routes.newAbsenceRequest}
    path={routes.newAbsenceRequest}
    // @ts-ignore
    component={NewAbsenceRequest}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowAbsences)}
  />,
]

export const balancesRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.balances}
    path={routes.balances}
    // @ts-ignore
    component={BalancesPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.balances)}
  />,
]

export const documentRoutes = ({ isAuthenticated, hasAccessToModule, redesignFF }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.documents}
    path={routes.documents}
    // @ts-ignore
    component={redesignFF ? NewDocumentsPage : DocumentsPage}
    exact
    isAllowed={isAuthenticated}
    isRedesignTheme={redesignFF}
    moduleAccess={hasAccessToModule(modules.documents)}
  />,
]

// CMS related routes
export const cmsRoutes = ({ isAuthenticated, cmsAccess }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.cmsContentView}
    path={`${routes.cmsContentView}/:contentUuid`}
    // @ts-ignore
    component={ContentViewPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={cmsAccess}
  />,
  <ProtectedRoute
    key={routes.cmsContentIndex}
    path={routes.cmsContentIndex}
    // @ts-ignore
    component={ContentIndexPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={cmsAccess}
  />,
  <ProtectedRoute
    key={routes.cmsContentIndex}
    path={`${routes.cmsContentIndex}/:ownerId`}
    // @ts-ignore
    component={ContentIndexPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={cmsAccess}
  />,
  <ProtectedRoute
    key={routes.cmsOverview}
    path={routes.cmsOverview}
    // @ts-ignore
    component={ContentOverviewPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={cmsAccess}
  />,
  <ProtectedRoute
    key={routes.cmsEventIndex}
    path={routes.cmsEventIndex}
    // @ts-ignore
    component={ContentIndexEvent}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={cmsAccess}
  />,
  <ProtectedRoute
    key={routes.cmsEventIndex}
    path={`${routes.cmsEventIndex}/:ownerId`}
    // @ts-ignore
    component={ContentIndexEvent}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={cmsAccess}
  />,
  <ProtectedRoute
    key={routes.cmsNewsletterIndex}
    path={routes.cmsNewsletterIndex}
    // @ts-ignore
    component={ContentIndexNewsletter}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={cmsAccess}
  />,
  <ProtectedRoute
    key={routes.cmsNewsletterIndex}
    path={`${routes.cmsNewsletterIndex}/:ownerId`}
    // @ts-ignore
    component={ContentIndexNewsletter}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={cmsAccess}
  />,
  <ProtectedRoute
    key={routes.cmsRedirect}
    path={`${routes.cmsRedirect}`}
    // @ts-ignore
    component={RedirectCmsPage}
    isAllowed={isAuthenticated}
    noPushRegistration
  />,
]

export const employeeCardRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.employeeCard}
    path={`${routes.employeeCard}`}
    // @ts-ignore
    component={EmployeeCardPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.employeeCards)}
  />,
]

export const employeerRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.employer}
    path={`${routes.employer}/:favurUuid`}
    // @ts-ignore
    component={EmployerPage}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={routes.employers}
    path={routes.employers}
    // @ts-ignore
    component={EmployersPage}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={routes.employers}
    path={`${routes.employers}/:favurUuid/teams`}
    // @ts-ignore
    component={EmployerPersonTeamsPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
  <ProtectedRoute
    key={routes.employers}
    path={`${routes.employers}/:favurUuid/teams/:teamId`}
    // @ts-ignore
    component={EmployerPersonTeamPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
]

export const monthlySheetsRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.monthlySheets}
    path={`${routes.monthlySheets}/:taskUuid`}
    // @ts-ignore
    component={MonthlySheetPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowMonthlySheets)}
  />,
]

// Routes that can be accessed by non logged-in users
export const notRequiredAuthenticationRoutes = (isAuthenticated: boolean, queryParams: URLSearchParams) => [
  <ProtectedRoute
    key={`${routes.login}/:step?`}
    path={`${routes.login}/:step?`}
    // @ts-ignore
    component={LoginPage}
    isAllowed={!isAuthenticated}
    redirectTo={routes.dashboard}
    redirectLogin={isCmsRedirect(queryParams)}
    noPushRegistration
  />,
  <ProtectedRoute
    key={`${routes.forgotPhone}/:step?`}
    path={`${routes.forgotPhone}/:step?`}
    // @ts-ignore
    component={ForgotPhonePage}
    isAllowed={!isAuthenticated}
    redirectTo={routes.dashboard}
    noPushRegistration
  />,
  <Route
    key={`${routes.invite}/:inviteCode`}
    path={`${routes.invite}/:inviteCode`}
    // @ts-ignore
    component={InvitationPage}
    exact
  />,
  <Route
    key={routes.settings}
    path={routes.settings}
    // @ts-ignore
    component={SettingsPage}
    exact
  />,
  <Route
    key={routes.contact}
    path={routes.contact}
    // @ts-ignore
    component={ContactPage}
    exact
  />,
  <Route
    key={routes.deleteAccountSuccess}
    path={routes.deleteAccountSuccess}
    // @ts-ignore
    component={SuccessDeleteUserPage}
    exact
  />,
]

// Routes that can be accessed only by office users
export const officeRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={`${routes.requestEmployeeData}/:mode`}
    path={`${routes.requestEmployeeData}/:mode`}
    // @ts-ignore
    component={RequestEmployeeData}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowShareUserData)}
  />,
]

export const secureConnectionRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.secureConnection}
    path={`${routes.secureConnection}/:favurUuid`}
    // @ts-ignore
    component={SecureConnectionDetails}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowSecureConnection)}
  />,
  <ProtectedRoute
    key={routes.secureConnection}
    path={`${routes.secureConnectionSuccess}/:taskUuid?`}
    // @ts-ignore
    component={SuccessProcess}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowSecureConnection)}
  />,
  <ProtectedRoute
    key={routes.securitySetup}
    path={`${routes.securitySetup}/:step?`}
    // @ts-ignore
    component={SecureConnectionProcessPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowSecureConnection)}
  />,
]

export const shareUserDataRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.userDataDetails}
    path={`${routes.userDataDetails}/:favurUuid`}
    // @ts-ignore
    component={UserDataDetails}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowShareUserData)}
  />,
  <ProtectedRoute
    key={routes.employmentDataShare}
    path={`${routes.employmentDataShare}`}
    // @ts-ignore
    component={EmployersSelectionPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowShareUserData)}
  />,
]

export const shiftsRoutes = ({ isAuthenticated, hasAccessToModule, redesignFF }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.shifts}
    path={routes.shifts}
    // @ts-ignore
    component={redesignFF ? ShiftPlanPage : ShiftsPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.shiftPlan)}
    isRedesignTheme={redesignFF}
  />,
  <ProtectedRoute
    key={routes.shiftUpdateDetail}
    path={`${routes.shiftUpdateDetail}/:favurUuid/:status`}
    // @ts-ignore
    component={redesignFF ? NewShiftUpdateDetails : ShiftUpdateDetails}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.workflowShiftUpdate)}
    isRedesignTheme={redesignFF}
  />,
]

export const tasksRoutes = ({ isAuthenticated, hasAccessToModule, redesignFF }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.tasks}
    path={`${routes.tasks}/:taskstate?`}
    // @ts-ignore
    component={redesignFF ? TaskListPage : FrontlinerTasks}
    exact
    isAllowed={isAuthenticated}
    isRedesignTheme={redesignFF}
    moduleAccess={hasAccessToModule(modules.tasks)}
  />,
]

export const teamRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.teams}
    path={routes.teams}
    // @ts-ignore
    component={TeamsPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
  <ProtectedRoute
    key={`${routes.teams}/owner/:ownerId`}
    path={`${routes.teams}/owner/:ownerId`}
    // @ts-ignore
    component={TeamsPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
  <ProtectedRoute
    key={`${routes.teams}/owner/:ownerId/members/status/:status`}
    path={`${routes.teams}/owner/:ownerId/members/status/:status`}
    // @ts-ignore
    component={PersonList}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
  <ProtectedRoute
    key={`${routes.teams}/owner/:ownerId/members/:favurUuid`}
    path={`${routes.teams}/owner/:ownerId/members/:favurUuid`}
    // @ts-ignore
    component={PersonDetail}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/:tab?`}
    path={`${routes.teams}/:uuid/:tab?`}
    // @ts-ignore
    component={TeamPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/${routes.teamEditMembers}`}
    path={`${routes.teams}/:uuid/${routes.teamEditMembers}`}
    // @ts-ignore
    component={EditMembers}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/${routes.teamDetailMember}/:favurUuid`}
    path={`${routes.teams}/:uuid/${routes.teamDetailMember}/:favurUuid`}
    // @ts-ignore
    component={MemberDetail}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/${routes.teamEditPermissionMembers}/:permission`}
    path={`${routes.teams}/:uuid/${routes.teamEditPermissionMembers}/:permission`}
    // @ts-ignore
    component={EditPermissionMembers}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/${routes.teamEditSettings}`}
    path={`${routes.teams}/:uuid/${routes.teamEditSettings}`}
    // @ts-ignore
    component={EditTeamSettings}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.teams)}
  />,
]

export const tenantRoutes = ({ isAuthenticated, hasAccessToModule }: RoutesParamsT) => [
  <ProtectedRoute
    key={routes.tenants}
    path={routes.tenants}
    // @ts-ignore
    component={TenantsPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.businessCustomizations)}
  />,
  <ProtectedRoute
    key={routes.tenant}
    path={`${routes.tenant}/:tenantUuid`}
    // @ts-ignore
    component={TenantPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.businessCustomizations)}
  />,
  <ProtectedRoute
    key={routes.tenantReminders(':tenantUuid')}
    path={routes.tenantReminders(':tenantUuid')}
    // @ts-ignore
    component={TenantRemindersPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.businessCustomizations)}
  />,
  <ProtectedRoute
    key={routes.tenantRemindersList(':tenantUuid', ':workflowType')}
    path={routes.tenantRemindersList(':tenantUuid', ':workflowType')}
    // @ts-ignore
    component={TenantReminderListPage}
    exact
    isAllowed={isAuthenticated}
    moduleAccess={hasAccessToModule(modules.businessCustomizations)}
  />,
]
