import MuiRadio from './MiuRadio'
import MuiBadge from './MuiBadge'
import MuiButton from './MuiButton'
import MuiCheckbox from './MuiCheckbox'
import MuiDialog from './MuiDialog'
import MuiFormLabel from './MuiFormLabel'
import MuiIconButton from './MuiIconButton'
import MuiLink from './MuiLink'
import MuiPopover from './MuiPopover'
import MuiSwitch from './MuiSwitch'
import MuiTab from './MuiTab'
import MuiTabs from './MuiTabs'
import MuiTextField from './MuiTextField'
import MuiTooltip from './MuiTooltip'

const ComponentOverrides = {
  MuiRadio,
  MuiBadge,
  MuiCheckbox,
  MuiDialog,
  MuiFormLabel,
  MuiButton,
  MuiIconButton,
  MuiLink,
  MuiPopover,
  MuiSwitch,
  MuiTab,
  MuiTabs,
  MuiTextField,
  MuiTooltip,
}

export { ComponentOverrides }
