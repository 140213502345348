import { styled } from '@mui/material'

export const FooterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing(2, 2, 0.5, 2)};
  background: ${({ theme }) => theme.palette.background.default};
  border-top: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
`
