import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FramedIcon from 'redesign/components/atoms/FramedIcon'
import { PlaceholderIcon } from 'redesign/components/atoms/Icon'
import ContentCard from 'redesign/components/organisms/ContentCard'
import ListWrapper from 'redesign/components/organisms/ListWrapper'
import { TaskContainer } from './styles'
import { TaskListProps } from './types'
import { normalizeTaskContents, normalizeTaskHeader } from './utils'

const TaskList: React.FC<TaskListProps> = ({
  tasks,
  isOffice,
  isLoading,
  onTaskClick,
  showLoadMoreButton,
  loadMore,
}) => {
  const { t, locale } = useFavurTranslation()

  return (
    <ListWrapper
      hasContent={Boolean(tasks.length)}
      message={t('page.tasks.content.noTasks')}
      isLoading={isLoading}
      showLoadMoreButton={showLoadMoreButton}
      loadMore={loadMore}
    >
      <TaskContainer>
        {tasks.map((task) => {
          const { bodyText, startText, secondaryText, endText } = normalizeTaskContents({
            t,
            task,
            locale,
          })
          const title = normalizeTaskHeader({ task, isOffice, t })

          return (
            <ContentCard
              key={task.favurUuid}
              header={{
                icon: <FramedIcon icon={PlaceholderIcon} />,
                title,
                overflowSingleLine: true,
              }}
              bodyText={bodyText}
              footerProps={{
                startText,
                endText,
                secondaryText,
              }}
              onClick={() => onTaskClick({ task })}
              overflowBodyText
            />
          )
        })}
      </TaskContainer>
    </ListWrapper>
  )
}
export default TaskList
