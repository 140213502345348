import { FormControl, FormGroup, FormHelperText, styled } from '@mui/material'

export const Container = styled(FormControl)`
  display: flex;
  flex-direction: column;
`

export const Group = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const HelperText = styled(FormHelperText)`
  margin: 0;
`

export const HeaderWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
`
