import { Box, css, styled } from '@mui/material'
import { resolveDayCellBackground, resolveDayCellTextColor } from './utils'

export const MainContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isSmall' && prop !== 'isToday',
})<{
  isSelected?: boolean
  isSmall?: boolean
  isToday?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ isSmall, theme }) => (isSmall ? theme.spacing(3) : theme.spacing(4.5))};
  width: ${({ isSmall, theme }) => (isSmall ? theme.spacing(3) : theme.spacing(4.5))};
  border-radius: 50%;
  border: ${({ theme, isToday }) => (isToday ? `${theme.spacing(0.125)} solid ${theme.palette.primary.main}` : 'none')};
  background-color: ${({ theme, isSelected, isToday }) => resolveDayCellBackground({ isToday, isSelected, theme })};
  color: ${({ theme, isSelected, isToday }) => resolveDayCellTextColor({ isToday, isSelected, theme })};
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;

  :disabled {
    opacity: 60%;
    pointer-events: none;
    background-color: ${({ theme, isSelected, isToday }) => resolveDayCellBackground({ isToday, isSelected, theme })};
    color: ${({ theme, isSelected, isToday }) => resolveDayCellTextColor({ isToday, isSelected, theme })};
  }

  :hover {
    ${({ theme, isSelected, isToday }) =>
      !isSelected
        ? css`
            background-color: ${theme.palette.common.hoverCard};
            color: ${theme.palette.text.primary};
          `
        : css`
            background-color: ${resolveDayCellBackground({ isToday, isSelected, theme })};
          `}
    cursor: pointer;
  }
`
