import { useMemo } from 'react'
import { getAreThereShifts, getCanShowEffectiveTimes } from 'components/ShiftsList/components/ShiftCard/utils'
import { findEffectiveShiftListByTenant, getCurrentTeamPlan } from 'components/ShiftsList/components/utils'
import { ShiftUpdateStatus } from 'components/ShiftsList/types'
import type { UseShiftPlanContent, UseShiftPlanContentProps } from './types'
import { getGroupedShifts, getShiftUpdateDaysCount, getUpdateTaskFromShifts, shouldShowShiftUpdateAlert } from './utils'

const useShiftPlanContent = ({
  sortedTenantIds,
  tenantList,
  groupedTenants,
  getAbsenceRequestsByTenantId,
  effectiveShifts,
  effectiveShiftsLoading,
  shiftUpdates = [],
  getEventsForTenantShiftCard,
  isPast,
  teamPlanData,
  date,
}: UseShiftPlanContentProps) => {
  return useMemo(
    () =>
      sortedTenantIds.reduce((acc, tenantId) => {
        const tenant = tenantList[tenantId]
        const shiftData = groupedTenants.get(tenantId) ?? []
        const absenceRequests = getAbsenceRequestsByTenantId(tenantId)
        const groupedShifts = getGroupedShifts(shiftData)
        const events = getEventsForTenantShiftCard({ tenantShifts: shiftData })
        const showEvents = Boolean(events.length)
        const showShifts = Boolean(shiftData.length) || Boolean(absenceRequests.length)
        const teamPlan = getCurrentTeamPlan(false, teamPlanData, date, tenantId)
        const areThereShifts = getAreThereShifts(groupedShifts)
        const areThereEffectiveShifts = effectiveShifts !== undefined && effectiveShifts.length > 0
        const updateTask = getUpdateTaskFromShifts(shiftData, shiftUpdates)
        const showShiftUpdateAlert = shouldShowShiftUpdateAlert(updateTask, isPast)
        const shiftUpdateDaysCount = getShiftUpdateDaysCount(updateTask)

        const hasAcknowledgedShiftUpdate =
          updateTask === undefined || updateTask.status === ShiftUpdateStatus.reviewFrontlineSeen

        const canShowEffectiveTimes = getCanShowEffectiveTimes(
          isPast,
          areThereShifts,
          areThereEffectiveShifts,
          hasAcknowledgedShiftUpdate,
          false,
        )
        const filteredEffectiveShifts = findEffectiveShiftListByTenant(
          effectiveShifts,
          effectiveShiftsLoading,
          tenantId,
        )

        if (shiftData.length || absenceRequests.length || teamPlan?.length) {
          return [
            ...acc,
            {
              tenant,
              shiftData,
              absenceRequests,
              groupedShifts,
              showShifts,
              filteredEffectiveShifts,
              canShowEffectiveTimes,
              events,
              showEvents,
              showShiftUpdateAlert,
              updateTask,
              shiftUpdateDaysCount,
              teamPlan: teamPlan,
            },
          ]
        }

        return acc
      }, [] as UseShiftPlanContent[]),
    [
      sortedTenantIds,
      tenantList,
      groupedTenants,
      getAbsenceRequestsByTenantId,
      getEventsForTenantShiftCard,
      teamPlanData,
      date,
      effectiveShifts,
      shiftUpdates,
      isPast,
      effectiveShiftsLoading,
    ],
  )
}

export default useShiftPlanContent
