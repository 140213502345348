import React from 'react'
import { CloseIcon } from 'redesign/components/atoms/Icon'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import { CloseButton, ShiftHeader, TextContainer } from './styles'
import type { ShiftHeaderTitle } from './types'

const ShiftDialogHeader: React.FC<ShiftHeaderTitle> = ({ title, subtitle, onClose, isSmallScreen = false }) => (
  <ShiftHeader isSmallScreen={isSmallScreen}>
    <TextContainer>
      <HeaderLabel mainText={title} subText={subtitle} />
    </TextContainer>
    <CloseButton onClick={onClose}>
      <CloseIcon />
    </CloseButton>
  </ShiftHeader>
)

export default ShiftDialogHeader
