import { sortTeams } from 'pages/AbsencePlanner/utils'
import { getFullTeamLabel } from 'utils/team'
import type { GetTeamsSortedValues } from './types'

export const getTeamsSorted = ({
  teams,
  language,
  isMultiTenant,
  shownTeams,
  handleCheckChange,
}: GetTeamsSortedValues) =>
  sortTeams(teams, language).map((team) => ({
    key: team.id,
    dataTestId: `team-${team.id}`,
    label: getFullTeamLabel(team, language, Boolean(isMultiTenant)),
    checked: shownTeams.includes(team.id),
    onChange: () => handleCheckChange(team, shownTeams.includes(team.id)),
  }))
