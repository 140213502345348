import { useCallback, useMemo, useState } from 'react'
import useFilterContext from 'components/Filter/context'
import useFilterUtils from 'components/Filter/useFilterUtils'
import type { TaskListElement } from 'shared/graphql/graphql'
import { TASK_TYPES } from '../../ContentLists/TaskList/constants'
import useFilterConfiguration from '../hooks/useFilterConfiguration'

export const usePersonalShiftUpdates = () => {
  // Placeholder
  const [shiftUpdateOpen, setShiftUpdateOpen] = useState(false)
  const { configuration, loading: configLoading } = useFilterConfiguration({ isOffice: false })
  const { storeState } = useFilterContext()
  const { setLoadFilterTasks } = useFilterUtils()
  const initialFilters = useMemo(
    () => ({
      // Camelcase used for the filter query variables
      // eslint-disable-next-line camelcase
      task_types: [TASK_TYPES.shiftUpdate],
    }),
    [],
  )
  const onTaskClick = useCallback(
    // Placeholder
    (_props: { task: TaskListElement }) => {
      setLoadFilterTasks()
      storeState()
      setShiftUpdateOpen(true)
    },
    [setLoadFilterTasks, storeState],
  )

  return {
    configuration,
    initialFilters,
    configLoading,
    onTaskClick,
    shiftUpdateOpen,
    setShiftUpdateOpen,
  }
}
