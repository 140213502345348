import { Theme } from '@mui/material'
import theme from 'utils/theme'
import { CELL_HEIGHT, CELL_WIDTH } from '../constants'

const getYMargins = (numberOfBadges: number) => (numberOfBadges < 3 ? '8px' : '3px')

export const absencePlannerCellClasses = {
  container: (numberOfBadges: number) => ({
    width: '38px',
    minWidth: '38px',
    height: `${CELL_HEIGHT}px`,
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '0 3px',
    justifyContent: 'center',
    overflow: 'hidden',
    '&>div:first-of-type': {
      marginTop: getYMargins(numberOfBadges),
    },
    '&>div:last-of-type': {
      marginBottom: getYMargins(numberOfBadges),
    },
    cursor: numberOfBadges > 0 ? 'pointer' : 'auto',
  }),
  lastOfRow: {
    borderRight: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.primary[50],
  },
  justifySelfStart: {
    justifySelf: 'start',
  },
  overflowContainer: {
    minHeight: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lastRowContainer: (isLastRow: boolean, isMonday: boolean) => ({
    boxSizing: 'border-box',
    width: `${CELL_WIDTH}px`,
    paddingBottom: isLastRow ? '32px' : 0,
    borderLeft: '1px solid',
    // @ts-ignore
    borderColor: isMonday ? theme.palette.grey[300] : theme.palette.primary[50],
    backgroundColor: theme.palette.common.white,
  }),
  redesignTheme: (redesignTheme: Theme, isMonday: boolean) => ({
    borderColor: isMonday ? theme.palette.text.secondary : redesignTheme.palette.common.cardBorder,
  }),
}
