import React from 'react'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import { DrawerHeaderWrapper } from 'redesign/components/molecules/DrawerHeaderWrapper'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import Header from '../../../organisms/Header'
import { SIDE_MENU_OPTIONS, SIDE_MENU_TRANSLATIONS } from '../../SideMenu/constants'
import type { MenuPageProps } from '../../SideMenu/types'

const Events: React.FC<MenuPageProps> = ({ onClose, t, setPage, isDrawer }) => (
  <DrawerHeaderWrapper
    header={
      <Header
        startContent={isDrawer && <BackButton onGoBack={() => setPage(SIDE_MENU_OPTIONS.mainMenu)} />}
        middleContent={<HeaderLabel mainText={t(SIDE_MENU_TRANSLATIONS[SIDE_MENU_OPTIONS.events])} />}
        endContent={isDrawer && <CloseButton onClick={onClose} />}
      />
    }
  ></DrawerHeaderWrapper>
)

export default Events
