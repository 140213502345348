import React from 'react'
import { Typography } from '@mui/material'
import { FavurBirdWhite } from 'redesign/components/atoms/Icon'
import Header from '../../Header'
import { HeaderContainer, IconContainer } from './styles'
import type { SideMenuProps } from './types'

const NavigationMenuHeader: React.FC<SideMenuProps> = ({ userFirstName }) => (
  <HeaderContainer>
    <Header
      startContent={
        <Typography noWrap variant="h6">
          {userFirstName}
        </Typography>
      }
      endContent={
        <IconContainer>
          <FavurBirdWhite />
        </IconContainer>
      }
      sidePadding
      isNavigationMenu
      withoutDivider
    />
  </HeaderContainer>
)

export default NavigationMenuHeader
