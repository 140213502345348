import { styled } from '@mui/material'
import { CELL_WIDTH, TEAM_NAME_ROW_HEIGHT } from 'pages/AbsencePlanner/components/Grid/constants'
import { Z_INDEX_PLACEHOLDER_CONTAINER, Z_INDEX_TEAM_CONTAINER } from 'redesign/shared/zIndex/constants'

export const TeamContainer = styled('div')`
  height: ${TEAM_NAME_ROW_HEIGHT}px;
  background-color: transparent;
  background-size: ${CELL_WIDTH}px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: transparent;
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  flex: 1;
  z-index: ${Z_INDEX_TEAM_CONTAINER};
`

export const PlaceHolderContainer = styled('div')`
  display: flex;
  position: sticky;
  left: 0;
  padding-left: 0;
  width: fit-content;
  height: ${TEAM_NAME_ROW_HEIGHT}px;
  box-sizing: border-box;
  background-size: ${CELL_WIDTH}px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.common.cardBorder};
  z-index: ${Z_INDEX_PLACEHOLDER_CONTAINER};
`
