import { Dialog, DialogContent, css, styled } from '@mui/material'
import { Z_INDEX_DIALOG } from 'redesign/shared/zIndex/constants'

export const MuiDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'fixedHeight' && prop !== 'addMinWidth',
})<{ height: number; width: number; fixedHeight?: boolean; addMinWidth?: boolean }>`
  z-index: ${Z_INDEX_DIALOG};
  & > * > * {
    ${({ addMinWidth, theme, width }) =>
      addMinWidth &&
      css`
        min-width: ${theme.spacing(width)};
      `};
    width: ${({ theme, width }) => theme.spacing(width)};
    border: ${({ theme }) => `${theme.spacing(0.125)} solid ${theme.palette.common.cardBorder}`};
    box-shadow: ${({ theme }) => theme.shadows[3]} !important;
    ${({ fixedHeight, theme, height }) =>
      fixedHeight &&
      css`
        height: ${theme.spacing(height)};
      `}
  }
`

export const MuiDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== 'noPadding',
})<{ noPadding?: boolean }>`
  display: grid;
  grid-template-rows: auto auto 1fr;
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`
