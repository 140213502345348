import React from 'react'
import { useEventsDateRange } from 'hooks/useEventsDateRange'
import type { TenantT } from 'types'
import ShiftData from '../ShiftData'
import { Container } from './styles'
import type { ShiftContentProps } from './types'

const ShiftContent: React.FC<ShiftContentProps> = ({ shifts, selectedShift, date, userIsAbsenceManager }) => {
  const { events } = useEventsDateRange({
    startDate: date,
    endDate: date,
  })

  return (
    <Container>
      <ShiftData
        shiftData={shifts ?? []}
        events={events}
        tenant={selectedShift?.tenant as TenantT}
        date={selectedShift?.date}
        avatarData={selectedShift?.avatarData}
        hideAbsenceType={!userIsAbsenceManager}
        showAvatarUserName
        showEventsInTaskBar
      />
    </Container>
  )
}

export default ShiftContent
