import React from 'react'
import { CalendarActionIcon } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import { Button } from './styles'
import type { CalendarButtonProps } from './types'

const CalendarButton: React.FC<CalendarButtonProps> = ({ handleCalendarAction, label }) => (
  <Button endIcon={<CalendarActionIcon size={ICON_SIZE.small} />} onClick={handleCalendarAction} variant="text">
    {label}
  </Button>
)

export default CalendarButton
