import { useMemo } from 'react'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import type { RadioOption } from 'redesign/components/molecules/RadioFormGroup/types'

export const useOwnerSettings = () => {
  const { selectedOwnerId, setSelectedOwnerId, owners } = useAbsencePlannerStateContext()

  const options: RadioOption[] = useMemo(
    () =>
      (owners ?? []).map(({ owner }) => ({
        id: parseInt(`${owner.id}`),
        label: owner.name,
        value: parseInt(`${owner.id}`),
      })),
    [owners],
  )

  return { selectedOwnerId, setSelectedOwnerId, options }
}
