import React, { useMemo } from 'react'
import { NavPointConditionsT } from 'components/Page/Header/Toolbar/Menu/types'
import navigation from 'constants/navigation'
import { DashboardApp, DashboardAppActive, HomeL } from 'icons'
import routes from 'services/RoutesProvider/routes'
import { isNative } from 'utils/platform'
import { modules } from '../shared/constants'
import useCms from './useCms'
import useModule from './useModule'
import useRolesViews from './useRolesViews'

type DashboardIconT = {
  fill?: string | undefined
  negative?: boolean
}

const getNavigationItems = (conditions: NavPointConditionsT, isManager: boolean) => {
  return [
    navigation.dashboard,
    ...(conditions.managerTasks && isManager ? [navigation.managerTasks] : []),
    ...(conditions.shifts ? [navigation.shifts] : []),
    ...(conditions.absencePlanner && isManager ? [navigation.absencePlanner] : []),
    ...(conditions.personalTasks ? [navigation.personalTasks] : []),
    ...(conditions.documents ? [navigation.documents] : []),
    ...(conditions.balances ? [navigation.balances] : []),
    ...(conditions.settings ? [navigation.settings] : []),
  ].slice(0, 4)
}

const getNativeNavigation = (conditions: NavPointConditionsT, isManager: boolean) => {
  if (!isNative()) return []

  return getNavigationItems(conditions, isManager)
}

// eslint-disable-next-line complexity
const getPrimaryNavigation = (conditions: NavPointConditionsT, isManager: boolean) => {
  return [
    ...(conditions.dashboard ? [navigation.dashboard] : []),
    ...(conditions.shifts ? [navigation.shifts] : []),
    ...(conditions.absencePlanner && isManager ? [navigation.absencePlanner] : []),
    ...(conditions.managerTasks && isManager ? [navigation.managerTasks] : []),
    ...(conditions.personalTasks ? [navigation.personalTasks] : []),
    ...(conditions.documents ? [navigation.documents] : []),
    ...(conditions.balances ? [navigation.balances] : []),
    ...(conditions.absencePlanner && !isManager ? [navigation.absencePlanner] : []),
    ...(conditions.cmsOverview ? [navigation.cmsOverview] : []),
  ]
}

const getSecondaryNavigation = (conditions: NavPointConditionsT) => {
  return [...(conditions.settings ? [navigation.settings] : [])]
}
const getTertiaryNavigation = () => [navigation.contact, navigation.privacy, navigation.appInfo]

const useFavurNavigation = () => {
  const { hasAccessToModule } = useModule()
  const { cmsAccess } = useCms()
  const { hasOfficeView, hasActivePersons } = useRolesViews()

  const conditions = useMemo(
    () =>
      ({
        dashboard: true,
        shifts: hasAccessToModule(modules.shiftPlan),
        balances: hasAccessToModule(modules.balances),
        managerTasks: hasAccessToModule(modules.tasks),
        absencePlanner: hasActivePersons && hasAccessToModule(modules.absencesPlan),
        personalTasks: hasAccessToModule(modules.tasks),
        documents: hasAccessToModule(modules.documents),
        cmsOverview: hasActivePersons && cmsAccess,
        settings: true,
        contact: true,
        appInfo: true,
        privacy: true,
        tasks: false,
        notifications: false,
        logout: false,
      } as NavPointConditionsT),
    [cmsAccess, hasAccessToModule, hasActivePersons],
  )

  const getDashboardIcon = (pathname: string): React.FC<DashboardIconT> => {
    if (isNative()) {
      return HomeL
    }

    return pathname === routes.dashboard ? DashboardAppActive : DashboardApp
  }

  const nativeNavigation = useMemo(() => getNativeNavigation(conditions, hasOfficeView), [conditions, hasOfficeView])
  const primaryNavigation = useMemo(() => getPrimaryNavigation(conditions, hasOfficeView), [conditions, hasOfficeView])
  const secondaryNavigation = useMemo(() => getSecondaryNavigation(conditions), [conditions])
  const tertiaryNavigation = useMemo(() => getTertiaryNavigation(), [])

  return {
    conditions,
    nativeNavigation,
    primaryNavigation,
    secondaryNavigation,
    tertiaryNavigation,
    routes,
    getDashboardIcon,
  }
}

export default useFavurNavigation
