import React, { useMemo, useRef } from 'react'
import { Fade } from '@mui/material'
import { AuthenticationKind } from 'constants/authentication'
import { AnimatePresence } from 'framer-motion'
import SafeSpace from 'redesign/components/atoms/SafeSpace'
import HamburgerHeader from 'redesign/components/molecules/HamburgerHeader'
import NavigationMenuHeader from '../../Headers/NavigationMenuHeader'
import NavigationFooter from '../NavigationFooter'
import NavigationMenuOptions from '../NavigationMenuOptions'
import { Background, BottomScroll } from './styles'
import type { NavigationMenuProps } from './types'

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  appInfoData,
  auth,
  displayMenu,
  toggleAppInfoDialog,
  toggleDrawer,
  hideHamburger,
  showAppInfo,
  navigationLinks,
  userFirstName,
  isNative,
  onNavigationGroupClick,
  navigationButtonCallbacks,
  showHamburgerHeader,
}) => {
  const loggedInAndShowHamburger = auth > AuthenticationKind.ANONYMOUS && !hideHamburger
  const scrollRef = useRef<HTMLDivElement>(null)

  const scroll = useMemo(
    () => displayMenu && (scrollRef.current?.scrollHeight ?? 0) - (scrollRef.current?.clientHeight ?? 0) > 0,
    [displayMenu],
  )

  return (
    <>
      {loggedInAndShowHamburger && (
        <Fade in={displayMenu} timeout={700}>
          <Background>
            <SafeSpace isSideMenuOpen>
              {showHamburgerHeader && <HamburgerHeader toggleMenu={toggleDrawer} isMenuOpen showLogo={false} />}
              <NavigationMenuHeader userFirstName={userFirstName} />
              {scroll && <BottomScroll />}
              <AnimatePresence>
                {displayMenu && (
                  <NavigationMenuOptions
                    appInfoData={appInfoData}
                    showAppInfo={showAppInfo}
                    toggleAppInfoDialog={toggleAppInfoDialog}
                    navigationLinks={navigationLinks}
                    onNavigationGroupClick={onNavigationGroupClick}
                    navigationButtonCallbacks={navigationButtonCallbacks}
                  />
                )}
              </AnimatePresence>
              {isNative && <NavigationFooter toggleDrawer={toggleDrawer} />}
            </SafeSpace>
          </Background>
        </Fade>
      )}
    </>
  )
}

export default NavigationMenu
