import { css, styled } from '@mui/material'
import { VirtualizeSwipeableViews } from 'redesign/services/SwipeableView/VirtualizeSwipeableViews'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`

export const SwipeableViews = styled(VirtualizeSwipeableViews)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  flex-grow: 1;
  height: 100%;
  & > div {
    height: 100%;
    min-height: ${({ theme }) => theme.spacing(48)};
  }
`

export const SwipeableContainer = styled('div')<{ slide: number; index: number }>`
  ${({ theme, slide, index }) =>
    slide !== index &&
    css`
      margin: ${theme.spacing(0, 2)};
    `}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  pointer-events: auto;
  height: 100%;
`

export const DataContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(3, 0)};
`
