import React from 'react'
import { ContentWrapper, HeaderContainer, Container } from './styles'
import type { DrawerHeaderWrapperProps } from './types'

const DrawerHeaderWrapper: React.FC<DrawerHeaderWrapperProps> = ({ header, children, footer, backgroundColor }) => (
  <Container backgroundColor={backgroundColor}>
    <HeaderContainer>{header}</HeaderContainer>
    <ContentWrapper>{children}</ContentWrapper>
    {footer}
  </Container>
)

export default DrawerHeaderWrapper
