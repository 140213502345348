import { useCallback, useEffect, useState } from 'react'
import { AuthenticationKind } from 'constants/authentication'
import useSessionContext from 'hooks/useSessionContext'
import { useLocation } from 'react-router'
import { useAnnouncements } from 'services/AnnouncementsService/useAnnouncements'
import routes from 'services/RoutesProvider/routes'
import { UserAnnouncement, UserAnnouncementStatus } from 'shared/graphql/graphql'

export const useAnnouncementsService = () => {
  const { pathname } = useLocation()
  const { auth } = useSessionContext()

  const [routeAnnouncement, setRouteAnnouncement] = useState<UserAnnouncement>()
  const [showAnnouncement, setShowAnnouncement] = useState(false)

  const { list: userAnnouncements, refetch, markAnnouncementSeen, loading } = useAnnouncements({
    skip: auth < AuthenticationKind.AUTHENTICATED,
    statuses: [UserAnnouncementStatus.Unseen],
  })

  // We refetch the announcement list whenever a user goes back to the dashboard to keep it up to date
  useEffect(() => {
    if (auth < AuthenticationKind.AUTHENTICATED) return
    if (loading) return
    if (routes.dashboard === pathname || !userAnnouncements) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, fetch, auth])

  const openAnnouncement = useCallback((announcementRoute: UserAnnouncement) => {
    setRouteAnnouncement(announcementRoute)
    setShowAnnouncement(true)
  }, [])

  useEffect(() => {
    if (userAnnouncements && userAnnouncements.length > 0) {
      // Refetch for when we close the drawer without calling onClose (when navigating through link)
      if (showAnnouncement) {
        refetch()
        setRouteAnnouncement(undefined)
        setShowAnnouncement(false)
        return
      }
      const routeAnnouncements = userAnnouncements.filter(({ announcement }) => announcement?.route === pathname)
      setRouteAnnouncement(routeAnnouncements[0])
      setShowAnnouncement(routeAnnouncements.length > 0)
    }

    // Needed to ignore unnecessary showAnnouncement updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, userAnnouncements])

  // We mark announcements as seen whenever we load a new one to show
  useEffect(() => {
    if (routeAnnouncement) {
      markAnnouncementSeen(routeAnnouncement, { updateLocal: false })
    }
  }, [markAnnouncementSeen, routeAnnouncement])

  return { routeAnnouncement, showAnnouncement, setShowAnnouncement, refetch, openAnnouncement, markAnnouncementSeen }
}
