import React from 'react'
import SwitchFormGroup from 'redesign/components/molecules/SwitchFormGroup'
import { useAbsenceEntryTypes } from './useAbsenceEntryTypes'

const AbsenceEntryTypes: React.FC = () => {
  const { options, headlineTitle } = useAbsenceEntryTypes()

  return <SwitchFormGroup legend={headlineTitle} options={options} />
}

export default AbsenceEntryTypes
