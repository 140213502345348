import { useEffect } from 'react'
import { tutorialTypes } from 'constants/tutorials'
import useRolesViews from 'hooks/useRolesViews'
import useStateBackLink from 'hooks/useStateBackLink'
import useTutorials from 'hooks/useTutorials'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'

const useAbsencePlanPage = () => {
  const { persons, canManageAbsences } = useRolesViews()
  const history = useHistory()
  const { beenShownBefore, isReady } = useTutorials()
  const { setBackLink } = useStateBackLink()

  useEffect(() => {
    if (!isReady) return
    if (canManageAbsences) {
      if (!beenShownBefore('absenceplanManager')) {
        history.replace(`${routes.tutorial}/${tutorialTypes.absencePlanManager}`)
      }
    } else {
      if (!beenShownBefore('absenceplanFrontliner')) {
        history.replace(`${routes.tutorial}/${tutorialTypes.absencePlanFrontliner}`)
      }
    }
  }, [history, isReady, beenShownBefore, setBackLink, canManageAbsences])

  return { isReady, persons }
}

export default useAbsencePlanPage
