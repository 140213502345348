import { useCallback, useMemo } from 'react'
import { useTheme } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import useFilterUtils from 'components/Filter/useFilterUtils'
import { addDays } from 'date-fns'
import { getDaysBefore } from 'pages/AbsencePlanner/components/TaskBar/AbsenceRequestTab/utils'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { useAvatarSidebarStateDispatchContext } from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import useAbsencePlannerFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import useSelectedStatesContext, {
  useSelectedStatesActionsContext,
} from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import type { PinPointingSearchValueT } from 'pages/AbsencePlanner/types'
import { getDaysRequested } from 'pages/AbsencePlanner/utils'
import type { TaskT, AbsenceRequestTaskT } from 'pages/Tasks/types'
import type { TaskListElement } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { TASK_TYPES } from '../../ContentLists/TaskList/constants'
import useFilterConfiguration from '../hooks/useFilterConfiguration'
import { UseAbsenceProps } from './types'

export const useAbsences = ({ isOffice }: UseAbsenceProps) => {
  const { configuration, loading: configLoading } = useFilterConfiguration({ isOffice })
  const theme = useTheme()
  const { shownTeams } = useAbsencePlannerFilterContext()
  const { storeState } = useFilterContext()
  const { setLoadFilterTasks } = useFilterUtils()
  const initialFilters = useMemo(
    () => ({
      // Camelcase used for the filter query variables
      // eslint-disable-next-line camelcase
      task_types: [TASK_TYPES.absenceRequest],
      // Camelcase used for the filter query variables
      // eslint-disable-next-line camelcase
      team_ids: shownTeams,
    }),
    [shownTeams],
  )
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const daysBefore = useMemo(() => getDaysBefore(isSmallScreen), [isSmallScreen])
  const setTaskBarState = useTaskBarStateDispatchContext()
  const setAvatarBarExpanded = useAvatarSidebarStateDispatchContext()
  const { selectedTaskUuid } = useSelectedStatesContext()
  const { selectTaskFromTaskBar, setSelectedTaskUuid } = useSelectedStatesActionsContext()
  const pinPointTask = useCallback(
    (task: TaskT<AbsenceRequestTaskT>) => {
      const firstTaskDay = newDateWithoutTime(task?.taskData?.dateFrom)
      const dayToScroll = addDays(firstTaskDay, daysBefore)
      const searchValue = {
        date: dayToScroll,
        personId: `${task?.person?.id}`,
      } as PinPointingSearchValueT

      selectTaskFromTaskBar(task?.favurUuid, searchValue, getDaysRequested(task), true)
      setTaskBarState(null)
      setAvatarBarExpanded(false)
    },
    [daysBefore, selectTaskFromTaskBar, setAvatarBarExpanded, setTaskBarState],
  )
  const onTaskClick = useCallback(
    ({ task }: { task: TaskListElement }) => {
      setLoadFilterTasks()
      storeState()
      pinPointTask(task as TaskT<AbsenceRequestTaskT>)
    },
    [pinPointTask, setLoadFilterTasks, storeState],
  )

  return {
    configLoading,
    configuration,
    headerBackgroundColor: theme.palette.common.backgroundApp,
    initialFilters,
    onTaskClick,
    selectedTaskUuid,
    setSelectedTaskUuid,
  }
}
