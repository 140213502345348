import { useCallback, useEffect, useState } from 'react'
import { hasAcknowledgedShiftUpdate } from 'components/ShiftsList/components/utils'
import { convertPxToMuiSpacing } from 'redesign/themes/main/utils'
import type { PlannedShiftT } from 'types'
import { DAY_CELL_HEIGHT, TAG_HEIGHT, TAG_GAP } from './constants'
import type { TUseCalendar } from './types'

export const useCalendarDay = ({ sortedShifts, shiftUpdates, events, containerRef }: TUseCalendar) => {
  const [maxChips, setMaxChips] = useState(0)
  const [hasOverflow, setHasOverflow] = useState(false)
  const [shiftsToShow, setShiftsToShow] = useState<PlannedShiftT[]>([])
  const numEvents = !events?.length ? 0 : 1

  const hasAcknowledgedTask = useCallback((shift: PlannedShiftT) => hasAcknowledgedShiftUpdate(shift, shiftUpdates), [
    shiftUpdates,
  ])

  const calculateMaxChips = useCallback((containerHeight: number | null) => {
    if (containerHeight) {
      const containerHeightInMuiSpacing = convertPxToMuiSpacing(containerHeight)

      const resultMaxChips = Math.floor((containerHeightInMuiSpacing - DAY_CELL_HEIGHT) / (TAG_HEIGHT + TAG_GAP))

      setMaxChips(resultMaxChips)
    }
  }, [])

  useEffect(() => {
    const observer = new ResizeObserver(() => calculateMaxChips(containerRef?.current?.clientHeight ?? 0))
    const mutationObserver = new MutationObserver(() => calculateMaxChips(containerRef?.current?.clientHeight ?? 0))

    if (containerRef?.current) {
      observer.observe(containerRef.current)
    }

    mutationObserver.observe(document.documentElement, { attributes: true, attributeFilter: ['style'] })

    return () => {
      observer.disconnect()
      mutationObserver.disconnect()
    }
  }, [containerRef, sortedShifts, calculateMaxChips])

  useEffect(() => {
    const overflowValue = sortedShifts.length + numEvents - maxChips
    const hasOverflowVal = overflowValue > 0
    const chipsToShow = hasOverflowVal ? maxChips - numEvents - 1 : maxChips - numEvents

    setHasOverflow(hasOverflowVal)
    setShiftsToShow(sortedShifts.slice(0, chipsToShow))
  }, [maxChips, numEvents, sortedShifts])

  return {
    hasAcknowledgedTask,
    numEvents,
    hasOverflow,
    shiftsToShow,
  }
}
