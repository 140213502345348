import React from 'react'
import { Box } from '@mui/material'
import { AuthenticationKind } from 'constants/authentication'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import RedesignThemeWrapper from 'redesign/components/atoms/RedesignThemeWrapper'
import HamburgerHeader from 'redesign/components/molecules/HamburgerHeader'
import SideMenuNavigation from 'redesign/components/templates/NavigationSideMenu'
import { isNative } from 'utils/platform'
import { isPlatform } from '@ionic/react'
import Menu from './Menu'
import { toolbarClasses } from './Menu/styles'
import { IToolbarProps } from './types'

const Toolbar: React.FC<IToolbarProps> = ({
  auth,
  navigate: displayMenu,
  toggleDrawer,
  hideHamburger,
  hideWebToolbar,
}) => {
  const redesignFF = Boolean(useFeatureFlag(FF_FAVUR_REDESIGN))
  const displayHamburgerHeader = auth === AuthenticationKind.AUTHENTICATED && !isNative()

  if (!isNative() && hideWebToolbar) {
    return null
  }

  return redesignFF ? (
    <>
      {isPlatform('ios') && !displayMenu && (
        <Box sx={toolbarClasses.toolbar}>
          <Box sx={(toolbarClasses.content, toolbarClasses.native)} />
        </Box>
      )}
      <RedesignThemeWrapper>
        <SideMenuNavigation
          hideWebToolbar={hideWebToolbar}
          toggleDrawer={toggleDrawer}
          displayMenu={displayMenu}
          auth={auth}
          showHamburgerHeader={displayHamburgerHeader}
        />
        {displayHamburgerHeader && (
          <HamburgerHeader
            toggleMenu={toggleDrawer}
            showLogo
            hamburguerTestId="hamburger-header"
            menuButtonTestId="navigation-icon"
            hideWebToolbar={hideWebToolbar}
          />
        )}
      </RedesignThemeWrapper>
    </>
  ) : (
    <Menu auth={auth} navigate={displayMenu} toggleDrawer={toggleDrawer} hideHamburger={hideHamburger} />
  )
}

export default Toolbar
