import React from 'react'
import {
  MiddleContainer,
  HeaderContainer,
  EndContainer,
  StartContainer,
  MainContainer,
  HeaderContent,
  DividerLine,
  ContentContainer,
} from './styles'
import type { HeaderProps } from './types'

const Header: React.FC<HeaderProps> = ({
  startContent,
  middleContent,
  endContent,
  subheader,
  withoutDivider = false,
  sidePadding = false,
  skipAdaptivePadding = false,
  isNavigationMenu,
}) => (
  <MainContainer>
    <ContentContainer skipAdaptivePadding={skipAdaptivePadding}>
      <HeaderContainer sidePadding={sidePadding}>
        <HeaderContent isNavigationMenu={isNavigationMenu}>
          <StartContainer isNavigationMenu={isNavigationMenu}>{startContent}</StartContainer>
          {!isNavigationMenu && <MiddleContainer>{middleContent}</MiddleContainer>}
          <EndContainer>{endContent}</EndContainer>
        </HeaderContent>
      </HeaderContainer>
      {subheader}
    </ContentContainer>
    {!withoutDivider && <DividerLine />}
  </MainContainer>
)

export default Header
