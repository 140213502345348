import React from 'react'
import { useTheme } from '@mui/material'
import { MoreHorizontalIcon } from '../../Icon'
import { ICON_SIZE } from '../../Icon/constants'
import { MoreEntries } from './styles'

const MoreEntriesTag: React.FC = () => {
  const { palette } = useTheme()

  return (
    <MoreEntries>
      <MoreHorizontalIcon size={ICON_SIZE.extraSmall} color={palette.common.greyMid} />
    </MoreEntries>
  )
}

export default MoreEntriesTag
