import React from 'react'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import { DrawerHeaderWrapper } from 'redesign/components/molecules/DrawerHeaderWrapper'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'
import Header from '../../../organisms/Header'
import { AbsencePlanSettings } from '../../AbsencePlanSettings'
import { ShiftPlanSettings } from '../../ShiftPlanSettings'
import { PARENT_PAGES, SIDE_MENU_OPTIONS, SIDE_MENU_TRANSLATIONS } from '../../SideMenu/constants'
import type { MenuPageProps } from '../../SideMenu/types'

const Settings: React.FC<MenuPageProps> = ({ onClose, t, setPage, isDrawer }) => {
  const { parentPage } = useSideMenuContext()

  return (
    <DrawerHeaderWrapper
      header={
        <Header
          startContent={isDrawer && <BackButton onGoBack={() => setPage(SIDE_MENU_OPTIONS.mainMenu)} />}
          middleContent={<HeaderLabel mainText={t(SIDE_MENU_TRANSLATIONS[SIDE_MENU_OPTIONS.settings])} />}
          endContent={isDrawer && <CloseButton onClick={onClose} />}
        />
      }
    >
      {parentPage === PARENT_PAGES.shiftPlan ? <ShiftPlanSettings /> : <AbsencePlanSettings />}
    </DrawerHeaderWrapper>
  )
}

export default Settings
