import React, { useCallback } from 'react'
import { IconButton } from '@mui/material'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import Divider from 'redesign/components/atoms/Divider'
import { DIVIDER_VARIANT } from 'redesign/components/atoms/Divider/constants'
import { ChevronLeftDuo } from 'redesign/components/atoms/Icon'
import { SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import type { MenuOptions } from 'redesign/components/templates/SideMenu/types'
import SideMenuIcon from 'redesign/components/templates/SideMenuIcons'
import { useSideMenuContent } from '../SideMenuContent/useSideMenuContent'
import { ContentContainer, IconBar, SideMenuWrapper } from './styles'

const SideMenuContent: React.FC = () => {
  const { PageContent, setPage, pages, open, taskCount, page, setOpen } = useSideMenuContent()
  const setTaskBarState = useTaskBarStateDispatchContext()

  const toggleOpen = useCallback(
    (clickedPage: MenuOptions) => {
      if (clickedPage === page) {
        setTaskBarState(null)
        setPage(undefined)
        setOpen(false)
      } else {
        setPage(clickedPage)
        setOpen(true)
      }
    },
    [page, setOpen, setPage, setTaskBarState],
  )

  return (
    <SideMenuWrapper>
      <Divider variant={DIVIDER_VARIANT.separator} />
      <IconBar>
        <IconButton
          onClick={() =>
            toggleOpen(
              page && page !== SIDE_MENU_OPTIONS.mainMenu ? SIDE_MENU_OPTIONS[page] : SIDE_MENU_OPTIONS.settings,
            )
          }
          size="large"
        >
          <ChevronLeftDuo rotate={open ? 180 : 0} />
        </IconButton>
        {pages.map((iconPage) => (
          <SideMenuIcon
            key={iconPage}
            toggleOpen={() => toggleOpen(SIDE_MENU_OPTIONS[iconPage])}
            page={iconPage}
            taskCount={iconPage === SIDE_MENU_OPTIONS.adminAbsences ? taskCount : undefined}
            selected={page === iconPage}
          />
        ))}
      </IconBar>
      <ContentContainer isOpen={open}>{PageContent}</ContentContainer>
    </SideMenuWrapper>
  )
}

export default SideMenuContent
