import React from 'react'
import { FilterProvider } from 'components/Filter/context'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import { DrawerHeaderWrapper } from 'redesign/components/molecules/DrawerHeaderWrapper'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import { SideMenuTaskListContent } from 'redesign/components/organisms/SideMenuTaskListContent'
import Header from '../../../organisms/Header'
import Filters from '../../Filters'
import { SIDE_MENU_OPTIONS, SIDE_MENU_ROOT_ID, SIDE_MENU_TRANSLATIONS } from '../../SideMenu/constants'
import type { MenuPageProps } from '../../SideMenu/types'
import ShiftUpdateDetail from '../ShiftUpdateDetail'
import { usePersonalShiftUpdates } from './usePersonalShiftUpdates'

const PersonalShiftUpdates: React.FC<MenuPageProps> = ({ onClose, t, setPage, isDrawer, parentPage }) => {
  const {
    initialFilters,
    onTaskClick,
    configuration,
    configLoading,
    shiftUpdateOpen,
    setShiftUpdateOpen,
  } = usePersonalShiftUpdates()

  if (configLoading) return <LoadingSpinner />

  if (shiftUpdateOpen) {
    return (
      <ShiftUpdateDetail
        onClose={() => {
          setShiftUpdateOpen(false)
          onClose()
        }}
        onGoBack={() => setShiftUpdateOpen(false)}
        parentPage={parentPage}
        t={t}
      />
    )
  }

  return (
    <FilterProvider configuration={configuration} name="personal_shift_update_list">
      <DrawerHeaderWrapper
        header={
          <Header
            startContent={isDrawer && <BackButton onGoBack={() => setPage(SIDE_MENU_OPTIONS.mainMenu)} />}
            middleContent={<HeaderLabel mainText={t(SIDE_MENU_TRANSLATIONS[SIDE_MENU_OPTIONS.personalShiftUpdates])} />}
            endContent={isDrawer && <CloseButton onClick={onClose} />}
          />
        }
      >
        <SideMenuTaskListContent initialFilters={initialFilters} onTaskClick={onTaskClick} />
      </DrawerHeaderWrapper>
      <Filters rootId={SIDE_MENU_ROOT_ID} />
    </FilterProvider>
  )
}

export default PersonalShiftUpdates
