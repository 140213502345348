import { styled } from '@mui/material'

export const EffectiveTimeContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`
export const CardContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`

export const IconHeader = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
`
