import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Headline from 'redesign/components/atoms/Headline'
import { HEADLINE_LEVELS } from 'redesign/components/atoms/Headline/constants'
import { TaskListElement } from 'shared/graphql/graphql'
import type { PlannedShiftT } from 'types'
import { getShiftKey } from 'types/utils'
import ShiftPlanCard from '../../ShiftPlanCard'
import TaskReferenceCard from '../../TaskReferenceCard/TaskReferenceCard'
import { ShiftsContainer } from '../styles'
import type { PlannedTimeCardsProps } from './types'

const PlannedTimeCards: React.FC<PlannedTimeCardsProps> = ({
  date,
  tenant,
  absenceRequests,
  onTaskClick,
  groupedShifts,
  updateTask,
  isPast,
  inAbsencePlanner,
}) => {
  const { t } = useFavurTranslation()
  const showPlannedTime = isPast && !inAbsencePlanner

  return (
    <ShiftsContainer>
      {showPlannedTime && Boolean(groupedShifts.length) && (
        <Headline
          noBottomSpacing
          level={HEADLINE_LEVELS.third}
          text={t('page.shifts.shiftCard.plannedTime')}
          testId="shift-card__planned-shifts"
        />
      )}
      {groupedShifts.map((costcenter) => (
        <ShiftsContainer key={`${date}-${tenant.id}-${costcenter.costCenterUuid}`}>
          {costcenter.shifts.map((shifts) => {
            const firstShift = shifts[0] as PlannedShiftT

            return (
              <ShiftPlanCard
                date={date}
                isAccordion={showPlannedTime}
                shifts={shifts}
                key={`${date}-${tenant.id}-${costcenter.costCenterUuid}-${getShiftKey(firstShift)}`}
              />
            )
          })}
        </ShiftsContainer>
      ))}
      {absenceRequests.map((absenceRequest) => (
        <TaskReferenceCard
          key={`absence-request-${absenceRequest.id}`}
          testId="absence-request-card"
          task={absenceRequest as TaskListElement}
          onTaskClick={onTaskClick}
        />
      ))}
      {updateTask && (
        <TaskReferenceCard
          key={`shift-update-${updateTask.id}`}
          testId="shift-update-task-card"
          task={updateTask as TaskListElement}
          onTaskClick={onTaskClick}
        />
      )}
    </ShiftsContainer>
  )
}

export default PlannedTimeCards
