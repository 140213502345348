import React from 'react'
import { Typography, Link, Box, Paper } from '@mui/material'
import Nbsp from 'components/Nbsp'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSessionContext from 'hooks/useSessionContext'
import { Announcement, ArrowRightThinS, Bird36, EmployersM, FeedbackM, QuestionsM } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import theme from 'utils/theme'
import { classes } from './styles'

const ContactContent: React.FC<{ anonymous?: boolean }> = ({ anonymous }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { auth } = useSessionContext()
  const boxClasses = [...(anonymous ? [classes.anonymous] : []), classes.block]
  return (
    <>
      <Box component={Paper} sx={boxClasses}>
        <Box sx={[classes.icon, classes.bird]}>
          <Bird36 />
        </Box>
        <Box>
          <Typography variant="body2" sx={classes.gutterBottom}>
            <strong>{t('page.contact.favur.title')}</strong>
          </Typography>
          <Typography variant="body2">{t('page.contact.favur.text')}</Typography>
        </Box>
      </Box>
      <Box component={Paper} sx={boxClasses}>
        <Box sx={[classes.icon, classes.sizeM]}>
          <QuestionsM fill={theme.palette.secondary.main} />
        </Box>
        <Box>
          <Typography variant="body2" sx={classes.gutterBottom}>
            <strong>{t('page.contact.questions.title')}</strong>
          </Typography>
          <Typography variant="body2">{t('page.contact.questions.text')}</Typography>
        </Box>
      </Box>
      <Box component={Paper} sx={boxClasses}>
        <Box sx={[classes.icon, classes.sizeM]}>
          <FeedbackM fill={theme.palette.secondary.main} />
        </Box>
        <Box>
          <Typography variant="body2" sx={classes.gutterBottom}>
            <strong>{t('page.contact.feedback.title')}</strong>
          </Typography>
          <Typography variant="body2">
            {t('page.contact.feedback.text')}
            <Nbsp />
            <Link
              href={t('page.contact.url.feedback') as string}
              target="_blank"
              sx={classes.link}
              data-testid="feedback"
              underline="hover"
            >
              www.favur.ch/feedback
            </Link>
            .
          </Typography>
        </Box>
      </Box>
      <Box component={Paper} sx={[...boxClasses, classes.noFlexGrow]}>
        <Box sx={[classes.icon, classes.sizeM]}>
          <EmployersM fill={theme.palette.secondary.main} />
        </Box>
        <Box>
          <Typography variant="body2" sx={classes.gutterBottom}>
            <strong>{t('page.contact.employers.title')}</strong>
          </Typography>
          <Typography variant="body2">
            {t('page.contact.employers.text')}
            <Nbsp />
            <Link
              href={t('page.contact.url.favur') as string}
              target="_blank"
              sx={classes.link}
              data-testid="favur-website"
              underline="hover"
            >
              www.favur.ch
            </Link>
            .
          </Typography>
        </Box>
      </Box>
      {auth > 0 && (
        <Box
          component={Paper}
          sx={[...boxClasses, classes.noFlexGrow, classes.cursorPointer, classes.justifyContent]}
          onClick={() => history.push(routes.announcements)}
        >
          <Box sx={classes.content}>
            <Box sx={[classes.icon, classes.sizeM]}>
              <Announcement
                fill={theme.palette.secondary.main}
                stroke={theme.palette.secondary.main}
                {...classes.iconAnnouncement}
              />
            </Box>
            <Box>
              <Typography variant="body2" sx={classes.gutterBottom}>
                <strong>{t('page.contact.announcements.title')}</strong>
              </Typography>
              <Typography variant="body2">{t('page.contact.announcements.text')}</Typography>
            </Box>
          </Box>
          <Box sx={classes.alignSelfStart}>
            <ArrowRightThinS />
          </Box>
        </Box>
      )}
    </>
  )
}

export default ContactContent
