import React from 'react'
import { Radio as MuiRadio } from '@mui/material'
import { Label, ControlLabel } from './styles'
import type { RadioProps } from './types'

const Radio: React.FC<RadioProps> = (props) => {
  const { value, label, labelPlacement } = props

  return (
    <ControlLabel
      value={value}
      control={<MuiRadio {...props} />}
      label={<Label variant="body1">{label}</Label>}
      labelPlacement={labelPlacement}
    />
  )
}

export default Radio
