import { ComponentsOverrides, Theme } from '@mui/material'

const styleOverrides: ComponentsOverrides<Theme>['MuiPopover'] = {
  root: ({ theme }) => ({
    '& .MuiPopover-paper': {
      borderRadius: '1rem',
      border: `0.0625rem solid ${theme.palette.common.cardBorder}`,
      boxShadow: `
        ${theme.shadows[1]},
        ${theme.shadows[2]},
        ${theme.shadows[3]}
      `,
    },
  }),
}
const MuiPopover = {
  styleOverrides,
}

export default MuiPopover
