import React from 'react'
import { absenceRequest, cmsEvent, shiftTypes } from 'constants/shift'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { colorModes as COLOR_MODES } from 'pages/AbsencePlanner/components/TaskBar/types'
import type { ColorModesT } from 'pages/AbsencePlanner/components/TaskBar/types'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import type { AbsencePlannerBadgeTypesT } from 'pages/AbsencePlanner/types'
import useCms from '../../../../../hooks/useCms'

export const useAbsenceEntryTypes = () => {
  const { t } = useFavurTranslation()
  const { cmsEventsAccess } = useCms()
  const { greyscaleMode: colorMode, setGreyscaleMode: setColorMode, shownTypes, setShownTypes } = useFilterContext()

  const handleCheckChange = (absencePlanerBadgeType: AbsencePlannerBadgeTypesT, show: boolean) =>
    setShownTypes(
      show
        ? shownTypes.filter((shownType) => shownType !== absencePlanerBadgeType)
        : shownTypes.concat(absencePlanerBadgeType),
    )

  const options = [
    {
      label: t('absencePlanner.taskbar.types.absences'),
      key: t('absencePlanner.taskbar.types.absences'),
      checked: shownTypes.includes(shiftTypes.absence),
      onChange: () => handleCheckChange(shiftTypes.absence, shownTypes.includes(shiftTypes.absence)),
      dataTestId: 'absencePlanner-filter-absence',
    },
    {
      label: t('absencePlanner.taskbar.types.absenceRequests'),
      key: t('absencePlanner.taskbar.types.absenceRequests'),
      checked: shownTypes.includes(absenceRequest),
      onChange: () => handleCheckChange(absenceRequest, shownTypes.includes(absenceRequest)),
      dataTestId: 'absencePlanner-filter-absenceRequest',
    },
    {
      label: t('absencePlanner.taskbar.types.shifts'),
      key: t('absencePlanner.taskbar.types.shifts'),
      checked: shownTypes.includes(shiftTypes.plannedShift),
      onChange: () => handleCheckChange(shiftTypes.plannedShift, shownTypes.includes(shiftTypes.plannedShift)),
      dataTestId: 'absencePlanner-filter-shift',
    },
    ...(cmsEventsAccess
      ? [
          {
            label: t('absencePlanner.taskbar.types.cmsEvent'),
            key: t('absencePlanner.taskbar.types.cmsEvent'),
            checked: shownTypes.includes(cmsEvent),
            onChange: () => handleCheckChange(cmsEvent, shownTypes.includes(cmsEvent)),
            dataTestId: 'absencePlanner-filter-cmsEvent',
          },
        ]
      : []),
    {
      label: t('absencePlanner.taskbar.colorChanger.greyscale'),
      checked: colorMode === COLOR_MODES.greyscale,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target
        const newValue = checked ? COLOR_MODES.greyscale : COLOR_MODES.colorful

        setColorMode(newValue as ColorModesT)
      },
    },
  ]

  return {
    options,
    headlineTitle: t('absencePlanner.taskbar.filter.showEntryTypes'),
  }
}
