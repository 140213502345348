import React, { useMemo } from 'react'
import { PlannedShiftT } from 'types'
import Badges from '../Badges'
import NextDayEvents from '../NextDayEvents'
import { INextShiftProps } from '../types'

const NextShift: React.FC<INextShiftProps> = ({ shiftsByTenants, shiftUpdates, events }) => {
  const notMultitenantShifts = useMemo(() => shiftsByTenants.map((tenant) => tenant.shifts).flat(), [shiftsByTenants])

  return (
    <>
      <Badges shifts={notMultitenantShifts as PlannedShiftT[]} shiftUpdates={shiftUpdates} />
      <NextDayEvents events={events} tenantId={shiftsByTenants[0].tenantId} ownerId={shiftsByTenants[0].ownerId} />
    </>
  )
}

export default NextShift
