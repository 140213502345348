import React from 'react'
import { AbsenceEntryTypes } from 'redesign/components/organisms/AbsencePlanSettingsBlocks/AbsenceEntryTypes'
import AbsenceTeamSettings from 'redesign/components/organisms/AbsencePlanSettingsBlocks/AbsenceTeamSettings/AbsenceTeamSettings'
import OwnerSettingsBlock from 'redesign/components/organisms/AbsencePlanSettingsBlocks/OwnerSettingsBlock/OwnerSettingsBlock'
import { Container } from './styles'

const AbsencePlanSettings: React.FC = () => (
  <Container>
    <AbsenceEntryTypes />
    <AbsenceTeamSettings />
    <OwnerSettingsBlock />
  </Container>
)

export default AbsencePlanSettings
