export const Z_INDEX_PULL_TO_REFRESH_INDICATOR = 100000
export const Z_INDEX_FULL_SCREEN_MODAL_SHEET = 1200
export const Z_INDEX_DRAWER_HEADER = 1000
export const Z_INDEX_DIALOG = 800
export const Z_INDEX_SWIPEABLE_DRAWER = 30
export const Z_INDEX_NAVIGATION_MENU_BACKGROUND = 15
export const Z_INDEX_TEAM_CONTAINER = 11
export const Z_INDEX_SIDE_MENU = 10
export const Z_INDEX_PLACEHOLDER_CONTAINER = 9
export const Z_INDEX_DEFAULT_VALUE = 1
