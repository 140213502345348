import React from 'react'
import { Typography, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import CardWidget from 'redesign/components/atoms/CardWidget'
import ShiftTags from 'redesign/components/molecules/ShiftTags'
import NoShifts from './NoShifts'
import { ContentContainer, DateContainer, DateSpan } from './styles'
import type { TShiftCard } from './types'
import { getProcessedShiftDate } from './utils'

const ShiftsCard: React.FC<TShiftCard> = ({ date, tenants, events, shiftUpdates, locale, onClick, personTenants }) => {
  const theme = useTheme()
  const { t } = useFavurTranslation()
  const { dateIsToday, dayOfWeek, dayOfMonth, month } = getProcessedShiftDate({ date, locale, t })

  return (
    <CardWidget onClick={onClick}>
      <ContentContainer>
        <DateContainer>
          <Typography
            variant="caption"
            color={theme.palette.text.secondary}
            data-testid={`dashboard-shifts__card--${dayOfMonth}`}
          >
            {dayOfWeek}
          </Typography>
          <DateSpan isToday={dateIsToday}>
            <Typography variant="h6">{dayOfMonth}</Typography>
            <Typography variant="body2">{month}</Typography>
          </DateSpan>
        </DateContainer>
        {personTenants?.length ? (
          <ShiftTags events={events} tenants={tenants} shiftUpdates={shiftUpdates} />
        ) : (
          <NoShifts />
        )}
      </ContentContainer>
    </CardWidget>
  )
}

export default ShiftsCard
