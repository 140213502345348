import { useMemo } from 'react'
import { getAreThereShifts } from 'components/ShiftsList/components/ShiftCard/utils'
import { PlannedShiftT } from 'types'
import { getTodayFormatted } from 'utils/date'
import { sortGroupedShifts, groupShiftsByShortDescription } from 'utils/shifts'
import type { TUseShiftData } from './types'

export const useShiftData = ({ date, events, shiftData }: TUseShiftData) => {
  const showEvents = useMemo(() => Boolean(events?.length), [events?.length])
  const groupedShifts = useMemo(
    () =>
      shiftData.map((costcenter) => ({
        costCenterName: costcenter.costCenterName,
        costCenterUuid: costcenter.costCenterUuid,
        shifts: sortGroupedShifts(groupShiftsByShortDescription(costcenter.shifts as PlannedShiftT[])),
      })),
    [shiftData],
  )
  const areThereShifts = getAreThereShifts(groupedShifts)
  const isPast = Boolean(date && date < getTodayFormatted())

  return {
    groupedShifts,
    areThereShifts,
    showEvents,
    isPast,
  }
}
