import React from 'react'
import { useTheme } from '@mui/material'
import { Container, Text } from './styles'
import type { HeaderLabelProps } from './types'

const HeaderLabel: React.FC<HeaderLabelProps> = ({ mainText, subText, mainTextDataTestId, subTextDataTestId }) => {
  const theme = useTheme()

  return (
    <Container>
      <Text data-testid={mainTextDataTestId} color={theme.palette.text.primary} variant="subtitle1">
        {mainText}
      </Text>
      {subText && (
        <Text data-testid={subTextDataTestId} variant="body2" color={theme.palette.text.secondary}>
          {subText}
        </Text>
      )}
    </Container>
  )
}

export default HeaderLabel
