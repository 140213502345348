import type { FilterStateT } from 'components/Filter/types'
import useTasksQueryPaginated from 'redesign/shared/hooks/useTasksQueryPaginated'
import { TASK_LIMIT } from '../../components/templates/ContentLists/TaskList/constants'
import type { UseTaskListProps } from './types'

export const useTaskList = ({ initialFilters = {}, isOffice }: UseTaskListProps) => {
  const {
    updateFilters,
    updateOffice,
    hasMoreElements,
    list: tasks,
    loading: tasksLoading,
    loadMore,
    reload,
    totalCount,
  } = useTasksQueryPaginated({ initialOffice: isOffice, limit: TASK_LIMIT, initialFilters: initialFilters })
  const handleFilterChange = (filters: FilterStateT) => updateFilters(filters)

  return {
    isOffice,
    updateFilters,
    updateOffice,
    hasMoreElements,
    handleFilterChange,
    list: tasks ?? [],
    loading: tasksLoading,
    loadMore,
    reload,
    showLoadMoreButton: (totalCount === undefined || hasMoreElements) && Boolean(tasks.length),
  }
}
