import { styled } from '@mui/material'

export const FlexContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'gap' && prop !== 'directionColumn',
})<{ gap?: number; directionColumn?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: ${({ directionColumn }) => (directionColumn ? 'column' : 'row')};
  gap: ${({ theme, gap = 1 }) => theme.spacing(gap)};
`
