import React from 'react'
import { IconButton, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { MoreVertical } from 'redesign/components/atoms/Icon'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'
import Header from '../../Header'

const AbsencePlannerHeader: React.FC = () => {
  const { t } = useFavurTranslation()
  const theme = useTheme()
  const { setOpen } = useSideMenuContext()
  const { isSmallScreen } = useAbsencePlannerStateContext()

  return (
    <Header
      middleContent={<HeaderLabel mainText={t('page.absencePlanner.title')} />}
      endContent={
        isSmallScreen && (
          <IconButton onClick={() => setOpen(true)} size="medium">
            <MoreVertical color={theme.palette.primary.main} />
          </IconButton>
        )
      }
      withoutDivider={isSmallScreen}
    />
  )
}

export default AbsencePlannerHeader
