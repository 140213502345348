import React, { memo, useCallback } from 'react'
import { Box } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useAvatarSidebarStateContext, {
  useAvatarSidebarStateDispatchContext,
} from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import ArrowIcon from './ArrowIcon'
import { avatarSideBarClasses } from './styles'

interface IToggleSideBarButtonProps {
  isSmallScreen: boolean
  isAtBottom: boolean
  hasOverflow: boolean
}

const ToggleSideBarButton: React.FC<IToggleSideBarButtonProps> = ({ isSmallScreen, isAtBottom, hasOverflow }) => {
  const setAvatarBarExpanded = useAvatarSidebarStateDispatchContext()
  const { avatarBarExpanded: open } = useAvatarSidebarStateContext()
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN)

  const toggleOpen = useCallback(() => {
    if (!isSmallScreen) {
      setAvatarBarExpanded((currentState) => !currentState)
      return
    }
    setAvatarBarExpanded(true)
  }, [isSmallScreen, setAvatarBarExpanded])

  if (redesignFF) return <></>

  return (
    <Box display="flex">
      <Box
        sx={avatarSideBarClasses.toggleButtonContainer(hasOverflow, isAtBottom, open)}
        onClick={() => toggleOpen()}
        data-testid="absence-planner-toggle_avatar_sidebar"
      >
        <Box sx={avatarSideBarClasses.bottomDivisionLineContainer}>
          <DivisionLine />
        </Box>
        <Box sx={avatarSideBarClasses.toggleButtonWrapper(open)}>
          <Box sx={avatarSideBarClasses.toggleButton}>
            <ArrowIcon open={open} isRedesign={false} onClick={() => {}} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(ToggleSideBarButton)
