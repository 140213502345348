import { Popover, styled } from '@mui/material'

export const ContentContainer = styled('div')`
  width: 100%;
  min-width: ${({ theme }) => theme.spacing(40)};
  max-width: ${({ theme }) => theme.spacing(40)};
  max-height: ${({ theme }) => theme.spacing(60)};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const ChildContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.backgroundApp};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
`

export const MuiPopover = styled(Popover)``
