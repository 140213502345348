import React from 'react'
import { FormGroup, FormHelperText, Typography } from '@mui/material'
import Checkbox from 'redesign/components/atoms/Checkbox'
import { Container, LegendContainer } from './styles'
import type { CheckboxFormGroupProps } from './types'

const CheckboxFormGroup: React.FC<CheckboxFormGroupProps> = ({
  checkboxes = [],
  disabled,
  error,
  helperText,
  legend,
  size = 'medium',
}) => (
  <Container disabled={disabled} error={error}>
    {legend && (
      <LegendContainer>
        <Typography color="textPrimary" variant="overline">
          {legend}
        </Typography>
      </LegendContainer>
    )}
    <FormGroup>
      {checkboxes.map((props) => (
        <Checkbox size={size} {...props} key={`${props?.key}`} />
      ))}
    </FormGroup>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </Container>
)

export default CheckboxFormGroup
