import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction as MuiBottomNavigationAction,
  styled,
} from '@mui/material'
import { Z_INDEX_DEFAULT_VALUE } from 'redesign/shared/zIndex/constants'

export const Container = styled('div')`
  z-index: ${Z_INDEX_DEFAULT_VALUE};
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => `calc(${theme.spacing(0.5)} + env(safe-area-inset-bottom))`};
  border-top: ${({ theme }) => `${theme.spacing(0.125)} solid ${theme.palette.common.cardBorder}`};
  box-shadow: ${({ theme }) => theme.spacing(0, 0.5, 2)} rgba(20, 26, 51, 0.06),
    ${({ theme }) => theme.spacing(0, 0.125, 1)} rgba(20, 24, 51, 0.04);
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const BottomNavigation = styled(MuiBottomNavigation)`
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(75)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
`

export const BottomNavigationAction = styled(MuiBottomNavigationAction)`
  gap: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(1, 0.5)};

  & .Mui-selected,
  & .active,
  & .MuiBottomNavigationAction-iconOnly {
    font-size: 0 !important;
    padding: unset;
  }
`
