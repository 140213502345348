import React, { FC } from 'react'
import { useMemo } from 'react'
import useFilterContext from 'components/Filter/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import FilterButton from 'redesign/components/atoms/Buttons/FilterButton'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import SubheaderFilters from 'redesign/components/molecules/TabBar/SubheaderFilters'
import Header from 'redesign/components/organisms/Header'
import { CloseButton } from 'redesign/components/organisms/ShiftDialog/styles'
import { SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import { FlexContainer } from './styles'
import type { SideMenuHeaderProps } from './types'

const SideMenuHeader: FC<SideMenuHeaderProps> = ({ isDrawer, setPage, onClose, titleKey }) => {
  const { t } = useFavurTranslation()
  const headerTitle = useMemo(() => <HeaderLabel mainText={t(titleKey)} />, [t, titleKey])
  const { openFilterPage } = useFilterContext()

  return (
    <Header
      skipAdaptivePadding
      startContent={
        isDrawer && (
          <FlexContainer gap={2}>
            <BackButton onGoBack={() => setPage(SIDE_MENU_OPTIONS.mainMenu)} />
            {headerTitle}
          </FlexContainer>
        )
      }
      middleContent={!isDrawer && headerTitle}
      endContent={
        <FlexContainer>
          <FilterButton onClick={openFilterPage} />
          {isDrawer && <CloseButton onClick={onClose} />}
        </FlexContainer>
      }
      subheader={<SubheaderFilters />}
    />
  )
}

export default SideMenuHeader
