import { useCallback, useEffect, useMemo } from 'react'
import { getTaskIds } from 'components/ShiftsList/components/utils'
import { DASHBOARD_NEXT_SHIFT_DATE } from 'constants/shift'
import { useEventsDateRange } from 'hooks/useEventsDateRange'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import useModule from 'hooks/useModule'
import useNotificationCounts from 'hooks/useNotificationCounts'
import usePersonsOfUser from 'hooks/usePersonsOfUser'
import usePSN from 'hooks/usePushNotifications'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import useShifts from 'hooks/useShifts'
import useTaskCounts from 'hooks/useTaskCounts'
import useUserSettings from 'hooks/useUserSettings'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import { getTasksByDay } from 'pages/Tasks/utils'
import { useHistory } from 'react-router-dom'
import { useAppStatesContext } from 'services/AppStates'
import routes from 'services/RoutesProvider/routes'
import { modules } from 'shared/constants'
import type { Person } from 'shared/graphql/graphql'
import type { ShiftsByTenantAndCostCenter } from 'shared/graphql/graphql'
import type { RoleViewT, TenantT } from 'types'
import { isNativeNoOverride } from 'utils/platform'
import { getDashboardNextShiftDates } from 'utils/shifts'
import { PushNotifications } from '@capacitor/push-notifications'
import { getEmployeeCards, getTenantLinks } from './utils'

export const useDashboardPage = () => {
  const { locale } = useFavurTranslation()
  const { hasAccessToModule } = useModule()
  const { pushActiveOnDevice } = usePSN({ refresh: false })
  const { refresh, personIds } = useSessionContext()
  const { hasActiveAndResignedPersons, hasOfficeView: isManager, setActiveView } = useRolesViews()
  const { taskCounts, loading: taskCountsLoading } = useTaskCounts({ showSecondView: true })
  const history = useHistory()
  const {
    counts: { manager, personal },
  } = useNotificationCounts()
  const { start, end } = getDashboardNextShiftDates()
  const { settings, loading: settingsLoading } = useUserSettings()
  const { persons, loading: personsLoading } = usePersonsOfUser()
  const { set } = useAppStatesContext()
  const { pushWithRole } = useHistoryUtils()
  const employeeCards = useMemo(() => {
    if (settingsLoading || personsLoading || !settings) return []

    return getEmployeeCards({ persons: persons as Person[], settings, history })
  }, [history, persons, personsLoading, settings, settingsLoading])

  const tenantLinks = useMemo(() => (personsLoading ? [] : getTenantLinks({ persons: persons as Person[], locale })), [
    locale,
    persons,
    personsLoading,
  ])
  const { eventsByDate } = useEventsDateRange({ startDate: start, endDate: end })
  const { isMultitenant, shifts } = useShifts({
    dates: { start, end },
    displayFull: true,
    personIds,
  })
  const taskIds = getTaskIds(shifts)
  const { tasks } = useShiftUpdates({ taskIds })
  const groupedTasks = getTasksByDay(tasks, locale)

  const firstDayTenants = () => {
    if (shifts[0]?.date === start) {
      return shifts[0]?.tenants ?? []
    }

    if (shifts[1]?.date === start) {
      return shifts[1]?.tenants ?? []
    }

    return []
  }

  const secondDayTenants = () => {
    if (shifts[0]?.date === end) {
      return shifts[0]?.tenants ?? []
    }

    if (shifts[1]?.date === end) {
      return shifts[1]?.tenants ?? []
    }

    return []
  }

  const totalNotifications = isManager ? manager + personal : personal
  const isNoOverrideNative = isNativeNoOverride()

  const taskOnClick = useCallback(
    (role: RoleViewT) => {
      setActiveView(role)
      history.push(`${routes.tasks}/todo`)
    },
    [history, setActiveView],
  )

  const header = {
    totalNotifications,
    hasActiveAndResignedPersons,
  }
  const taskSection = {
    taskCounts,
    taskOnClick,
  }

  const shiftClick = (date: string) => {
    set(DASHBOARD_NEXT_SHIFT_DATE, date)
    pushWithRole(routes.shifts)
  }

  const personTenants = useMemo(
    () =>
      (persons ?? []).reduce<TenantT[]>((acc, person) => {
        return person?.tenant ? [...acc, person.tenant] : acc
      }, []),
    [persons],
  )

  const shiftsSection = {
    firstDay: {
      date: start,
      tenants: (firstDayTenants() as unknown) as ShiftsByTenantAndCostCenter[],
      shiftUpdates: groupedTasks.get(start) ?? [],
      events: eventsByDate.get(start) ?? [],
      onClick: () => shiftClick(start),
      personTenants,
    },
    secondDay: {
      date: end,
      tenants: (secondDayTenants() as unknown) as ShiftsByTenantAndCostCenter[],
      shiftUpdates: groupedTasks.get(end) ?? [],
      events: eventsByDate.get(end) ?? [],
      onClick: () => shiftClick(end),
      personTenants,
    },
    isMultitenant,
  }

  const quickLinksSection = {
    employeeCards,
    tenantLinks,
    showSection: Boolean(employeeCards.length || tenantLinks.length) && hasAccessToModule(modules.employeeCards),
  }

  const isLoading = taskCountsLoading

  useEffect(() => {
    if (isNoOverrideNative && pushActiveOnDevice) {
      PushNotifications.removeAllDeliveredNotifications()
    }
  }, [pushActiveOnDevice, isNoOverrideNative])

  useEffect(() => {
    refresh()
  }, [refresh])

  return {
    header,
    isLoading,
    isManager,
    isNoOverrideNative,
    taskSection,
    shiftsSection,
    quickLinksSection,
    hasAccessToModuleShiftPlan: hasAccessToModule(modules.shiftPlan),
    hasAccessToModuleTasks: hasAccessToModule(modules.tasks),
  }
}
