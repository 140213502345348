import React from 'react'
import CheckboxFormGroup from 'redesign/components/molecules/CheckboxFormGroup'
import { useAbsenceTeamSettings } from './useAbsenceTeamSettings'

const AbsenceTeamSettings: React.FC = () => {
  const { options } = useAbsenceTeamSettings()
  const { primaryTeams, secondaryTeams } = options

  return (
    <>
      {Boolean(primaryTeams.teams.length) && (
        <CheckboxFormGroup legend={primaryTeams.title} checkboxes={primaryTeams.teams} />
      )}
      {Boolean(secondaryTeams.teams.length) && (
        <CheckboxFormGroup legend={secondaryTeams.title} checkboxes={secondaryTeams.teams} />
      )}
    </>
  )
}

export default AbsenceTeamSettings
