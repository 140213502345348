import { css, styled } from '@mui/material'
import { Sheet } from 'react-modal-sheet'
import { Z_INDEX_FULL_SCREEN_MODAL_SHEET } from 'redesign/shared/zIndex/constants'

export const CustomSheet = styled(Sheet)`
  z-index: ${Z_INDEX_FULL_SCREEN_MODAL_SHEET} !important;
`

export const ContentContainer = styled('div')`
  height: 100%;
  flex: 1;
  overflow: scroll;
`

export const ContentDesktopContainer = styled('div')`
  height: 100%;
  flex: 1;
  overflow: auto;
`

export const FooterContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'extraBottomPadding' && prop !== 'removeBottomPadding',
})<{ extraBottomPadding?: boolean; removeBottomPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing(2, 2, 0, 2)};
  ${({ removeBottomPadding, theme, extraBottomPadding }) =>
    !removeBottomPadding &&
    css`
      padding-bottom: calc(${extraBottomPadding ? theme.spacing(2) : theme.spacing(0.5)} + env(safe-area-inset-bottom));
    `}

  margin-top: auto;
  bottom: 0;
  background: ${({ theme }) => theme.palette.background.default};
  border-top: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
`
