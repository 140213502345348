import { Paper, Popper, styled } from '@mui/material'
import { PickersLayout } from '@mui/x-date-pickers/PickersLayout'
import type { PopperComponentProps } from './types'

export const CalendarHeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 2, 0, 2)};
`

export const Container = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;
`

export const CustomLayout = styled(PickersLayout)`
  border: ${({ theme }) => theme.spacing(0.125)} solid ${({ theme }) => theme.palette.common.cardBorder};
  border-radius: ${({ theme }) => theme.spacing(2)};

  .MuiPickersMonth-root {
    width: ${({ theme }) => theme.spacing(10.25)};
    height: ${({ theme }) => theme.spacing(5)};
  }

  .MuiPickersMonth-root > button {
    /* body1 styles */
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(2)};
    line-height: 1.5;
    letter-spacing: 0.01em;

    :hover {
      color: ${({ theme }) => theme.palette.common.white};
      background-color: ${({ theme }) => theme.palette.primary.main};
      border-radius: ${({ theme }) => theme.spacing(1)};
    }
  }

  .MuiPickersMonth-monthButton.Mui-selected {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.text.primary};

    :focus {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.text.primary};
    }

    :hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }

  .MuiDateCalendar-root,
  .MuiPickersFadeTransitionGroup-root {
    width: ${({ theme }) => theme.spacing(35)};
  }

  .MuiMonthCalendar-root {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: ${({ theme }) => theme.spacing(31)};
    margin: 0 auto;
    padding: ${({ theme }) => theme.spacing(2, 0)};
  }

  .MuiPickersLayout-contentWrapper {
    max-width: ${({ theme }) => theme.spacing(35)};
  }

  > div > div {
    height: 100%;
  }
`

export const CustomPaper = styled(Paper)`
  border-radius: ${({ theme }) => theme.spacing(2)};
`

export const PopperComponent = ({ top, left }: PopperComponentProps) => styled(Popper)`
  top: ${top}px !important;
  left: ${left}px !important;
  border-radius: ${({ theme }) => theme.spacing(2)} !important;
  width: ${({ theme }) => theme.spacing(35)} !important;
`
