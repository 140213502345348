import { Drawer, styled } from '@mui/material'

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    background-color: ${({ theme }) => theme.palette.common.backgroundCard};
  }

  & > div {
    overflow: hidden;
  }
`
export const Backdrop = styled('div')`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.black};
`
export const ModalAncor = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
