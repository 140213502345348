import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { GeneralSettings } from 'redesign/components/organisms/ShiftPlanSettingsBlocks/GeneralSettings'
import { TeamSettingsBlock } from 'redesign/components/organisms/ShiftPlanSettingsBlocks/TeamSettingsBlock'
import { useSwipeableView } from 'redesign/services/SwipeableView/useSwipeableView'
import { FiltersContainer } from './styles'

const ShiftPlanSettings: React.FC = () => {
  const { sideMenuData } = useSwipeableView()
  const { t } = useFavurTranslation()
  const {
    teams,
    publicTeams,
    selectedPublicTeams,
    selectedTeams,
    setSelectedPublicTeams,
    setSelectedTeams,
  } = sideMenuData

  return (
    <FiltersContainer>
      <GeneralSettings />
      <TeamSettingsBlock
        teams={teams}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        headlineLabel={t('page.shifts.sideMenu.myTeams')}
        filterKey="selectedTeams"
      />
      <TeamSettingsBlock
        teams={publicTeams}
        selectedTeams={selectedPublicTeams}
        setSelectedTeams={setSelectedPublicTeams}
        headlineLabel={t('page.shifts.sideMenu.otherTeams')}
        filterKey="selectedPublicTeams"
      />
    </FiltersContainer>
  )
}

export default ShiftPlanSettings
