import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { PlannedShiftT } from 'types'
import { newDateWithoutTime } from 'utils/date'
import Badges from '../Badges'
import NextDayEvents from '../NextDayEvents'
import { classes } from '../styles'
import { INextShiftProps } from '../types'

const NextShiftMultiTenant: React.FC<INextShiftProps> = ({ date, shiftsByTenants, shiftUpdates, events }) => {
  const { dayOfMonth } = useMemo(() => {
    const dateObject = newDateWithoutTime(date)
    return { dayOfMonth: dateObject.getDate() }
  }, [date])

  return (
    <>
      {shiftsByTenants.map((tenant, index) => (
        <Box sx={classes.tenantAndShiftsBoxList} key={`tenant-${tenant.tenantId}-${dayOfMonth}`}>
          <Box sx={classes.tenantAndShiftsBox}>
            <Typography variant="caption" sx={classes.tenantName}>
              {tenant.tenantName}
            </Typography>
            <Badges shifts={tenant.shifts as PlannedShiftT[]} shiftUpdates={shiftUpdates} />
            <NextDayEvents events={events} tenantId={tenant.tenantId} ownerId={tenant.ownerId} />
          </Box>

          {index + 1 < shiftsByTenants.length && <DivisionLine />}
        </Box>
      ))}
    </>
  )
}

export default NextShiftMultiTenant
