import { Box, styled } from '@mui/material'
import { Z_INDEX_NAVIGATION_MENU_BACKGROUND } from 'redesign/shared/zIndex/constants'

export const Background = styled('div')`
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  z-index: ${Z_INDEX_NAVIGATION_MENU_BACKGROUND};
  background-color: ${({ theme }) => theme.palette.common.backgroundSideMenu};
`

export const BottomScroll = styled(Box)`
  position: absolute;
  width: 100%;
  height: ${({ theme }) => theme.spacing(4.5)};
  background: linear-gradient(180deg, rgba(24, 51, 101, 0) 0%, rgba(255, 255, 255, 0.28) 100%);
  bottom: ${({ theme }) =>
    `calc(max(var(--ion-safe-area-bottom), ${theme.spacing(0.75)}) + ${theme.spacing(1.25)} + ${theme.spacing(
      1.5,
    )} + ${theme.spacing(0.75)} + ${theme.spacing(4)})`};
` as typeof Box
