import { Divider, css, styled } from '@mui/material'

export const MainContainer = styled('div')<{ sticked?: boolean }>`
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'sidePadding',
})<{ sidePadding: boolean }>`
  padding: ${({ theme, sidePadding }) => theme.spacing(0.5, sidePadding ? 2 : 0)};
`

export const HeaderContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isNavigationMenu',
})<{ isNavigationMenu?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isNavigationMenu }) => (isNavigationMenu ? `4fr 1fr` : `1fr 3fr 1fr`)};
  align-items: center;
  height: ${({ theme }) => theme.spacing(5)};
`

export const ContentContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'skipAdaptivePadding',
})<{ skipAdaptivePadding: boolean }>`
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(75)};
  margin: 0 auto;
  box-sizing: border-box;

  ${({ theme, skipAdaptivePadding }) =>
    skipAdaptivePadding
      ? css`
          padding: ${theme.spacing(0, 2)};
        `
      : css`
          ${theme.breakpoints.down('sm')} {
            padding: ${theme.spacing(0, 2)};
          }
        `};
`

export const StartContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isNavigationMenu',
})<{ isNavigationMenu?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  ${({ isNavigationMenu }) =>
    isNavigationMenu &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`

export const MiddleContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
`

export const EndContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`

export const DividerLine = styled(Divider)`
  height: ${({ theme }) => theme.spacing(0)};
`
