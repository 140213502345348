import React from 'react'
import { Switch as MuiSwitch } from '@mui/material'
import { Label, ControlLabel } from './styles'
import type { SwitchProps } from './types'

const Switch: React.FC<SwitchProps> = ({ value, label, labelPlacement, dataTestId, ...rest }) => (
  <ControlLabel
    value={value}
    control={<MuiSwitch {...rest} />}
    label={<Label variant="body1">{label}</Label>}
    labelPlacement={labelPlacement}
    data-testid={dataTestId}
  />
)

export default Switch
