import React from 'react'
import { AbsencePlannerStateProvider } from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { TaskBarStateProvider } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import PinLogin from 'pages/PinLogin'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import { PARENT_PAGES } from 'redesign/components/templates/SideMenu/constants'
import SideMenuProvider from 'redesign/services/SideMenu/SideMenuProvider'
import AbsencePlannerPage from './AbsencePlannerPage'
import useAbsencePlanPage from './useAbsencePlanPage'

const AbsencePlannerPageWrappers: React.FC = () => {
  const { isReady, persons } = useAbsencePlanPage()

  if (!isReady) {
    return (
      <div data-testid="loading-tutorials-absence-planner">
        <LoadingSpinner />
      </div>
    )
  }
  return (
    <PinLogin>
      <TaskBarStateProvider>
        <AbsencePlannerStateProvider>
          <SideMenuProvider parentPage={PARENT_PAGES.absencePlan} persons={persons}>
            <AbsencePlannerPage />
          </SideMenuProvider>
        </AbsencePlannerStateProvider>
      </TaskBarStateProvider>
    </PinLogin>
  )
}

export default AbsencePlannerPageWrappers
