import { useCallback, useEffect, useMemo, useState } from 'react'
import { absenceRequest, shiftTypes } from 'constants/shift'
import { addDays, compareAsc, eachDayOfInterval } from 'date-fns'
import { useJamesApolloLazyQuery } from 'hooks/useJamesApolloLazyQuery'
import useManagedTaskCounts from 'hooks/useManagedTaskCounts'
import useSessionContext from 'hooks/useSessionContext'
import { dateToISOStringWithoutTime, newDateWithoutTime } from 'utils/date'
import useCms from '../../../hooks/useCms'
import { absencePlannerQuery } from '../queries'
import {
  AbsencePlannerBadgeTypesT,
  AbsencePlannerGridBadgesT,
  AbsencePlannerGridDataT,
  AbsencePlannerQueryReturnT,
  DateRangeT,
  PersonIdKeyT,
} from '../types'
import { addNewDataToAbsencePlanner } from '../utils'

const VALID_QUERY_SHIFT_TYPES = [shiftTypes.absence as string, shiftTypes.plannedShift as string]

interface IUseAbsencePlannerDataProps {
  shownTypes: AbsencePlannerBadgeTypesT[]
  shownTeams: string[]
  selectedPersonId?: number
  onLoadData?: () => void
}

// Hook responsible for absence planner badges data and related actions
export const useAbsencePlannerData = ({
  shownTeams,
  shownTypes,
  selectedPersonId,
  onLoadData,
}: IUseAbsencePlannerDataProps) => {
  const { personIds } = useSessionContext()
  const [gridData, setGridData] = useState<AbsencePlannerGridDataT>(new Map<PersonIdKeyT, AbsencePlannerGridBadgesT>())
  const [userPerson, setUserPerson] = useState<AbsencePlannerGridBadgesT | null>(null)
  const [shiftsDateRange, setShiftsDateRange] = useState<DateRangeT>({
    startDate: null,
    endDate: null,
  })
  const [maxDateRange, setMaxDateRange] = useState<DateRangeT>({
    startDate: null,
    endDate: null,
  })
  const { cmsEventsAccess } = useCms()

  const updatePersonData = useCallback(
    (data: AbsencePlannerGridDataT) => {
      if (selectedPersonId) {
        const key = `${selectedPersonId}`
        const personData = data.has(key) ? (data.get(key) as AbsencePlannerGridBadgesT) : null
        setUserPerson(personData)
      } else {
        const personData = (personIds ?? []).reduce((acc, personId) => {
          const key = `${personId}`
          if (!data.has(key)) {
            return acc
          }
          const dayBadges = data.get(key) as AbsencePlannerGridBadgesT
          dayBadges.forEach((value, datekey) => acc.set(datekey, [...(acc.get(datekey) ?? []), ...value]))
          return acc
        }, new Map() as AbsencePlannerGridBadgesT)
        setUserPerson(personData.size > 0 ? personData : null)
      }
    },
    [personIds, selectedPersonId],
  )

  const commonAbsencePlannerQueryVars = useMemo(
    () => ({
      teamIds: shownTeams,
      shiftTypes: shownTypes.filter((type) => VALID_QUERY_SHIFT_TYPES.includes(type)),
      showAbsences: shownTypes.includes(absenceRequest),
      showShiftUpdates: true,
    }),
    [shownTeams, shownTypes],
  )

  const [fetch, { loading }] = useJamesApolloLazyQuery<AbsencePlannerQueryReturnT>(absencePlannerQuery, {
    fetchPolicy: 'no-cache',
    onCompleted(newData) {
      const newGridData = addNewDataToAbsencePlanner(gridData, newData)
      setGridData(newGridData)
      updatePersonData(newGridData)
      onLoadData && onLoadData()
    },
  })
  const { taskCounts, taskCountTotal, refetch: refetchTaskCounts } = useManagedTaskCounts(shownTeams)

  const fetchDataByDateRange = useCallback(
    (dateRange: DateRangeT) => {
      if (!dateRange.startDate || !dateRange.endDate || shownTeams.length < 1 || loading) return
      fetch({
        variables: {
          ...commonAbsencePlannerQueryVars,
          startDate: dateToISOStringWithoutTime(dateRange.startDate as Date),
          endDate: dateToISOStringWithoutTime(dateRange.endDate as Date),
          showCmsEvents: cmsEventsAccess,
        },
      })
    },
    [shownTeams.length, loading, fetch, commonAbsencePlannerQueryVars, cmsEventsAccess],
  )

  const updateDateRange = useCallback(
    (newDateRange: DateRangeT) => {
      setShiftsDateRange(newDateRange)
      if (!shiftsDateRange.startDate || !shiftsDateRange.endDate || !maxDateRange.endDate || !maxDateRange.startDate) {
        setMaxDateRange(newDateRange)
        fetchDataByDateRange(newDateRange)
      } else {
        if (loading) {
          return
        }

        if (compareAsc(newDateRange.startDate as Date, maxDateRange.startDate) < 0) {
          fetchDataByDateRange({
            startDate: newDateRange.startDate as Date,
            endDate: addDays(shiftsDateRange.startDate, -1),
          })
          setMaxDateRange((currentValue) => ({ ...currentValue, startDate: newDateRange.startDate }))
        }

        if (compareAsc(newDateRange.endDate as Date, maxDateRange.endDate) > 0) {
          fetchDataByDateRange({
            startDate: addDays(shiftsDateRange.endDate, 1),
            endDate: newDateRange.endDate as Date,
          })
          setMaxDateRange((currentValue) => ({ ...currentValue, endDate: newDateRange.endDate }))
        }
      }
    },
    [
      loading,
      maxDateRange.endDate,
      maxDateRange.startDate,
      fetchDataByDateRange,
      shiftsDateRange.endDate,
      shiftsDateRange.startDate,
    ],
  )

  // Function to delete stored badges for a certain time frame and certain person. It refetches all badges, for the specified time frame
  const renewPersonData = useCallback(
    (personId: string, dateFrom: string, dateTo: string) => {
      const personsMap = new Map(gridData)
      const startDate = newDateWithoutTime(dateFrom)
      const endDate = newDateWithoutTime(dateTo)
      const dates = eachDayOfInterval({ start: startDate, end: endDate })

      if (personsMap.has(personId)) {
        const person = personsMap.get(personId)

        const updatedPerson = dates.reduce((acc, d) => {
          const date = dateToISOStringWithoutTime(d)
          if (acc?.has(date)) {
            acc.delete(date)
          }
          return acc
        }, person ?? new Map())

        setGridData(personsMap.set(personId, updatedPerson))

        fetchDataByDateRange({ startDate, endDate })
        refetchTaskCounts()
      }
    },
    [fetchDataByDateRange, gridData, refetchTaskCounts],
  )

  // Refetches all data if data relevant filters change (shown teams, shown badge types)
  useEffect(() => {
    fetchDataByDateRange(maxDateRange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownTypes, shownTeams])

  // This effect ensures that if the selected date has been altered while a previous query was still loading refetch for the most up to date data
  useEffect(() => {
    if (
      !loading &&
      shiftsDateRange.startDate &&
      shiftsDateRange.endDate &&
      maxDateRange.endDate &&
      maxDateRange.startDate
    ) {
      if (compareAsc(shiftsDateRange.startDate, maxDateRange.startDate) < 0) {
        fetchDataByDateRange({ startDate: shiftsDateRange.startDate, endDate: addDays(maxDateRange.startDate, -1) })
        setMaxDateRange((currentValue) => ({ ...currentValue, startDate: shiftsDateRange.startDate }))
      }
      if (compareAsc(shiftsDateRange.endDate, maxDateRange.endDate) > 0) {
        fetchDataByDateRange({ startDate: addDays(maxDateRange.endDate, 1), endDate: shiftsDateRange.endDate })
        setMaxDateRange((currentValue) => ({ ...currentValue, endDate: shiftsDateRange.endDate }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return {
    personData: gridData,
    userPerson,
    loading: loading,
    shiftsDateRange,
    taskCounts: taskCounts,
    taskCountTotal,
    maxDateRange,
    updateDateRange,
    reloadAbsenceRequest: () => {
      fetchDataByDateRange(maxDateRange)
      refetchTaskCounts()
    },
    renewPersonData,
  }
}
