import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import LoadMoreButton from 'components/LoadMoreButton'
import { SimpleLoading } from 'components/LoadingIcon'
import { useAnnouncementsContext } from 'services/AnnouncementsService/context'
import { UserAnnouncement } from 'shared/graphql/graphql'
import { useAnnouncements } from '../../services/AnnouncementsService/useAnnouncements'
import AnnouncementCard from './AnnouncementCard'
import { classes } from './styles'

const AnnouncementList: React.FC = () => {
  const { loading, list, loadMore, hasMoreElements, markAnnouncementSeen } = useAnnouncements()
  const { openAnnouncement } = useAnnouncementsContext()

  const handleClick = useCallback(
    (announcement: UserAnnouncement) => {
      openAnnouncement(announcement)
      if (!announcement.seen) {
        markAnnouncementSeen(announcement)
      }
    },
    [markAnnouncementSeen, openAnnouncement],
  )

  return (
    <>
      <Box sx={classes.cardList}>
        {list?.map((announcement) => (
          <AnnouncementCard
            key={`announcement_${announcement.id}`}
            announcement={announcement}
            onClick={() => handleClick(announcement)}
          />
        ))}
      </Box>
      {loading && <SimpleLoading />}
      {!loading && hasMoreElements && <LoadMoreButton onClick={loadMore} />}
    </>
  )
}

export default AnnouncementList
