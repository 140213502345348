import { css, styled, Typography } from '@mui/material'
import { CELL_WIDTH, HEADER_CELL_HEIGHT } from 'pages/AbsencePlanner/components/Grid/constants'

export const DayContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isMonday' &&
    prop !== 'isToday' &&
    prop !== 'isDayAfterToday' &&
    prop !== 'isFirstDayOfMonth' &&
    prop !== 'isDaySelected',
})<{
  isMonday: boolean
  isToday: boolean
  isDayAfterToday: boolean
  isFirstDayOfMonth: boolean
  isDaySelected: boolean
}>`
  display: flex;
  width: ${CELL_WIDTH}px;
  height: ${HEADER_CELL_HEIGHT}px;
  box-sizing: border-box;
  padding: 3px 0;
  gap: 4px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border-left: 1px solid;
  border-color: ${({ isMonday, theme }) => (isMonday ? theme.palette.text.secondary : theme.palette.common.white)};

  ${({ isToday, theme }) =>
    isToday &&
    css`
      color: ${theme.palette.primary.main};
      border-color: ${theme.palette.common.cardBorder};
      background-color: ${theme.palette.common.absencePlannerDay};
    `}

  ${({ isDayAfterToday, theme }) =>
    isDayAfterToday &&
    css`
      border-color: ${theme.palette.common.cardBorder};
    `}

    ${({ isFirstDayOfMonth, theme }) =>
    isFirstDayOfMonth &&
    css`
      background-color: ${theme.palette.common.absencePlannerNewMonth};
    `}

    ${({ isDaySelected, theme }) =>
    isDaySelected &&
    css`
      background-color: ${theme.palette.common.absencePlannerSelectedDay};
      border-top: 2px solid ${theme.palette.primary.main};
      padding: 1px 0 3px 0;
    `}
`

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`
export const MonthText = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.text.primary};
  border-radius: 2px;
  height: 18px;
  width: 35px;
`
