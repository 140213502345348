import React from 'react'
import { ClickAwayListener, Popover as MuiPopover } from '@mui/material'
import { ChildContainer, ContentContainer } from './styles'
import type { PopoverProps } from './types'

const Popover: React.FC<PopoverProps> = ({ open, title, dialogChildNode, onClose, popoverPosition, children }) => (
  <MuiPopover
    open={open}
    anchorEl={dialogChildNode}
    onClose={onClose}
    transformOrigin={popoverPosition.transformOrigin}
    anchorOrigin={popoverPosition.anchorOrigin}
    hideBackdrop
  >
    <ClickAwayListener onClickAway={onClose}>
      <ContentContainer>
        {title}
        <ChildContainer>{children}</ChildContainer>
      </ContentContainer>
    </ClickAwayListener>
  </MuiPopover>
)

export default Popover
