import React from 'react'
import { IconButton, Typography } from '@mui/material'
import { MainContainer } from './styles'
import type { DayCellProps } from './types'

const DayCell: React.FC<DayCellProps> = ({
  isButton = false,
  isSelected,
  isToday,
  isSmall = false,
  disabled,
  text,
  onClick,
  testId,
}) => (
  <MainContainer
    isToday={isToday}
    isSelected={isSelected}
    isSmall={isSmall}
    onClick={onClick}
    data-testid={testId}
    as={isButton ? IconButton : undefined}
    {...(isButton && disabled && { disabled })}
  >
    <Typography variant="body2" color="inherit">
      {text}
    </Typography>
  </MainContainer>
)

export default DayCell
