import { css, styled } from '@mui/material'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSideMenuOpen' && prop !== 'hasNavbar' && prop !== 'scrollOverflow',
})<{
  isSideMenuOpen: boolean
  hasNavbar?: boolean
  scrollOverflow?: boolean
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ scrollOverflow }) =>
    scrollOverflow &&
    css`
      overflow: scroll;
    `}
  background-color: ${({ isSideMenuOpen, theme }) =>
    isSideMenuOpen ? theme.palette.common.backgroundSideMenu : theme.palette.background.paper};
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  ${({ hasNavbar, theme }) =>
    !hasNavbar &&
    css`
      padding-bottom: calc(${theme.spacing(0.5)} + env(safe-area-inset-bottom));
    `}
`
