import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import FullPageDrawer from 'redesign/components/atoms/FullPageDrawer'
import DesktopSideMenu from 'redesign/components/organisms/DesktopSideMenu'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'
import SideMenuContent from '../../organisms/SideMenuContent/SideMenuContent'
import { SIDE_MENU_ROOT_ID } from './constants'

const SideMenu: React.FC = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { open, setOpen, setPage } = useSideMenuContext()

  return isSmallScreen ? (
    <FullPageDrawer
      open={open}
      onClose={() => {
        setOpen(false)
        setPage(undefined)
      }}
      rootId={SIDE_MENU_ROOT_ID}
    >
      <SideMenuContent />
    </FullPageDrawer>
  ) : (
    <DesktopSideMenu />
  )
}

export default SideMenu
