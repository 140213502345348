import { notificationTypes } from 'constants/notifications'
import { TaskTypeT } from 'shared/constants'
import type { RoleViewT } from 'types'
import type { TabT, TaskStateT } from './types'

export const taskStates: Record<string, TaskStateT> = {
  todo: 'todo',
  pending: 'pending',
  closed: 'closed',
}

export const taskTypeByWorkflow: Record<string, TaskTypeT> = {
  workflowAbsences: 'absence_request',
  workflowMonthlySheets: 'monthly_sheet',
  workflowShareUserData: 'share_user_data',
  workflowShiftUpdate: 'shift_update',
  workflowSecureConnection: 'secure_connection',
}

export const tabs: TabT[] = [
  { id: taskStates.todo, label: 'tasks.tab.todo' },
  { id: taskStates.pending, label: 'tasks.tab.pending' },
  { id: taskStates.closed, label: 'tasks.tab.closed' },
]

export const notificationTypesToDismiss: Record<RoleViewT, Record<TaskStateT, string>> = {
  office: {
    todo: `"${notificationTypes.officeAbsenceRequest}"`,
    pending: '',
    closed: '',
  },
  frontliner: {
    todo: '',
    pending: '',
    closed: `"${notificationTypes.resolvedAbsenceRequest}"`,
  },
}

// Send manual reminder endpoint error codes
const recentNotificationCode = 'tasks.new.sendReminder.error.recentNotification'
const recentNotificationManagerCode = 'tasks.new.sendReminder.manager.error.recentNotification'
const noUserCode = 'tasks.new.sendReminder.error.noUser'
const personWithoutRequiredModules = 'tasks.new.sendReminder.error.personWithoutRequiredModules'
export const errorCodes = [
  recentNotificationCode,
  recentNotificationManagerCode,
  noUserCode,
  personWithoutRequiredModules,
]
