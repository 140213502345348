import React from 'react'
import { TaskList } from 'redesign/components/templates/ContentLists'
import { FilterStateListener } from 'redesign/components/templates/Filters/FilterStateListener'
import { useTaskList } from 'redesign/pages/TaskList/useTaskList'
import { ListWrapper } from './styles'
import type { SideMenuTaskListContentProps } from './types'

const SideMenuTaskListContent: React.FC<SideMenuTaskListContentProps> = ({
  onTaskClick,
  initialFilters,
  isOffice = false,
}) => {
  const { list: tasks, loading, loadMore, showLoadMoreButton, handleFilterChange } = useTaskList({
    initialFilters,
    isOffice,
  })

  return (
    <FilterStateListener onFilterChange={handleFilterChange} defaultFilters={initialFilters}>
      <ListWrapper>
        <TaskList
          isLoading={loading}
          isOffice={isOffice}
          tasks={tasks}
          onTaskClick={onTaskClick}
          showLoadMoreButton={showLoadMoreButton}
          loadMore={loadMore}
        />
      </ListWrapper>
    </FilterStateListener>
  )
}
export default SideMenuTaskListContent
