import React from 'react'
import Announcement from 'components/Announcements'
import { CmsContentBlocksT } from 'types'
import AnnouncementsContext from './context'
import { useAnnouncementsService } from './useAnnouncementsService'

interface IAnnouncementsServiceProps {
  children?: React.ReactNode
}

const AnnouncementsService: React.FC<IAnnouncementsServiceProps> = ({ children }) => {
  const {
    routeAnnouncement,
    showAnnouncement,
    setShowAnnouncement,
    openAnnouncement,
    refetch,
  } = useAnnouncementsService()

  return (
    <AnnouncementsContext.Provider value={{ openAnnouncement }}>
      <Announcement
        blocks={(routeAnnouncement?.announcement?.blocks as CmsContentBlocksT[]) ?? []}
        open={showAnnouncement}
        onClose={() => {
          setShowAnnouncement(false)
          refetch()
        }}
      />
      {children}
    </AnnouncementsContext.Provider>
  )
}

export default AnnouncementsService
