import React from 'react'
import { FilterProvider } from 'components/Filter/context'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import { DrawerHeaderWrapper } from 'redesign/components/molecules/DrawerHeaderWrapper'
import SideMenuHeader from 'redesign/components/organisms/Headers/SideMenuHeader'
import { SideMenuTaskListContent } from 'redesign/components/organisms/SideMenuTaskListContent'
import Filters from '../../Filters'
import { SIDE_MENU_ROOT_ID } from '../../SideMenu/constants'
import type { MenuPageProps } from '../../SideMenu/types'
import { useAbsences } from '../PersonalAbsences/useAbsences'
import TaskDetail from '../TaskDetail'

const AdminAbsences: React.FC<MenuPageProps> = ({ onClose, setPage, isDrawer, t, ...props }) => {
  const {
    configuration,
    configLoading,
    headerBackgroundColor,
    initialFilters,
    onTaskClick,
    selectedTaskUuid,
    setSelectedTaskUuid,
  } = useAbsences({ isOffice: true })

  if (configLoading) return <LoadingSpinner />

  if (selectedTaskUuid) {
    return (
      <TaskDetail
        {...props}
        onGoBack={() => setSelectedTaskUuid(null)}
        onClose={() => {
          setSelectedTaskUuid(null)
          onClose()
        }}
        t={t}
      />
    )
  }

  return (
    <>
      <FilterProvider configuration={configuration} name="admin_absence_list">
        <DrawerHeaderWrapper
          header={
            <SideMenuHeader
              titleKey="absencePlanner.taskbar.frontlinerTasks.title"
              onClose={onClose}
              isDrawer={Boolean(isDrawer)}
              setPage={setPage}
            />
          }
          backgroundColor={headerBackgroundColor}
        >
          <SideMenuTaskListContent initialFilters={initialFilters} onTaskClick={onTaskClick} isOffice={true} />
        </DrawerHeaderWrapper>
        <Filters rootId={SIDE_MENU_ROOT_ID} />
      </FilterProvider>
    </>
  )
}

export default AdminAbsences
