import { useMemo, useState } from 'react'
import { CheckListOptionT, FilterType, OptionT } from 'components/Filter/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { tabs } from 'pages/Tasks/constants'
import { allManagedPersonsQuery } from 'pages/Tasks/queries'
import { personsOnlyTenant } from 'shared/queries'
import type { PersonT } from 'types'
import { getISOLastDayOfMonth, newDateWithoutTime } from 'utils/date'
import { sortArrayByKey } from 'utils/sort'
import type { UseFilterConfigurationProps } from '../PersonalAbsences/types'

const useFilterConfiguration = ({ isOffice }: UseFilterConfigurationProps) => {
  const { t } = useFavurTranslation()
  const [tenantOptionsList, setTenantOptionsList] = useState<CheckListOptionT[]>([])
  const [tenantsLoading, setTenantsLoading] = useState(true)
  const [personsList, setPersonsList] = useState<PersonT[]>([])
  const [personsLoading, setPersonsLoading] = useState(true)

  useJamesApolloQuery<{ findAllPersons: PersonT[] }>(personsOnlyTenant, {
    onCompleted(personsWithTenants) {
      const filteredTenantOptions = Array.from(
        personsWithTenants?.findAllPersons
          .reduce(
            (map, person) =>
              map.has(person.tenant?.id)
                ? map
                : map.set(person.tenant?.id, { name: person.tenant?.id, label: person.tenant?.name }),
            new Map(),
          ) // de-duplication of tenants
          .values(),
      )

      const sortedTenants = sortArrayByKey(filteredTenantOptions, 'label')
      setTenantOptionsList(sortedTenants)
      setTenantsLoading(false)
    },
  })

  useJamesApolloQuery<{ findAllManagedPersons: PersonT[] }>(allManagedPersonsQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted(managedPersons) {
      setPersonsList(managedPersons.findAllManagedPersons)
      setPersonsLoading(false)
    },
  })

  const configTimeFrame = useMemo(
    () => [
      {
        name: 'time_frame',
        type: FilterType.DATE_RANGE,
        label: t('filter.task_time_frame.label'),
        maxDate: getISOLastDayOfMonth(newDateWithoutTime()),
        minDate: '2015-01-01',
        fromDate: {
          name: 'start_date',
          label: t('filter.from_date.label'),
        },
        toDate: {
          name: 'end_date',
          label: t('filter.to_date.label'),
        },
      } as OptionT,
    ],
    [t],
  )

  const configTaskStatuses = useMemo(
    () => [
      {
        name: 'task_statuses',
        type: FilterType.MULTIPLE_CHOICE,
        label: t('filter.task_status.label'),
        options: tabs.map((tab) => ({ name: tab.id, label: t(tab.label) })),
      } as OptionT,
    ],
    [t],
  )

  const configTenants = useMemo(
    () =>
      !isOffice && tenantOptionsList
        ? [
            {
              name: 'tenant_ids',
              type: FilterType.MULTIPLE_CHOICE,
              label: t('filter.tenants.label'),
              options: tenantOptionsList,
            } as OptionT,
          ]
        : [],
    [isOffice, t, tenantOptionsList],
  )

  const configPersons = useMemo(
    () =>
      isOffice && personsList && personsList.length > 1
        ? [
            {
              name: 'person_ids',
              type: FilterType.PERSONS,
              label: t('filter.persons.label'),
              options: personsList,
            } as OptionT,
          ]
        : [],
    [isOffice, personsList, t],
  )

  const configuration: OptionT[] = useMemo(() => {
    const baseConfig = [...configTimeFrame, ...configTaskStatuses, ...configTenants]

    return isOffice ? [...baseConfig, ...configPersons] : baseConfig
  }, [configPersons, configTaskStatuses, configTenants, configTimeFrame, isOffice])

  return {
    configuration,
    loading: tenantsLoading || personsLoading,
  }
}

export default useFilterConfiguration
