import { createContext, Context, useContext } from 'react'
import { UserAnnouncement } from 'shared/graphql/graphql'
import { IAnnouncementsContext } from './types'

const initialState: IAnnouncementsContext = {
  openAnnouncement: (_announcementRoute: UserAnnouncement) => {},
}

const AnnouncementsContext: Context<IAnnouncementsContext> = createContext(initialState)

export const useAnnouncementsContext = () => {
  return useContext(AnnouncementsContext)
}

export default AnnouncementsContext
