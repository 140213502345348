import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { RequestFullS } from 'icons'
import useTaskBarStateContext from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'
import { shiftTimesOfDay } from 'types'
import { newDateWithoutTime } from 'utils/date'
import palette from 'utils/theme/palette'
import { taskBarStates } from '../../TaskBar/types'
import { badgeClasses } from '../styles'
import { IBadgeProps } from '../types'
import { getBadgeKey, isBadgeVisible } from '../utils'

const RequestBadge: React.FC<IBadgeProps> = ({
  badge,
  isAlone = true,
  userBadge,
  person,
  setTaskBarState,
  isTaskSelected,
  isPreview,
  dataTestId,
}) => {
  const { timeOfDay, taskUuid, daysRequested } = badge
  const { selectTaskFromGrid } = useSelectedStatesActionsContext()
  const isHalfWith = isAlone && timeOfDay && timeOfDay !== shiftTimesOfDay.whole
  const isAfternoon = isHalfWith && badge.timeOfDay === shiftTimesOfDay.pm
  const { taskBarState } = useTaskBarStateContext()
  const iconColor = isTaskSelected ? palette.primary[500] : palette.primary[300]
  const redesignFF = Boolean(useFeatureFlag(FF_FAVUR_REDESIGN))
  // Needed for redesign pls don't remove
  const { setOpen, setPage } = useSideMenuContext()

  const openRedesignSideMenu = useCallback(() => {
    if (!redesignFF) return

    setOpen(true)
    setPage(userBadge ? SIDE_MENU_OPTIONS.personalAbsences : SIDE_MENU_OPTIONS.adminAbsences)
  }, [redesignFF, setOpen, setPage, userBadge])

  const closeRedesignSideMenu = useCallback(() => {
    if (!redesignFF) return

    setOpen(false)
    setPage(undefined)
  }, [redesignFF, setOpen, setPage])

  const onClick = useCallback(() => {
    if (!taskUuid || !person || !daysRequested || isPreview) return
    const daysRequestedDates = daysRequested.map((date) => newDateWithoutTime(date as string))
    selectTaskFromGrid(taskUuid, person, daysRequestedDates)

    if (isTaskSelected) {
      setTaskBarState(null)
      closeRedesignSideMenu()
    } else {
      setTaskBarState(userBadge ? taskBarStates.userTasks : taskBarStates.managerTasks)
      openRedesignSideMenu()
      if (!taskBarState && !isBadgeVisible(getBadgeKey(badge))) {
        document.getElementById(getBadgeKey(badge))?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        })
      }
    }
  }, [
    taskUuid,
    person,
    daysRequested,
    isPreview,
    selectTaskFromGrid,
    isTaskSelected,
    setTaskBarState,
    closeRedesignSideMenu,
    userBadge,
    openRedesignSideMenu,
    taskBarState,
    badge,
  ])

  return (
    <Box
      sx={[
        badgeClasses.requestBadge(isTaskSelected),
        ...(isHalfWith ? [badgeClasses.badgeHalfWidth] : [badgeClasses.badgeFullWidth]),
        ...(isAfternoon ? [badgeClasses.afternoonBadge] : []),
      ]}
      onClick={onClick}
      id={getBadgeKey(badge)}
      data-testid={dataTestId}
    >
      <RequestFullS fill={iconColor} id={`${getBadgeKey(badge)}_icon`} />
    </Box>
  )
}

export default RequestBadge
