import { styled } from '@mui/material'
import { Z_INDEX_PULL_TO_REFRESH_INDICATOR } from 'redesign/shared/zIndex/constants'
import { IonRefresher, IonRefresherContent } from '@ionic/react'

export const PullIndicator = styled(IonRefresherContent)`
  z-index: ${Z_INDEX_PULL_TO_REFRESH_INDICATOR};
  height: 0 !important;
`

export const Refresher = styled(IonRefresher)`
  display: contents !important;
  height: 100%;
`
