import { useCallback } from 'react'
import { ModuleT } from '../types'
import useSessionContext from './useSessionContext'

const useModule = () => {
  const { persons } = useSessionContext()

  const getPersonIdsWithAccessToModule = useCallback(
    (module: ModuleT) => {
      if (persons === undefined) return []

      return persons.filter((person) => person.modules?.includes(module)).map((person) => person.id)
    },
    [persons],
  )

  const hasAccessToModule = useCallback(
    (module: ModuleT) => {
      if (persons === undefined) return false

      return persons.some((person) => person.modules?.includes(module))
    },
    [persons],
  )

  const hasPersonAccessToModule = useCallback(
    (personId: number, module: ModuleT): boolean => {
      if (persons === undefined) return false

      const person = persons.find((p) => p.id === personId)
      return person?.modules ? person.modules?.includes(module) : false
    },
    [persons],
  )

  const hasTenantAccessToModule = useCallback((tenantModules: string[] | undefined, module: ModuleT): boolean => {
    if (tenantModules === undefined) return false

    return tenantModules.includes(module)
  }, [])

  return {
    getPersonIdsWithAccessToModule,
    hasAccessToModule,
    hasPersonAccessToModule,
    hasTenantAccessToModule,
  }
}

export default useModule
