import { useCallback, useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { PaginatedUserAnnouncements, UserAnnouncement, UserAnnouncementStatus } from 'shared/graphql/graphql'
import { announcementsQuery, markAnncouncementSeen } from 'shared/queries'
import { MutationResult } from 'types'
import { newDateWithoutTime } from 'utils/date'
import { useMutation } from '@apollo/client'

const DEFAULT_PAGE_SIZE = 10

const appendAnnouncement = (newEntries: UserAnnouncement[], currentEntries: UserAnnouncement[]) => {
  return newEntries.reduce((acc, newContent) => {
    const existingContent = acc.find((content) => content.id === newContent.id)
    if (!existingContent) return [...acc, newContent]
    return acc
  }, currentEntries)
}

const updateAnnouncements = (
  currentEntries: UserAnnouncement[],
  newEntries: UserAnnouncement[] | undefined,
  paginate: boolean,
) => {
  if (paginate && newEntries) {
    return appendAnnouncement(newEntries, currentEntries)
  }
  return newEntries ?? []
}

const markAnnouncementAsSeen = (announcements: UserAnnouncement[], announcementId: string): UserAnnouncement[] => {
  const seenAt = newDateWithoutTime()

  return announcements.map((announcement) => {
    if (announcement.id !== announcementId) return announcement
    return {
      ...announcement,
      seen: true,
      seenAt,
    }
  }) as UserAnnouncement[]
}

export const useAnnouncements = ({
  pageSize = DEFAULT_PAGE_SIZE,
  statuses = undefined,
  skip = false,
}: {
  pageSize?: number
  skip?: boolean
  statuses?: UserAnnouncementStatus[]
} = {}) => {
  const [announcements, setAnnouncements] = useState<UserAnnouncement[]>([])
  const [page, setPage] = useState(1)
  const [paginate, setPaginate] = useState(false)
  const { data: announcementsData, loading, error, refetch } = useJamesApolloQuery<{
    announcements: PaginatedUserAnnouncements
  }>(announcementsQuery, {
    fetchPolicy: 'cache-and-network',
    skip: skip,
    variables: {
      statuses: statuses,
      page: page,
      pageSize: pageSize,
    },
    onCompleted(data) {
      const newEntries = data.announcements.entries
      const updatedAnnouncements = updateAnnouncements(announcements, newEntries as UserAnnouncement[], paginate)
      setAnnouncements(updatedAnnouncements)
      setPaginate(false)
    },
  })

  const loadMore = useCallback(() => {
    setPaginate(true)
    setPage(page + 1)
  }, [page])

  const [markAnnouncementSeenMutation] = useMutation<MutationResult>(markAnncouncementSeen)

  const totalCount = announcementsData?.announcements.totalEntries || 0

  const hasMoreElements = Boolean(totalCount && announcements && totalCount > announcements.length)

  const markAnnouncementSeen = useCallback(
    (userAnnouncement: UserAnnouncement, { updateLocal = true }: { updateLocal?: boolean } = {}) => {
      markAnnouncementSeenMutation({ variables: { userAnnouncementId: userAnnouncement.id } }).then((_result) => {
        if (!updateLocal) return
        const updatedAnnouncements = markAnnouncementAsSeen(announcements, userAnnouncement.id as string)
        setAnnouncements(updatedAnnouncements)
      })
    },
    [announcements, markAnnouncementSeenMutation],
  )

  return {
    error,
    hasMoreElements,
    list: announcements,
    loading,
    loadMore,
    markAnnouncementSeen,
    refetch,
    totalEntries: totalCount,
  }
}
