import { Box, styled } from '@mui/material'
import { CELL_WIDTH } from 'pages/AbsencePlanner/components/Grid/constants'

export const CellBox = styled(Box)`
  height: 100%;
  min-width: ${CELL_WIDTH}px;
  width: ${CELL_WIDTH}px;
  box-sizing: border-box;
  border-left: 1px solid;
  border-color: ${({ theme }) => theme.palette.common.greyLight};
`
