import { ShiftUpdateStatus } from 'components/ShiftsList/types'
import { groupBy } from 'lodash/fp'
import type { TaskT, ShiftUpdateTaskT } from 'pages/Tasks/types'
import type { PlannedShiftT, ShiftsByTenantCCT } from 'types'
import { groupShiftsByShortDescription } from 'utils/shifts'

export const getGroupedShifts = (shiftData: ShiftsByTenantCCT[]) =>
  shiftData.map(({ costCenterName, costCenterUuid, shifts }) => ({
    costCenterName,
    costCenterUuid,
    shifts: groupShiftsByShortDescription(shifts as PlannedShiftT[]),
  }))

export const getUpdateTask = (shiftGroup: PlannedShiftT[], shiftUpdates: TaskT<ShiftUpdateTaskT>[]) => {
  const taskIds = shiftGroup.map((shift) => `${shift.updateTaskId}`)

  return shiftUpdates.find((task) => task.id && taskIds.includes(`${task.id}`))
}

export const getUpdateTaskFromShifts = (shiftData: ShiftsByTenantCCT[], shiftUpdates: TaskT<ShiftUpdateTaskT>[]) => {
  const allShifts = shiftData.flatMap((costcenter) => costcenter.shifts as PlannedShiftT[])

  return getUpdateTask(allShifts, shiftUpdates)
}

export const shouldShowShiftUpdateAlert = (updateTask: TaskT<ShiftUpdateTaskT> | undefined, isPast: boolean) =>
  Boolean(!isPast && updateTask && !updateTask.taskData.acknowledgedAt)

export const getShiftUpdateDaysCount = (updateTask?: TaskT<ShiftUpdateTaskT>) =>
  updateTask ? Object.keys(groupBy('date', updateTask.shiftsUpdatedHistory ?? [])).length : 0

export const hasAcknowledgedShiftUpdate = (updateTask?: TaskT<ShiftUpdateTaskT>) =>
  !updateTask || updateTask.status === ShiftUpdateStatus.reviewFrontlineSeen
