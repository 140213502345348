import { styled } from '@mui/material'

export const TitleContainer = styled('div')`
  margin-left: 16px;
  padding-left: 0;
  width: 100%;
`

export const TeamTitlePlaceholder = styled('div')`
  width: 144px;
  height: 22px;
  background: #e6e6e6;
  border-radius: 20px;
`

export const TeamNameText = styled('span')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`
