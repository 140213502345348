import { css, styled } from '@mui/material'
import { VirtualizeSwipeableViews } from 'redesign/services/SwipeableView/VirtualizeSwipeableViews'

export const MonthContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`

export const WeekContainer = styled('div')`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0, 2)};

  &:not(:first-of-type) {
    border-top: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
  }
`

export const ModalContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSmallScreen',
})<{
  isSmallScreen: boolean
}>`
  width: 100%;
  height: 100%;
  ${({ isSmallScreen, theme }) =>
    !isSmallScreen &&
    css`
      width:${theme.spacing(75)}
      max-width: ${theme.spacing(75)};
      height: 75vh;
      max-height: ${theme.spacing(100)};
    `}
`

export const DaysContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const SwipeContainer = styled(VirtualizeSwipeableViews)`
  height: 100%;
  width: 100%;
  > .react-swipeable-view-container {
    width: inherit;
    height: inherit;
  }
`
