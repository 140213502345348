import { useMemo } from 'react'
import { FF_CMS_MODULES } from '../constants/featureFlags'
import { modules } from '../shared/constants'
import useFeatureFlag from './useFeatureFlag'
import useModule from './useModule'

const useCms = () => {
  const { hasAccessToModule } = useModule()
  const cmsModulesFF = useFeatureFlag(FF_CMS_MODULES) as boolean

  const cmsAccess = useMemo(() => hasAccessToModule(modules.cms), [hasAccessToModule])
  const cmsEventsAccess = useMemo(() => !cmsModulesFF || hasAccessToModule(modules.cmsEvents), [
    cmsModulesFF,
    hasAccessToModule,
  ])
  const cmsNewslettersAccess = useMemo(() => !cmsModulesFF || hasAccessToModule(modules.cmsNewsLetters), [
    cmsModulesFF,
    hasAccessToModule,
  ])
  const cmsPagesAccess = useMemo(() => !cmsModulesFF || hasAccessToModule(modules.cmsPages), [
    cmsModulesFF,
    hasAccessToModule,
  ])

  return {
    cmsAccess,
    cmsEventsAccess,
    cmsNewslettersAccess,
    cmsPagesAccess,
  }
}

export default useCms
