import React from 'react'
import { Typography } from '@mui/material'
import { getTeamNameByLanguage } from 'types/utils'
import Divider from '../Divider'
import { DIVIDER_VARIANT } from '../Divider/constants'
import { TeamNameText, TeamTitlePlaceholder, TitleContainer } from './styles'
import type { TeamRowTitleProps } from './types'
import { getTenantLabel } from './utils'

const TeamRowTitle: React.FC<TeamRowTitleProps> = ({ teamsLoading, team, isMultiTenant, language }) => (
  <TitleContainer>
    {teamsLoading || !team ? (
      <TeamTitlePlaceholder />
    ) : (
      <TeamNameText>
        <Typography variant="subtitle1" data-testid={`title-${team.id}`} noWrap>
          {getTeamNameByLanguage(team, language)}
        </Typography>
        {isMultiTenant && (
          <>
            <Divider variant={DIVIDER_VARIANT.dot} />
            <Typography variant="body1" data-testid={`title-${team.id}-tenant-label`} noWrap>
              {getTenantLabel(team)}
            </Typography>
          </>
        )}
      </TeamNameText>
    )}
  </TitleContainer>
)

export default TeamRowTitle
