import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_XXL } from './constants'
import { IconPropsT } from './types'

const Announcement: React.FC<IconPropsT & { strokeWidth?: string }> = ({
  stroke,
  size = ICON_SIZE_XXL,
  width,
  height,
  strokeWidth = '2.667',
}) => {
  return (
    <Svg size={size} viewBox="0 0 64 64" fill="none" stroke={stroke} width={width} height={height}>
      <g clipPath="url(#a)" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round">
        <path d="M8.352 34.134 9.547 31l-2.79-7.317a2.477 2.477 0 0 1 .96-2.952l6.556-4.28 2.045-7.557A2.475 2.475 0 0 1 18.83 7.07l7.819.397 6.096-4.92a2.475 2.475 0 0 1 3.106 0l6.096 4.92 7.819-.39a2.475 2.475 0 0 1 2.512 1.825l2.045 7.557 6.555 4.28a2.474 2.474 0 0 1 .96 2.952l-2.787 7.317 2.787 7.315a2.477 2.477 0 0 1-.96 2.955l-6.555 4.266-2.056 7.56a2.472 2.472 0 0 1-2.512 1.827l-7.819-.39-6.096 4.91M2 52.718l4.416 6.957M26.42 21.725l16.785 26.443" />
        <path d="m42.323 46.776-36.79 11.51-2.65-4.177 24.421-30.992 15.019 23.659Z" />
        <path d="m13.482 55.797 2.336 3.59a5.445 5.445 0 0 0 7.75 1.637 6.016 6.016 0 0 0 1.565-8.107l-.435-.63" />
      </g>
    </Svg>
  )
}

export default Announcement
