import React from 'react'
import { Button } from '@mui/material'
import { FooterContainer } from './styles'
import type { NewAbsenceButtonProps } from './types'

const NewAbsenceButton: React.FC<NewAbsenceButtonProps> = ({ onClick, t }) => (
  <FooterContainer>
    <Button variant="contained" fullWidth onClick={onClick}>
      {t('absencePlanner.taskbar.newAbsenceRequest.header')}
    </Button>
  </FooterContainer>
)

export default NewAbsenceButton
