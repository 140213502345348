import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CalendarNoDataM } from 'icons'
import NextDayEvents from '../NextDayEvents'
import { classes } from '../styles'
import { INextShiftProps } from '../types'

const NextShiftNoShifts: React.FC<INextShiftProps> = ({ events }) => {
  const { t } = useFavurTranslation()

  return (
    <Box sx={classes.noData}>
      <Box sx={classes.noDataTextContainer}>
        <CalendarNoDataM />
        <Typography variant="body2" sx={classes.noDataText}>
          {t('page.dashboard.content.noUseShift')}
        </Typography>
      </Box>
      <NextDayEvents events={events} />
    </Box>
  )
}

export default NextShiftNoShifts
