import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { DASHBOARD_NEXT_SHIFT_DATE } from 'constants/shift'
import { isToday } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory } from 'react-router-dom'
import { useAppStatesContext } from 'services/AppStates'
import routes from 'services/RoutesProvider/routes'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { capitalize } from 'utils/string'
import NextShiftMultiTenant from './NextShiftMultiTenant'
import NextShiftNoShifts from './NextShiftNoShifts'
import NextShiftSingleTenant from './NextShiftSingleTenant'
import { classes } from './styles'
import { INextShiftProps } from './types'

const NextShift: React.FC<INextShiftProps> = (props) => {
  const { date, shiftsByTenants, isMultitenant } = props
  const { locale } = useFavurTranslation()
  const history = useHistory()
  const { set } = useAppStatesContext()

  const dateIsToday = useMemo(() => isToday(newDateWithoutTime(date)), [date])

  const { dayOfWeek, dayOfMonth } = useMemo(() => {
    const dateObject = newDateWithoutTime(date)
    const weekDayName = capitalize(getFormattedFromDate(dateObject, 'EEEE', locale))
    return { dayOfWeek: weekDayName, dayOfMonth: dateObject.getDate() }
  }, [date, locale])

  const shiftCards = useMemo(() => {
    if (!shiftsByTenants?.length) return <NextShiftNoShifts {...props} />
    if (isMultitenant) return <NextShiftMultiTenant {...props} />
    return <NextShiftSingleTenant {...props} />
  }, [shiftsByTenants, isMultitenant, props])

  return (
    <Box sx={classes.card}>
      <Card
        data-testid={`dashboard-shifts__card--${dayOfMonth}`}
        onClick={() => {
          set(DASHBOARD_NEXT_SHIFT_DATE, date)
          history.push(routes.shifts)
        }}
      >
        <Typography variant="overline" sx={classes.dayOfWeek}>
          {dayOfWeek}
        </Typography>
        <Typography variant="h3" color={dateIsToday ? 'secondary' : 'primary'} sx={classes.dayOfMonth}>
          {dayOfMonth}
        </Typography>
        <Box>{shiftCards}</Box>
      </Card>
    </Box>
  )
}

export default NextShift
