import React from 'react'
import { Box } from '@mui/material'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useAvatarSidebarStateContext, {
  useAvatarSidebarStateDispatchContext,
} from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import RedesignThemeWrapper from 'redesign/components/atoms/RedesignThemeWrapper'
import ArrowIcon from './ArrowIcon'
import { avatarSideBarClasses } from './styles'

const TopBox: React.FC<{ isScrolled: boolean }> = ({ isScrolled }) => {
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { avatarBarExpanded: open } = useAvatarSidebarStateContext()
  const setAvatarBarExpanded = useAvatarSidebarStateDispatchContext()
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN)

  const Icon = redesignFF ? (
    <RedesignThemeWrapper>
      <ArrowIcon open={open} isRedesign={Boolean(redesignFF)} onClick={() => setAvatarBarExpanded(!open)} />
    </RedesignThemeWrapper>
  ) : (
    <ArrowIcon open={open} isRedesign={Boolean(redesignFF)} onClick={() => setAvatarBarExpanded(!open)} />
  )

  return (
    <Box sx={avatarSideBarClasses.topBox(isScrolled)}>
      <Box sx={avatarSideBarClasses.coverBox(open, Boolean(redesignFF))}>
        <Box sx={avatarSideBarClasses.iconContainer(Boolean(redesignFF) && isSmallScreen)}>
          {isSmallScreen || Boolean(redesignFF) ? Icon : null}
        </Box>
      </Box>
    </Box>
  )
}

export default TopBox
