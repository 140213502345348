import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const MoreHorizontalIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
  fillOpacity,
}) => (
  <SvgWrapper aria-label="More Horizontal" viewBox={viewBox} size={size} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm-6 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm-6 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  </SvgWrapper>
)

export default MoreHorizontalIcon
