import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'

const getVariants: (theme: Theme) => ComponentsVariants['MuiSwitch'] = (theme) => [
  {
    props: { size: 'medium' },
    style: {
      width: theme.spacing(6),
      height: theme.spacing(3),
      '& .MuiSwitch-switchBase': {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        left: theme.spacing(0.25),
        '&.Mui-checked': {
          transform: `translateX(${theme.spacing(3)}) translateY(-50%)`,
        },
      },
      '& .MuiSwitch-thumb': {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
      },
    },
  },
  {
    props: { size: 'small' },
    style: {
      width: theme.spacing(5),
      height: theme.spacing(2.5),
      '& .MuiSwitch-switchBase': {
        width: theme.spacing(2),
        height: theme.spacing(2),
        left: theme.spacing(0.25),
        '&.Mui-checked': {
          transform: `translateX(${theme.spacing(2.5)}) translateY(-50%)`,
        },
      },
      '& .MuiSwitch-thumb': {
        width: theme.spacing(2),
        minWidth: theme.spacing(2),
        height: theme.spacing(2),
      },
      '& .MuiSwitch-track': {
        borderRadius: theme.spacing(1.25),
      },
    },
  },
]

const styleOverrides: ComponentsOverrides<Theme>['MuiSwitch'] = {
  root: ({ theme }) => ({
    variants: getVariants(theme),
    width: theme.spacing(6),
    height: theme.spacing(3),
    padding: theme.spacing(0),
    position: 'relative',
    '@media (hover: hover)': {
      '&:hover .MuiSwitch-track': {
        backgroundColor: theme.palette.text.primary,
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: theme.spacing(0.125),
      margin: theme.spacing(0),
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      transitionDuration: '300ms',
      position: 'absolute',
      top: '50%',
      left: theme.spacing(0.25),
      transform: 'translateY(-50%)',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.sharp,
      }),
      '&.Mui-checked': {
        transform: `translateX(${theme.spacing(3)}) translateY(-50%)`,
        color: theme.palette.common.white,
        '&.Mui-disabled': {
          '& + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 0,
        },
        '@media (hover: hover)': {
          '&:hover + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
    },
    '& .MuiTouchRipple-root': {
      height: '300%',
      width: '300%',
      top: '-100%',
      left: '-100%',
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      minWidth: theme.spacing(2.5),
      height: theme.spacing(2.5),
      color: theme.palette.common.white,
    },
    '& .MuiSwitch-input': {
      width: '350%',
    },
    '& .MuiSwitch-track': {
      borderRadius: theme.spacing(1.5),
      backgroundColor: theme.palette.text.secondary,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.standard,
      }),
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
  }),
}

const MuiSwitch = {
  styleOverrides,
}

export default MuiSwitch
