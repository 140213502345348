import { ComponentsOverrides, Theme } from '@mui/material'

const styleOverrides: ComponentsOverrides<Theme>['MuiRadio'] = {
  root: ({ theme }) => ({
    '&.MuiRadio-root': {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }),
}

const MuiRadio = {
  styleOverrides,
}

export default MuiRadio
