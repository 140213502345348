import { useMemo, useState } from 'react'
import { teamplayWithTeamsQuery } from 'pages/Shifts/TeamPlan/queries'
import { PersonTeamPermissionsQueryReturnT } from 'shared/queries'
import { QueryDates, TeamT } from 'types'
import {
  DayT,
  PersonsT,
  TeamplanDayT,
  TeamplanPersonsCostCenterT,
  TeamplanPersonsT,
  TeamplanPersonsTeamT,
  TeamplanTenantT,
  TenantT,
} from 'types/teamplan'
import { useJamesApolloQuery } from './useJamesApolloQuery'
import usePersonsWithTeamPermissions from './usePersonsWithTeamPermissions'

const anyTeams = (tenant: TenantT): boolean =>
  tenant.costCenters.some((costCenter) =>
    costCenter.persons.some((person) => person.personTeamPermissions && person.personTeamPermissions?.length > 0),
  )

const getPersonsFromTenant = (tenant: TenantT): PersonsT[] => {
  const persons = new Map<string, PersonsT>()
  tenant.costCenters.forEach((cc) => {
    cc.persons.forEach((person) => {
      const personId = person.id as string
      const currentPerson = persons.get(personId)
      if (!currentPerson) {
        persons.set(personId, person)
      } else {
        persons.set(personId, { ...currentPerson, shifts: [...currentPerson.shifts, ...person.shifts] })
      }
    })
  })

  return Array.from(persons.values())
}

const getTeamsFromPersons = (persons: PersonsT[]): TeamT[] => {
  const teams = new Map<string, TeamT>()

  persons.forEach((person) => {
    person.personTeamPermissions?.forEach((ptp) => {
      const teamId = ptp.team?.id as string
      if (!teams.has(teamId)) {
        teams.set(teamId, ptp.team as TeamT)
      }
    })
  })

  return Array.from(teams.values())
}

const getTeamplanCostCenters = (tenant: TenantT, excludePersonIds: string[]): TeamplanPersonsT[] => {
  return tenant.costCenters
    .map((costCenter) => {
      const personsInCostCenter = costCenter.persons.filter((person) => !excludePersonIds.includes(person.id as string))

      if (personsInCostCenter.length === 0) {
        return null
      }

      return {
        id: costCenter.id,
        name: costCenter.name,
        persons: personsInCostCenter,
        tenantName: tenant.name,
        type: 'cost_center',
        tenantId: tenant.id,
      } as TeamplanPersonsCostCenterT
    })
    .filter((tcc) => tcc !== null) as TeamplanPersonsCostCenterT[]
}

const getTeamplanTeams = (tenant: TenantT, excludePersonIds: string[]): TeamplanPersonsT[] => {
  const persons = getPersonsFromTenant(tenant)
  const teams = getTeamsFromPersons(persons)

  return teams
    .map((team) => {
      const personsInTeam = persons.filter((person) => {
        return person.personTeamPermissions?.some((ptp) => {
          const personId = person.id as string
          return ptp?.team?.id === team.id && !excludePersonIds.includes(personId) && !ptp?.supervisor
        })
      })

      if (personsInTeam.length === 0) {
        return null
      }

      return {
        id: team.id,
        team,
        persons: personsInTeam,
        type: 'team',
        tenantId: tenant.id,
      } as TeamplanPersonsTeamT
    })
    .filter((tpt) => tpt !== null) as TeamplanPersonsTeamT[]
}

const getTeamplanTenants = (day: DayT, excludePersonIds: string[]): TeamplanTenantT =>
  day.tenants.reduce((acc, tenant) => {
    return {
      [tenant.id]: anyTeams(tenant)
        ? getTeamplanTeams(tenant, excludePersonIds)
        : getTeamplanCostCenters(tenant, excludePersonIds),
      ...acc,
    }
  }, {} as TeamplanTenantT)

const getTenantsList = (teamplanWithTeams: DayT[]): TenantT[] => {
  return teamplanWithTeams.flatMap((day) => day.tenants)
}

const getExcludedPersonsIds = (personTeamPermissions: PersonTeamPermissionsQueryReturnT): string[] =>
  // a person is included in the teamplan if it has none or 1 teams
  personTeamPermissions.filter((person) => person.teamPermissions.length > 1).map((person) => `${person.id}`)

interface IUseTeamPlanProps {
  dates: QueryDates
  teamIds?: string[] | null
  teamsLoading: boolean
}

const useTeamPlan = ({ dates, teamIds, teamsLoading }: IUseTeamPlanProps) => {
  const [data, setData] = useState<TeamplanDayT>({})
  const [tenants, setTenants] = useState<TenantT[]>([])
  const { personTeamPermissions } = usePersonsWithTeamPermissions()

  const queryTeamIds = useMemo(() => {
    const userInAnyTeams =
      personTeamPermissions && personTeamPermissions.some((person) => person.teamPermissions.length > 0)

    if (!teamIds || (!userInAnyTeams && teamIds.length === 0)) return undefined
    return teamIds
  }, [teamIds, personTeamPermissions])

  const personIds = useMemo(() => {
    return getExcludedPersonsIds(personTeamPermissions ?? [])
  }, [personTeamPermissions])

  const { loading } = useJamesApolloQuery<{
    teamplanWithTeams: DayT[]
  }>(teamplayWithTeamsQuery, {
    skip: teamsLoading || !personIds,
    fetchPolicy: 'no-cache',
    variables: {
      startDate: dates.start,
      endDate: dates.end,
      teamIds: queryTeamIds,
    },
    onCompleted(res) {
      const { teamplanWithTeams } = res

      setData(
        teamplanWithTeams.reduce((acc, day) => {
          return { [day.date]: getTeamplanTenants(day, personIds), ...acc }
        }, {}) as TeamplanDayT,
      )
      setTenants(getTenantsList(teamplanWithTeams))
    },
  })

  return { loading, data, tenants }
}

export default useTeamPlan
