import React, { useMemo } from 'react'
import { useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { CloseIcon, MenuIcon } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import { FavurLogoWhite } from 'redesign/components/atoms/Illustrations'
import routes from 'services/RoutesProvider/routes'
import { FavurButton, HamburgerMenuButton, MainContainer, NavContainer } from './styles'
import type { HamburgerHeaderProps } from './types'

const HamburgerHeader: React.FC<HamburgerHeaderProps> = ({
  toggleMenu,
  isMenuOpen = false,
  showHamburger = true,
  showLogo = true,
  menuButtonTestId,
  hamburguerTestId,
  hideWebToolbar,
}) => {
  const theme = useTheme()
  const history = useHistory()

  const Icon = useMemo(() => (isMenuOpen ? CloseIcon : MenuIcon), [isMenuOpen])

  if (hideWebToolbar) return null

  return (
    <MainContainer data-testid={hamburguerTestId}>
      <NavContainer showLogo={showLogo}>
        {showLogo && (
          <FavurButton
            size="small"
            onClick={() => {
              history.push(routes.dashboard)
            }}
          >
            <FavurLogoWhite />
          </FavurButton>
        )}
        {showHamburger && (
          <HamburgerMenuButton onClick={toggleMenu} data-testid={menuButtonTestId} size="small">
            <Icon size={ICON_SIZE.large} color={theme.palette.common.white} />
          </HamburgerMenuButton>
        )}
      </NavContainer>
    </MainContainer>
  )
}

export default HamburgerHeader
