/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /**
   * The `Json` scalar type represents arbitrary json string data, represented as UTF-8
   * character sequences. The Json type is most often used to represent a free-form
   * human-readable json string.
   */
  Json: { input: any; output: any }
  /**
   * The [`utctimestamp`] scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC.
   */
  utctimestamp: { input: any; output: any }
}

export type AbsenceData = {
  __typename?: 'AbsenceData'
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  dates?: Maybe<Array<Maybe<AbsenceDateElement>>>
  reviewerComment?: Maybe<Scalars['String']['output']>
}

export type AbsenceDateElement = {
  __typename?: 'AbsenceDateElement'
  date?: Maybe<Scalars['String']['output']>
  period?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type AbsencePlannerPersonDays = {
  __typename?: 'AbsencePlannerPersonDays'
  dayBadges?: Maybe<Array<Maybe<DayBadges>>>
  personId?: Maybe<Scalars['ID']['output']>
}

export type AbsenceRequestCommentForFrontlinerNotification = {
  __typename?: 'AbsenceRequestCommentForFrontlinerNotification'
  badge?: Maybe<Scalars['String']['output']>
  commentShort?: Maybe<Scalars['String']['output']>
  managerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type AbsenceRequestCommentForManagerNotification = {
  __typename?: 'AbsenceRequestCommentForManagerNotification'
  badge?: Maybe<Scalars['String']['output']>
  commentShort?: Maybe<Scalars['String']['output']>
  frontlinerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type AbsenceRequestRequestDeletionNotification = {
  __typename?: 'AbsenceRequestRequestDeletionNotification'
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  frontlinerNameShort?: Maybe<Scalars['String']['output']>
  period?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type Address = {
  __typename?: 'Address'
  additionalAddress?: Maybe<Scalars['String']['output']>
  address?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type Announcement = {
  __typename?: 'Announcement'
  blocks?: Maybe<Array<Maybe<Block>>>
  id?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  route?: Maybe<Scalars['String']['output']>
}

export type Audience = {
  __typename?: 'Audience'
  audienceType?: Maybe<Scalars['String']['output']>
  nameDe?: Maybe<Scalars['String']['output']>
  nameEn?: Maybe<Scalars['String']['output']>
  nameFr?: Maybe<Scalars['String']['output']>
  nameIt?: Maybe<Scalars['String']['output']>
  ownerId?: Maybe<Scalars['ID']['output']>
  teamId?: Maybe<Scalars['ID']['output']>
  tenantId?: Maybe<Scalars['ID']['output']>
  uuid?: Maybe<Scalars['ID']['output']>
}

export type AuthStartResponse = {
  __typename?: 'AuthStartResponse'
  auth?: Maybe<Scalars['Int']['output']>
  /** @deprecated use cms module check instead */
  cmsActivated?: Maybe<Scalars['Boolean']['output']>
  invitationCode?: Maybe<Scalars['String']['output']>
  personIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  personModules?: Maybe<Scalars['Json']['output']>
  personPackages?: Maybe<Scalars['Json']['output']>
  personsAndPermissions?: Maybe<Array<Maybe<PersonIdWithPermissions>>>
  phone?: Maybe<Scalars['String']['output']>
  termsAndConditionsAcceptedAt?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
}

export type Badge = {
  __typename?: 'Badge'
  badgeId?: Maybe<Scalars['ID']['output']>
  date?: Maybe<Scalars['String']['output']>
  daysRequested?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  hexBackgroundColor?: Maybe<Scalars['String']['output']>
  hexTextColor?: Maybe<Scalars['String']['output']>
  isManual?: Maybe<Scalars['Boolean']['output']>
  numberOfEvents?: Maybe<Scalars['Int']['output']>
  percent?: Maybe<Scalars['Int']['output']>
  personId?: Maybe<Scalars['ID']['output']>
  shiftUpdateUuid?: Maybe<Scalars['String']['output']>
  shortDescription?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
  timeOfDay?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type Balance = {
  __typename?: 'Balance'
  bankHoliday?: Maybe<BalanceType>
  free?: Maybe<BalanceType>
  hours?: Maybe<BalanceType>
  nightHours?: Maybe<BalanceType>
  overtime?: Maybe<BalanceType>
  tenantName?: Maybe<Scalars['String']['output']>
  vacation?: Maybe<BalanceType>
  withErrors?: Maybe<Scalars['Boolean']['output']>
}

export type BalanceType = {
  __typename?: 'BalanceType'
  unit?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type BankInfo = {
  __typename?: 'BankInfo'
  bic?: Maybe<Scalars['String']['output']>
  iban?: Maybe<Scalars['String']['output']>
}

export type Block = {
  __typename?: 'Block'
  blockCss?: Maybe<Scalars['String']['output']>
  data?: Maybe<BlockData>
  language?: Maybe<Scalars['String']['output']>
  order?: Maybe<Scalars['Int']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type BlockData =
  | DocumentBlockData
  | IconBlockData
  | ImageBlockData
  | LinkBlockData
  | SubcontentBlockData
  | TextBlockData

export type ChangePhoneStep1Response = {
  __typename?: 'ChangePhoneStep1Response'
  connections?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type CmsContentNotification = {
  __typename?: 'CmsContentNotification'
  contentUuid?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['String']['output']>
  isWholeDay?: Maybe<Scalars['Boolean']['output']>
  startDate?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type Conflicts = {
  __typename?: 'Conflicts'
  otherLeavesCount?: Maybe<Scalars['Int']['output']>
  otherLeavesPersons?: Maybe<Array<Maybe<Person>>>
  pendingRequestsCount?: Maybe<Scalars['Int']['output']>
  pendingRequestsPersons?: Maybe<Array<Maybe<Person>>>
  vacationsCount?: Maybe<Scalars['Int']['output']>
  vacationsPersons?: Maybe<Array<Maybe<Person>>>
}

export type Connections = {
  __typename?: 'Connections'
  invitations?: Maybe<Array<Maybe<Invitation>>>
  persons?: Maybe<Array<Maybe<Person>>>
}

export type ContactData = {
  __typename?: 'ContactData'
  addresses?: Maybe<Array<Maybe<Address>>>
  email?: Maybe<Scalars['String']['output']>
}

export type Content = {
  __typename?: 'Content'
  audiences?: Maybe<Array<Maybe<Audience>>>
  blocks?: Maybe<Array<Maybe<Block>>>
  contentStatusType?: Maybe<Scalars['String']['output']>
  contentType?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['String']['output']>
  history?: Maybe<Array<Maybe<History>>>
  isAbsenceRestricted?: Maybe<Scalars['Boolean']['output']>
  isWholeDay?: Maybe<Scalars['Boolean']['output']>
  metadatas?: Maybe<Array<Maybe<Metadata>>>
  restrictAbsenceComment?: Maybe<Scalars['String']['output']>
  sentAt?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['String']['output']>
  uuid?: Maybe<Scalars['ID']['output']>
}

export type CreateShareUserDataResponse = {
  __typename?: 'CreateShareUserDataResponse'
  personsWithErrors?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type DayBadges = {
  __typename?: 'DayBadges'
  badges?: Maybe<Array<Maybe<Badge>>>
  date?: Maybe<Scalars['String']['output']>
  personId?: Maybe<Scalars['ID']['output']>
}

export type Document = {
  __typename?: 'Document'
  createdByMirus?: Maybe<Scalars['utctimestamp']['output']>
  data?: Maybe<Scalars['Json']['output']>
  documentType?: Maybe<Scalars['String']['output']>
  documentUuid?: Maybe<Scalars['String']['output']>
  favurUuid?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  lang?: Maybe<Scalars['String']['output']>
  person?: Maybe<Person>
  personId?: Maybe<Scalars['ID']['output']>
  status?: Maybe<Scalars['String']['output']>
  taskMonthlySheet?: Maybe<TaskMonthlySheet>
}

export type DocumentBlockData = {
  __typename?: 'DocumentBlockData'
  filename?: Maybe<Scalars['String']['output']>
  filesize?: Maybe<Scalars['String']['output']>
}

export type DocumentFilters = {
  documentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type DocumentNotification = {
  __typename?: 'DocumentNotification'
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  documentId?: Maybe<Scalars['Int']['output']>
  documentType?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type DocumentUploadLink = {
  __typename?: 'DocumentUploadLink'
  link?: Maybe<Scalars['String']['output']>
}

export type DocumentsObject = {
  __typename?: 'DocumentsObject'
  list?: Maybe<Array<Maybe<Document>>>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type EditTenant = {
  absencesBlockingPeriod?: InputMaybe<Scalars['Int']['input']>
  hideUnverifiedEffectiveTimes?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  reminders?: InputMaybe<Array<InputMaybe<TenantReminderInput>>>
}

export type Estimations = {
  __typename?: 'Estimations'
  bankHoliday?: Maybe<BalanceType>
  free?: Maybe<BalanceType>
  hours?: Maybe<BalanceType>
  nightHours?: Maybe<BalanceType>
  overtime?: Maybe<BalanceType>
  vacation?: Maybe<BalanceType>
}

export enum EventTimelineType {
  Past = 'PAST',
  Upcoming = 'UPCOMING',
}

export type FamilyData = {
  __typename?: 'FamilyData'
  children?: Maybe<Array<Maybe<FamilyMember>>>
  civilStatus?: Maybe<Scalars['String']['output']>
  civilStatusSince?: Maybe<Scalars['String']['output']>
  partner?: Maybe<FamilyMember>
}

export type FamilyMember = {
  __typename?: 'FamilyMember'
  birthDate?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  socialInsuranceNumber?: Maybe<Scalars['String']['output']>
}

export type File = {
  __typename?: 'File'
  createdAt?: Maybe<Scalars['String']['output']>
  filename?: Maybe<Scalars['String']['output']>
}

export type Folder = {
  __typename?: 'Folder'
  name?: Maybe<Scalars['String']['output']>
  uuid?: Maybe<Scalars['String']['output']>
}

export type FolderEntries = {
  __typename?: 'FolderEntries'
  entries?: Maybe<Array<Maybe<FolderEntry>>>
  pageNumber?: Maybe<Scalars['Int']['output']>
  pageSize?: Maybe<Scalars['Int']['output']>
  totalEntries?: Maybe<Scalars['Int']['output']>
  totalPages?: Maybe<Scalars['Int']['output']>
}

export type FolderEntry = {
  __typename?: 'FolderEntry'
  content?: Maybe<Content>
  folder?: Maybe<Folder>
  type?: Maybe<Scalars['String']['output']>
}

export type GetAvatarUrlsResponse = {
  __typename?: 'GetAvatarUrlsResponse'
  avatar32?: Maybe<Scalars['String']['output']>
  avatar48?: Maybe<Scalars['String']['output']>
  avatar64?: Maybe<Scalars['String']['output']>
  avatar96?: Maybe<Scalars['String']['output']>
  avatar128?: Maybe<Scalars['String']['output']>
  avatar252?: Maybe<Scalars['String']['output']>
  avatar504?: Maybe<Scalars['String']['output']>
  imageUuid?: Maybe<Scalars['String']['output']>
  profile?: Maybe<Scalars['String']['output']>
}

export type HighSecConnectionResponse = {
  __typename?: 'HighSecConnectionResponse'
  errorCode?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  success?: Maybe<Scalars['Boolean']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
  validUntil?: Maybe<Scalars['String']['output']>
}

export type History = {
  __typename?: 'History'
  avatarImageUuid?: Maybe<Scalars['String']['output']>
  insertedAt?: Maybe<Scalars['String']['output']>
  modificationType?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  userFirstName?: Maybe<Scalars['String']['output']>
  userLastName?: Maybe<Scalars['String']['output']>
}

export type IconBlockData = {
  __typename?: 'IconBlockData'
  variant?: Maybe<Scalars['String']['output']>
}

export type ImageBlockData = {
  __typename?: 'ImageBlockData'
  altText?: Maybe<Scalars['String']['output']>
  filename?: Maybe<Scalars['String']['output']>
}

export type ImprovedBalance = {
  __typename?: 'ImprovedBalance'
  bankHoliday?: Maybe<BalanceType>
  estimationDate?: Maybe<Scalars['String']['output']>
  estimations?: Maybe<Estimations>
  free?: Maybe<BalanceType>
  hours?: Maybe<BalanceType>
  nightHours?: Maybe<BalanceType>
  overtime?: Maybe<BalanceType>
  tenantName?: Maybe<Scalars['String']['output']>
  vacation?: Maybe<BalanceType>
  withErrors?: Maybe<Scalars['Boolean']['output']>
}

export type InAppNotifications = {
  __typename?: 'InAppNotifications'
  absenceRequest?: Maybe<Scalars['Boolean']['output']>
  officeMonthlySheetApproved?: Maybe<Scalars['Boolean']['output']>
  receivedUserData?: Maybe<Scalars['Boolean']['output']>
  secureConnectionManager?: Maybe<Scalars['Boolean']['output']>
  shiftUpdateSeen?: Maybe<Scalars['Boolean']['output']>
}

export type Info = {
  __typename?: 'Info'
  applicationEnv?: Maybe<Scalars['String']['output']>
  buildCommitSha?: Maybe<Scalars['String']['output']>
  /** @deprecated use build_commit_sha instead */
  commitSha?: Maybe<Scalars['String']['output']>
  endpoint?: Maybe<Scalars['String']['output']>
  k8sHostIp?: Maybe<Scalars['String']['output']>
  k8sNode?: Maybe<Scalars['String']['output']>
  k8sNs?: Maybe<Scalars['String']['output']>
  k8sPod?: Maybe<Scalars['String']['output']>
}

export type InputAbsenceDateElement = {
  date: Scalars['String']['input']
  period: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type InputAddress = {
  additionalAddress?: InputMaybe<Scalars['String']['input']>
  address?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  type: Scalars['String']['input']
  zipCode?: InputMaybe<Scalars['String']['input']>
}

export type InputFamilyData = {
  children?: InputMaybe<Array<InputMaybe<InputFamilyMember>>>
  civilStatus?: InputMaybe<Scalars['String']['input']>
  civilStatusSince?: InputMaybe<Scalars['String']['input']>
  partner?: InputMaybe<InputFamilyMember>
}

export type InputFamilyMember = {
  birthDate: Scalars['String']['input']
  firstName: Scalars['String']['input']
  gender: Scalars['String']['input']
  lastName: Scalars['String']['input']
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>
}

export type InputInAppNotifications = {
  absenceRequest: Scalars['Boolean']['input']
  officeMonthlySheetApproved: Scalars['Boolean']['input']
  receivedUserData: Scalars['Boolean']['input']
  secureConnectionManager?: InputMaybe<Scalars['Boolean']['input']>
  shiftUpdateSeen: Scalars['Boolean']['input']
}

export type InputPersonalData = {
  contactLanguage: Scalars['String']['input']
  firstName: Scalars['String']['input']
  gender: Scalars['String']['input']
  healthInsurance?: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  nationality: Scalars['String']['input']
  nickname?: InputMaybe<Scalars['String']['input']>
  placeOfOrigin: Scalars['String']['input']
  residenceCategoryType?: InputMaybe<Scalars['String']['input']>
  residenceCategoryValidUntil?: InputMaybe<Scalars['String']['input']>
  residenceCategoryZemisNumber?: InputMaybe<Scalars['String']['input']>
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>
}

export type InputPushNotifications = {
  absenceRequest: Scalars['Boolean']['input']
  cmsEvent?: InputMaybe<Scalars['Boolean']['input']>
  cmsNewsletter?: InputMaybe<Scalars['Boolean']['input']>
  cmsPage?: InputMaybe<Scalars['Boolean']['input']>
  documents: Scalars['Boolean']['input']
  frontlinerAbsenceRequest: Scalars['Boolean']['input']
  invitations: Scalars['Boolean']['input']
  monthlySheetReminder?: InputMaybe<Scalars['Boolean']['input']>
  newShifts: Scalars['Boolean']['input']
  officeMonthlySheetApproved: Scalars['Boolean']['input']
  receivedUserData: Scalars['Boolean']['input']
  secureConnectionFrontliner?: InputMaybe<Scalars['Boolean']['input']>
  secureConnectionManager?: InputMaybe<Scalars['Boolean']['input']>
  shareUserData: Scalars['Boolean']['input']
  shiftUpdateSeen?: InputMaybe<Scalars['Boolean']['input']>
  shiftsUpdate: Scalars['Boolean']['input']
}

export type Invitation = {
  __typename?: 'Invitation'
  code?: Maybe<Scalars['String']['output']>
  lang?: Maybe<Scalars['String']['output']>
  person?: Maybe<Person>
  phoneNumber?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['Int']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['Int']['output']>
}

export type InvitationNotification = {
  __typename?: 'InvitationNotification'
  code?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
}

export type IsMultiTenant = {
  __typename?: 'IsMultiTenant'
  isMultiTenant?: Maybe<Scalars['Boolean']['output']>
}

export type LastUpdatedDate = {
  __typename?: 'LastUpdatedDate'
  lastUpdatedDate?: Maybe<Scalars['String']['output']>
}

export type LinkBlockData = {
  __typename?: 'LinkBlockData'
  route?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
  variant?: Maybe<Scalars['String']['output']>
}

export type LinkMessageNotification = {
  __typename?: 'LinkMessageNotification'
  bodyKey?: Maybe<Scalars['String']['output']>
  linkButtonKey?: Maybe<Scalars['String']['output']>
  linkKey?: Maybe<Scalars['String']['output']>
  titleKey?: Maybe<Scalars['String']['output']>
}

export type LogoutInfo = {
  __typename?: 'LogoutInfo'
  auth?: Maybe<Scalars['Int']['output']>
}

export type Me = {
  __typename?: 'Me'
  auth?: Maybe<Scalars['Int']['output']>
  /** @deprecated use cms module check instead */
  cmsActivated?: Maybe<Scalars['Boolean']['output']>
  personIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  personModules?: Maybe<Scalars['Json']['output']>
  personPackages?: Maybe<Scalars['Json']['output']>
  personsAndPermissions?: Maybe<Array<Maybe<PersonIdWithPermissions>>>
  phone?: Maybe<Scalars['String']['output']>
  termsAndConditionsAcceptedAt?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
}

export type MemberChanges = {
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type Metadata = {
  __typename?: 'Metadata'
  language?: Maybe<Scalars['String']['output']>
  shortDescription?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type MonthlySheetCommentForFrontlinerNotification = {
  __typename?: 'MonthlySheetCommentForFrontlinerNotification'
  badge?: Maybe<Scalars['String']['output']>
  commentShort?: Maybe<Scalars['String']['output']>
  managerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type MonthlySheetCommentForManagerNotification = {
  __typename?: 'MonthlySheetCommentForManagerNotification'
  badge?: Maybe<Scalars['String']['output']>
  commentShort?: Maybe<Scalars['String']['output']>
  frontlinerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type MonthlySheetData = {
  __typename?: 'MonthlySheetData'
  documentAcceptedAt?: Maybe<Scalars['String']['output']>
  documentCreatedAt?: Maybe<Scalars['utctimestamp']['output']>
  documentId?: Maybe<Scalars['Int']['output']>
  documentUuid?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['String']['output']>
}

export type MutationOk = {
  __typename?: 'MutationOk'
  success?: Maybe<Scalars['Boolean']['output']>
}

export type MutationOkTask = {
  __typename?: 'MutationOkTask'
  success?: Maybe<Scalars['Boolean']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type MutationOkWithNotManaged = {
  __typename?: 'MutationOkWithNotManaged'
  notManaged?: Maybe<Scalars['Boolean']['output']>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type NativeVersionObject = {
  __typename?: 'NativeVersionObject'
  requiredVersion?: Maybe<Scalars['Int']['output']>
  requirementMet?: Maybe<Scalars['Boolean']['output']>
}

export type NewTaskWorkflowAbsences = {
  absencePeriod: Scalars['String']['input']
  dateFrom: Scalars['String']['input']
  dateTo: Scalars['String']['input']
}

export type Notification = {
  __typename?: 'Notification'
  data?: Maybe<NotificationData>
  dataType?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  invitationCode?: Maybe<Scalars['String']['output']>
  personId?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['Int']['output']>
}

export type NotificationCounts = {
  __typename?: 'NotificationCounts'
  manager?: Maybe<Scalars['Int']['output']>
  personal?: Maybe<Scalars['Int']['output']>
}

export type NotificationData =
  | AbsenceRequestCommentForFrontlinerNotification
  | AbsenceRequestCommentForManagerNotification
  | AbsenceRequestRequestDeletionNotification
  | CmsContentNotification
  | DocumentNotification
  | InvitationNotification
  | LinkMessageNotification
  | MonthlySheetCommentForFrontlinerNotification
  | MonthlySheetCommentForManagerNotification
  | OfficeAbsenceRequestNotification
  | OfficeMonthlySheetApprovedNotification
  | ReceivedUserDataNotification
  | ReminderAbsenceRequestManualForManagerNotification
  | ReminderMonthlySheetManualForManagerNotification
  | ReminderMonthlySheetManualNotification
  | ReminderNotification
  | ReminderSecureConnectionManualForFrontlinerNotification
  | ReminderSecureConnectionNotification
  | ReminderShareUserDataManualNotification
  | ReminderShareUserDataManualNotificationForManager
  | ReminderShareUserDataNotification
  | ReminderShiftUpdateManualNotification
  | ReminderShiftUpdateNotification
  | ResignationInfoNotification
  | ResolvedAbsenceRequestNotification
  | ResolvedAbsenceRequestRequestDeletionNotification
  | SecureConnectionCommentForFrontlinerNotification
  | SecureConnectionCommentForManagerNotification
  | SecureConnectionCompletedNotification
  | SecureConnectionNotification
  | ShareUserDataAcceptedNotification
  | ShareUserDataCommentForFrontlinerNotification
  | ShareUserDataCommentForManagerNotification
  | ShareUserDataNotification
  | ShiftNotification
  | ShiftUpdateSeenNotification

export type NotificationsPaginated = {
  __typename?: 'NotificationsPaginated'
  list?: Maybe<Array<Maybe<Notification>>>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type OfficeAbsenceRequestNotification = {
  __typename?: 'OfficeAbsenceRequestNotification'
  comment?: Maybe<Scalars['String']['output']>
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  period?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
  userName?: Maybe<Scalars['String']['output']>
  userNameShort?: Maybe<Scalars['String']['output']>
}

export type OfficeMonthlySheetApprovedNotification = {
  __typename?: 'OfficeMonthlySheetApprovedNotification'
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  documentId?: Maybe<Scalars['Int']['output']>
  personId?: Maybe<Scalars['Int']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
  userName?: Maybe<Scalars['String']['output']>
  userNameShort?: Maybe<Scalars['String']['output']>
}

export type Owner = {
  __typename?: 'Owner'
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
  persons?: Maybe<Array<Maybe<Person>>>
  tenants?: Maybe<Array<Maybe<Tenant>>>
}

export type OwnerPersonsArgs = {
  notStatusIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type OwnerTenantsArgs = {
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type PaginatedContents = {
  __typename?: 'PaginatedContents'
  entries?: Maybe<Array<Maybe<Content>>>
  pageNumber?: Maybe<Scalars['Int']['output']>
  pageSize?: Maybe<Scalars['Int']['output']>
  totalEntries?: Maybe<Scalars['Int']['output']>
  totalPages?: Maybe<Scalars['Int']['output']>
}

export type PaginatedFolders = {
  __typename?: 'PaginatedFolders'
  folderEntries?: Maybe<FolderEntries>
  insertedAt?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  path?: Maybe<Array<Maybe<PathItem>>>
  updatedAt?: Maybe<Scalars['String']['output']>
  uuid?: Maybe<Scalars['String']['output']>
}

export type PaginatedUserAnnouncements = {
  __typename?: 'PaginatedUserAnnouncements'
  entries?: Maybe<Array<Maybe<UserAnnouncement>>>
  pageNumber?: Maybe<Scalars['Int']['output']>
  pageSize?: Maybe<Scalars['Int']['output']>
  totalEntries?: Maybe<Scalars['Int']['output']>
  totalPages?: Maybe<Scalars['Int']['output']>
}

export type PathItem = {
  __typename?: 'PathItem'
  name?: Maybe<Scalars['String']['output']>
  uuid?: Maybe<Scalars['String']['output']>
}

export type Permissions = {
  __typename?: 'Permissions'
  officeView?: Maybe<Scalars['Boolean']['output']>
  permissionsChangedAt?: Maybe<Scalars['utctimestamp']['output']>
  workflowAbsences?: Maybe<Scalars['Boolean']['output']>
  workflowMonthlySheets?: Maybe<Scalars['Boolean']['output']>
  workflowSecureConnection?: Maybe<Scalars['Boolean']['output']>
  workflowShareUserData?: Maybe<Scalars['Boolean']['output']>
  workflowShareUserDataGlobal?: Maybe<Scalars['Boolean']['output']>
  workflowShiftUpdate?: Maybe<Scalars['Boolean']['output']>
}

export type Person = {
  __typename?: 'Person'
  costCenter?: Maybe<PersonCostCenter>
  costCenterId?: Maybe<Scalars['ID']['output']>
  deleteAfter?: Maybe<Scalars['String']['output']>
  employeeNumber?: Maybe<Scalars['String']['output']>
  favurUuid?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  invitation?: Maybe<Invitation>
  lastName?: Maybe<Scalars['String']['output']>
  lastSharedData?: Maybe<Scalars['String']['output']>
  numberOfDocuments?: Maybe<Scalars['Int']['output']>
  owner?: Maybe<Owner>
  ownerId?: Maybe<Scalars['ID']['output']>
  permissions?: Maybe<Permissions>
  roles?: Maybe<Array<Maybe<PersonRole>>>
  status?: Maybe<Scalars['String']['output']>
  teamPermissions?: Maybe<Array<Maybe<PersonTeamPermission>>>
  tenant?: Maybe<Tenant>
  tenantConnectionEstablished?: Maybe<Scalars['String']['output']>
  tenantConnectionStatus?: Maybe<Scalars['String']['output']>
  tenantId?: Maybe<Scalars['ID']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
  userId?: Maybe<Scalars['Int']['output']>
  workEntry?: Maybe<Scalars['String']['output']>
  workWithdrawal?: Maybe<Scalars['String']['output']>
}

export type PersonCostCenter = {
  __typename?: 'PersonCostCenter'
  name?: Maybe<Scalars['String']['output']>
  uuid?: Maybe<Scalars['String']['output']>
}

export type PersonIdWithPermissions = {
  __typename?: 'PersonIdWithPermissions'
  id?: Maybe<Scalars['Int']['output']>
  modules?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  ownerId?: Maybe<Scalars['Int']['output']>
  permissions?: Maybe<Permissions>
  roles?: Maybe<Array<Maybe<PersonRole>>>
  status?: Maybe<Scalars['String']['output']>
  tenantId?: Maybe<Scalars['Int']['output']>
  workWithdrawal?: Maybe<Scalars['String']['output']>
}

export type PersonRole = {
  __typename?: 'PersonRole'
  role?: Maybe<Scalars['String']['output']>
  tenant?: Maybe<Tenant>
  tenantId?: Maybe<Scalars['ID']['output']>
}

export type PersonTasksCounts = {
  __typename?: 'PersonTasksCounts'
  closed?: Maybe<Scalars['Int']['output']>
  pending?: Maybe<Scalars['Int']['output']>
  personId?: Maybe<Scalars['ID']['output']>
  todo?: Maybe<Scalars['Int']['output']>
}

export type PersonTeamPermission = {
  __typename?: 'PersonTeamPermission'
  absenceManager?: Maybe<Scalars['Boolean']['output']>
  monthlySheetManager?: Maybe<Scalars['Boolean']['output']>
  person?: Maybe<Person>
  personId?: Maybe<Scalars['ID']['output']>
  secureConnectionManager?: Maybe<Scalars['Boolean']['output']>
  shareUserDataManager?: Maybe<Scalars['Boolean']['output']>
  shiftUpdateManager?: Maybe<Scalars['Boolean']['output']>
  supervisor?: Maybe<Scalars['Boolean']['output']>
  team?: Maybe<Team>
  teamId?: Maybe<Scalars['String']['output']>
}

export type PersonalData = {
  __typename?: 'PersonalData'
  birthDate?: Maybe<Scalars['String']['output']>
  contactLanguage?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Scalars['String']['output']>
  healthInsurance?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  nationality?: Maybe<Scalars['String']['output']>
  nickname?: Maybe<Scalars['String']['output']>
  passportBack?: Maybe<File>
  passportFront?: Maybe<File>
  placeOfOrigin?: Maybe<Scalars['String']['output']>
  residenceCategoryType?: Maybe<Scalars['String']['output']>
  residenceCategoryValidUntil?: Maybe<Scalars['String']['output']>
  residenceCategoryZemisNumber?: Maybe<Scalars['String']['output']>
  residencePermitBack?: Maybe<File>
  residencePermitFront?: Maybe<File>
  socialInsuranceNumber?: Maybe<Scalars['String']['output']>
}

export type PinEntryRes = {
  __typename?: 'PinEntryRes'
  pinBlocked?: Maybe<Scalars['Boolean']['output']>
  remainRetries?: Maybe<Scalars['Int']['output']>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type PinStatus = {
  __typename?: 'PinStatus'
  pinLength?: Maybe<Scalars['Int']['output']>
  pinStatus?: Maybe<Scalars['String']['output']>
}

export type PushNotifications = {
  __typename?: 'PushNotifications'
  absenceRequest?: Maybe<Scalars['Boolean']['output']>
  cmsEvent?: Maybe<Scalars['Boolean']['output']>
  cmsNewsletter?: Maybe<Scalars['Boolean']['output']>
  cmsPage?: Maybe<Scalars['Boolean']['output']>
  documents: Scalars['Boolean']['output']
  frontlinerAbsenceRequest?: Maybe<Scalars['Boolean']['output']>
  invitations?: Maybe<Scalars['Boolean']['output']>
  monthlySheetReminder?: Maybe<Scalars['Boolean']['output']>
  newShifts?: Maybe<Scalars['Boolean']['output']>
  officeMonthlySheetApproved?: Maybe<Scalars['Boolean']['output']>
  receivedUserData?: Maybe<Scalars['Boolean']['output']>
  secureConnectionFrontliner?: Maybe<Scalars['Boolean']['output']>
  secureConnectionManager?: Maybe<Scalars['Boolean']['output']>
  shareUserData?: Maybe<Scalars['Boolean']['output']>
  shiftUpdateSeen?: Maybe<Scalars['Boolean']['output']>
  shiftsUpdate?: Maybe<Scalars['Boolean']['output']>
}

export type ReceivedUserDataNotification = {
  __typename?: 'ReceivedUserDataNotification'
  taskUuid?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
  userName?: Maybe<Scalars['String']['output']>
  userNameShort?: Maybe<Scalars['String']['output']>
}

export type RefreshHighSecuritySession = {
  __typename?: 'RefreshHighSecuritySession'
  highSecSessionValidUntil?: Maybe<Scalars['String']['output']>
}

export type ReminderAbsenceRequestManualForManagerNotification = {
  __typename?: 'ReminderAbsenceRequestManualForManagerNotification'
  frontlinerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderMonthlySheetManualForManagerNotification = {
  __typename?: 'ReminderMonthlySheetManualForManagerNotification'
  documentId?: Maybe<Scalars['Int']['output']>
  frontlinerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderMonthlySheetManualNotification = {
  __typename?: 'ReminderMonthlySheetManualNotification'
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  documentId?: Maybe<Scalars['Int']['output']>
  managerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderNotification = {
  __typename?: 'ReminderNotification'
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  documentId?: Maybe<Scalars['Int']['output']>
  reminderDeadline?: Maybe<Scalars['String']['output']>
  reminderType?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderSecureConnectionManualForFrontlinerNotification = {
  __typename?: 'ReminderSecureConnectionManualForFrontlinerNotification'
  managerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderSecureConnectionNotification = {
  __typename?: 'ReminderSecureConnectionNotification'
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderShareUserDataManualNotification = {
  __typename?: 'ReminderShareUserDataManualNotification'
  managerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderShareUserDataManualNotificationForManager = {
  __typename?: 'ReminderShareUserDataManualNotificationForManager'
  frontlinerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderShareUserDataNotification = {
  __typename?: 'ReminderShareUserDataNotification'
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderShiftUpdateManualNotification = {
  __typename?: 'ReminderShiftUpdateManualNotification'
  managerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ReminderShiftUpdateNotification = {
  __typename?: 'ReminderShiftUpdateNotification'
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ResignationInfoNotification = {
  __typename?: 'ResignationInfoNotification'
  resignationDate?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
}

export type ResolvedAbsenceRequestNotification = {
  __typename?: 'ResolvedAbsenceRequestNotification'
  comment?: Maybe<Scalars['String']['output']>
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  managerName?: Maybe<Scalars['String']['output']>
  managerNameShort?: Maybe<Scalars['String']['output']>
  period?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ResolvedAbsenceRequestRequestDeletionNotification = {
  __typename?: 'ResolvedAbsenceRequestRequestDeletionNotification'
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  managerNameShort?: Maybe<Scalars['String']['output']>
  period?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type RootMutationType = {
  __typename?: 'RootMutationType'
  /** Accept Invitation for existing user */
  acceptInvitation?: Maybe<MutationOk>
  /** Accept terms and conditions */
  acceptTermsConditions?: Maybe<MutationOk>
  /** Acknowledge a shift update (dismiss pink update symbol) */
  acknowledgeShiftUpdate?: Maybe<MutationOk>
  /** Add member to multiple teams */
  addToTeams?: Maybe<MutationOk>
  /** Authenticate with, and cycle refresh token */
  authRefreshToken?: Maybe<Me>
  /** Start the auth */
  authStart?: Maybe<AuthStartResponse>
  /** Validate code and authenticate */
  authValidate?: Maybe<Me>
  /** Update user bank information */
  bankInfo?: Maybe<MutationOk>
  /** Changes phone number for not logged users, step 1 of 4 */
  changePhoneStep1?: Maybe<ChangePhoneStep1Response>
  /** Changes phone number for not logged users, step 2 of 4 */
  changePhoneStep2?: Maybe<MutationOk>
  /** Changes phone number for not logged users, step 3 of 4 */
  changePhoneStep3?: Maybe<MutationOk>
  /** Changes phone number for not logged users, step 4 of 4 */
  changePhoneStep4?: Maybe<Me>
  /** change pin */
  changePin?: Maybe<MutationOk>
  /** change user language */
  changeUserLanguage?: Maybe<MutationOk>
  /** Update user addresses */
  contactData?: Maybe<MutationOk>
  /** Create an absence */
  createAbsence?: Maybe<MutationOkTask>
  /** Create a Share User Data request */
  createShareUserDataRequest?: Maybe<CreateShareUserDataResponse>
  /** Create a team */
  createTeam?: Maybe<Team>
  /** Delete an avatar from Google Storage */
  deleteAvatar?: Maybe<MutationOk>
  /** Delete a document from Google Storage */
  deleteFavurDocument?: Maybe<MutationOk>
  /** Delete task */
  deleteTask?: Maybe<MutationOk>
  /** Logically delete a team */
  deleteTeam?: Maybe<MutationOk>
  /** delete user */
  deleteUser?: Maybe<LogoutInfo>
  /** Dismisses all notifications by role */
  dismissAllNotifications?: Maybe<MutationOk>
  /** Dismiss monthly sheet document notifications */
  dismissBasicDocumentNotifications?: Maybe<MutationOk>
  /** Dismisses all notifications by related task */
  dismissByTask?: Maybe<MutationOk>
  /** Dismiss Notification */
  dismissNotification?: Maybe<MutationOk>
  /** Edits tenant */
  editTenant?: Maybe<MutationOk>
  /** High secure session pin entry */
  enterPin?: Maybe<PinEntryRes>
  /** High secure connection validation */
  establishSecureConnection?: Maybe<HighSecConnectionResponse>
  /** Update user family data */
  familyData?: Maybe<MutationOk>
  /** Init pin reset with token */
  initPinReset?: Maybe<MutationOk>
  /** Request sms code to init register process */
  initRegister?: Maybe<MutationOk>
  /** Init user phone number update */
  initUserPhoneNumberUpdate?: Maybe<MutationOk>
  /** Logout, delete session information */
  logout?: Maybe<LogoutInfo>
  /** Mark announcement as seen */
  markUserAnnouncementSeen?: Maybe<MutationOk>
  /** Perform an event to a task */
  performEventToTask?: Maybe<MutationOkWithNotManaged>
  /** Update personal information */
  personalData?: Maybe<MutationOk>
  /** Register Device */
  registerDevice?: Maybe<MutationOk>
  /** Register user with sms invitation process */
  registerUserValidated?: Maybe<MutationOk>
  /** Remove high security session validity */
  removeSecuritySession?: Maybe<MutationOk>
  /** Resend an sms in one of the sms processes */
  resendSms?: Maybe<MutationOk>
  /** revert delete person */
  revertDeletePerson?: Maybe<MutationOk>
  /** Set pin and set high secure connection */
  setPinAndEstablishSecureConnection?: Maybe<MutationOk>
  /** Share own user data with employers */
  shareOwnUserData?: Maybe<CreateShareUserDataResponse>
  /** start delete person */
  startDeletePerson?: Maybe<MutationOk>
  /** Update absence status */
  updateAbsenceStatus?: Maybe<MutationOk>
  /** Update Share User Data request */
  updateShareUserDataRequest?: Maybe<MutationOk>
  /** Update shift_update task status */
  updateShiftUpdateStatus?: Maybe<MutationOk>
  /** Update the members of a team */
  updateTeamMembers?: Maybe<MutationOk>
  /** Change the name of a team */
  updateTeamName?: Maybe<MutationOk>
  /** Update the team permissions */
  updateTeamPermissions?: Maybe<MutationOk>
  /** Update public status of a team */
  updateTeamPublicStatus?: Maybe<MutationOk>
  /** Update show team plan cost centers status of a team */
  updateTeamShowTeamplanCostCentersStatus?: Maybe<MutationOk>
  /** Update user avatar url */
  updateUserAvatarUrl?: Maybe<MutationOk>
  /** Update user data */
  updateUserData?: Maybe<MutationOk>
  /** Update User phone number */
  updateUserPhoneNumber?: Maybe<MutationOk>
  /** Update user settings */
  updateUserSettings?: Maybe<MutationOk>
  /** Validate sms code for init register process */
  validateRegister?: Maybe<MutationOk>
}

export type RootMutationTypeAcceptInvitationArgs = {
  invitationCode: Scalars['String']['input']
}

export type RootMutationTypeAcknowledgeShiftUpdateArgs = {
  dates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type RootMutationTypeAddToTeamsArgs = {
  personId: Scalars['ID']['input']
  teamIds: Array<InputMaybe<Scalars['String']['input']>>
}

export type RootMutationTypeAuthStartArgs = {
  appSignature?: InputMaybe<Scalars['String']['input']>
  phone: Scalars['String']['input']
}

export type RootMutationTypeAuthValidateArgs = {
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>
  validationCode: Scalars['String']['input']
}

export type RootMutationTypeBankInfoArgs = {
  bic?: InputMaybe<Scalars['String']['input']>
  iban: Scalars['String']['input']
}

export type RootMutationTypeChangePhoneStep1Args = {
  phone: Scalars['String']['input']
}

export type RootMutationTypeChangePhoneStep2Args = {
  personId?: InputMaybe<Scalars['Int']['input']>
  token: Scalars['String']['input']
}

export type RootMutationTypeChangePhoneStep3Args = {
  newPhone: Scalars['String']['input']
}

export type RootMutationTypeChangePhoneStep4Args = {
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>
  validationCode: Scalars['String']['input']
}

export type RootMutationTypeChangePinArgs = {
  newPinCode: Scalars['String']['input']
}

export type RootMutationTypeChangeUserLanguageArgs = {
  newLanguage: Scalars['String']['input']
}

export type RootMutationTypeContactDataArgs = {
  addresses?: InputMaybe<Array<InputMaybe<InputAddress>>>
  email: Scalars['String']['input']
}

export type RootMutationTypeCreateAbsenceArgs = {
  comment?: InputMaybe<Scalars['String']['input']>
  data: NewTaskWorkflowAbsences
  personUuid: Scalars['String']['input']
  taskType: Scalars['String']['input']
  triggeredByPersonUuid: Scalars['String']['input']
}

export type RootMutationTypeCreateShareUserDataRequestArgs = {
  comment?: InputMaybe<Scalars['String']['input']>
  personIds: Array<InputMaybe<Scalars['ID']['input']>>
  triggeredByPersonId: Scalars['ID']['input']
  userComment?: InputMaybe<Scalars['String']['input']>
}

export type RootMutationTypeCreateTeamArgs = {
  nameDe: Scalars['String']['input']
  nameEn?: InputMaybe<Scalars['String']['input']>
  nameFr?: InputMaybe<Scalars['String']['input']>
  nameIt?: InputMaybe<Scalars['String']['input']>
  personId?: InputMaybe<Scalars['Int']['input']>
  tenantId?: InputMaybe<Scalars['ID']['input']>
}

export type RootMutationTypeDeleteFavurDocumentArgs = {
  filename: Scalars['String']['input']
}

export type RootMutationTypeDeleteTaskArgs = {
  favurUuid: Scalars['String']['input']
  personId: Scalars['ID']['input']
}

export type RootMutationTypeDeleteTeamArgs = {
  id: Scalars['String']['input']
}

export type RootMutationTypeDismissAllNotificationsArgs = {
  isManager: Scalars['Boolean']['input']
}

export type RootMutationTypeDismissByTaskArgs = {
  isManager: Scalars['Boolean']['input']
  taskUuid: Scalars['String']['input']
}

export type RootMutationTypeDismissNotificationArgs = {
  notificationId: Scalars['ID']['input']
}

export type RootMutationTypeEditTenantArgs = {
  editTenant: EditTenant
  tenantId: Scalars['ID']['input']
}

export type RootMutationTypeEnterPinArgs = {
  pinCode: Scalars['String']['input']
}

export type RootMutationTypeEstablishSecureConnectionArgs = {
  personId: Scalars['Int']['input']
  token: Scalars['String']['input']
}

export type RootMutationTypeFamilyDataArgs = {
  familyData: InputFamilyData
}

export type RootMutationTypeInitPinResetArgs = {
  personId: Scalars['Int']['input']
  token: Scalars['String']['input']
}

export type RootMutationTypeInitRegisterArgs = {
  invitationCode: Scalars['String']['input']
}

export type RootMutationTypeInitUserPhoneNumberUpdateArgs = {
  phoneNumber: Scalars['String']['input']
}

export type RootMutationTypeLogoutArgs = {
  token?: InputMaybe<Scalars['String']['input']>
}

export type RootMutationTypeMarkUserAnnouncementSeenArgs = {
  userAnnouncementId: Scalars['ID']['input']
}

export type RootMutationTypePerformEventToTaskArgs = {
  comment?: InputMaybe<Scalars['String']['input']>
  event: Scalars['String']['input']
  favurUuid: Scalars['String']['input']
}

export type RootMutationTypePersonalDataArgs = {
  personalData: InputPersonalData
}

export type RootMutationTypeRegisterDeviceArgs = {
  language?: InputMaybe<Scalars['String']['input']>
  platform?: InputMaybe<Scalars['String']['input']>
  token: Scalars['String']['input']
}

export type RootMutationTypeRegisterUserValidatedArgs = {
  birthDate: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  termsAndConditions: Scalars['Boolean']['input']
}

export type RootMutationTypeResendSmsArgs = {
  appSignature?: InputMaybe<Scalars['String']['input']>
}

export type RootMutationTypeRevertDeletePersonArgs = {
  favurUuid: Scalars['String']['input']
}

export type RootMutationTypeSetPinAndEstablishSecureConnectionArgs = {
  pinCode: Scalars['String']['input']
}

export type RootMutationTypeShareOwnUserDataArgs = {
  personIds: Array<InputMaybe<Scalars['ID']['input']>>
  userComment?: InputMaybe<Scalars['String']['input']>
}

export type RootMutationTypeStartDeletePersonArgs = {
  favurUuid: Scalars['String']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}

export type RootMutationTypeUpdateAbsenceStatusArgs = {
  dates?: InputMaybe<Array<InputMaybe<InputAbsenceDateElement>>>
  internalReviewerComment?: InputMaybe<Scalars['String']['input']>
  reviewerComment?: InputMaybe<Scalars['String']['input']>
  status: Scalars['String']['input']
  taskUuid: Scalars['String']['input']
}

export type RootMutationTypeUpdateShareUserDataRequestArgs = {
  favurUuid: Scalars['String']['input']
  status: Scalars['String']['input']
  userComment?: InputMaybe<Scalars['String']['input']>
}

export type RootMutationTypeUpdateShiftUpdateStatusArgs = {
  favurUuid: Scalars['String']['input']
  status: Scalars['String']['input']
}

export type RootMutationTypeUpdateTeamMembersArgs = {
  changes: MemberChanges
  teamId: Scalars['String']['input']
}

export type RootMutationTypeUpdateTeamNameArgs = {
  id: Scalars['String']['input']
  nameDe: Scalars['String']['input']
  nameEn: Scalars['String']['input']
  nameFr: Scalars['String']['input']
  nameIt: Scalars['String']['input']
}

export type RootMutationTypeUpdateTeamPermissionsArgs = {
  changes: MemberChanges
  permission: Scalars['String']['input']
  teamId: Scalars['String']['input']
}

export type RootMutationTypeUpdateTeamPublicStatusArgs = {
  id: Scalars['String']['input']
  public: Scalars['Boolean']['input']
}

export type RootMutationTypeUpdateTeamShowTeamplanCostCentersStatusArgs = {
  id: Scalars['String']['input']
  showTeamplanCostCenters: Scalars['Boolean']['input']
}

export type RootMutationTypeUpdateUserAvatarUrlArgs = {
  imageUuid: Scalars['String']['input']
}

export type RootMutationTypeUpdateUserDataArgs = {
  birthDate: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
}

export type RootMutationTypeUpdateUserPhoneNumberArgs = {
  smsCode: Scalars['String']['input']
}

export type RootMutationTypeUpdateUserSettingsArgs = {
  settings: UserSettings
}

export type RootMutationTypeValidateRegisterArgs = {
  smsCode: Scalars['String']['input']
}

export type RootQueryType = {
  __typename?: 'RootQueryType'
  /** Get absence planner data for the logged user */
  absencePlanner?: Maybe<Array<Maybe<AbsencePlannerPersonDays>>>
  /** Get user announcements */
  announcements?: Maybe<PaginatedUserAnnouncements>
  /** Get a person's balance from office view */
  balanceOfficeOverview?: Maybe<Array<Maybe<ImprovedBalance>>>
  /** Get balances for user */
  balances?: Maybe<Array<Maybe<Balance>>>
  /** Get user bank information */
  bankInfo?: Maybe<BankInfo>
  /** Get content */
  cmsContentGet?: Maybe<Content>
  /** Get cms contents stats */
  cmsContentStats?: Maybe<Array<Maybe<Stats>>>
  /** List paginated contents */
  cmsPaginatedContentList?: Maybe<PaginatedContents>
  /** List paginated folders */
  cmsPaginatedFolderList?: Maybe<PaginatedFolders>
  /**
   * Get connections of a user
   * @deprecated should use find_all_persons instead
   */
  connections?: Maybe<Connections>
  /** Get contact data */
  contactData?: Maybe<ContactData>
  /** Get file upload link */
  documentUploadLink?: Maybe<DocumentUploadLink>
  /** All documents by filter and pagination */
  documentsPaginated?: Maybe<DocumentsObject>
  /** Get effective shifts for user from mirus */
  effectiveShiftsFavur?: Maybe<Array<Maybe<ShiftsByDate>>>
  /** Get family data */
  familyData?: Maybe<FamilyData>
  /**
   * Returns all manager persons from a user.
   * Can be filtered by workflow with the parameter 'team_permissions'.
   * Allowed values are: 'absence_manager', 'monthly_sheet_manager', 'shift_update_manager' and 'share_user_data_manager'
   */
  findAllManagedPersons?: Maybe<Array<Maybe<Person>>>
  /** Gets all persons of logged user */
  findAllPersons?: Maybe<Array<Maybe<Person>>>
  /** Gets teams of logged user */
  findAllTeams?: Maybe<Array<Maybe<Team>>>
  /** Return the absence request conflicts for a period of time */
  getAbsenceRequestConflicts?: Maybe<Conflicts>
  /** Get absences by filters */
  getAbsenceRequests?: Maybe<Array<Maybe<Task>>>
  /** Get Avatar upload Link */
  getAvatarUploadLinks?: Maybe<GetAvatarUrlsResponse>
  /** Get last updated date */
  getLastUpdatedDate?: Maybe<LastUpdatedDate>
  /** Get the managed tasks counts by persons */
  getManagedTaskCounts?: Maybe<Array<Maybe<PersonTasksCounts>>>
  /** Gets managed tenants */
  getManagedTenants?: Maybe<Array<Maybe<Tenant>>>
  getOwner?: Maybe<Owner>
  /** Get person by uuid, only admin can do this action */
  getPerson?: Maybe<Person>
  /** Get shifts for user from favur_db grouped by date */
  getPersonDayShifts?: Maybe<Array<Maybe<ShiftsByDate>>>
  getPersonValidationStatus?: Maybe<ValidationStatus>
  /** Gets public teams of logged user, filtering out empty teams */
  getPublicTeams?: Maybe<Array<Maybe<Team>>>
  /** Get one team by team_id */
  getTeam?: Maybe<Team>
  /** Get teams that user is member and public teams */
  getTeams?: Maybe<TeamsResult>
  getTenant?: Maybe<Tenant>
  /** Get improved balances for user */
  improvedBalances?: Maybe<Array<Maybe<ImprovedBalance>>>
  /** Get system information */
  info?: Maybe<Info>
  /** Get invitation of an invitation code */
  invitation?: Maybe<Invitation>
  /** Get is Multi Tenant */
  isMultiTenant?: Maybe<IsMultiTenant>
  /** Get tenant names of a user's insecure connections */
  lowSecurityTenantNames?: Maybe<Array<Maybe<TenantsNamesPersonsIds>>>
  /** Get session information */
  me?: Maybe<Me>
  /** Check the current native device version against the minimum version required */
  nativeVersionRequirement?: Maybe<NativeVersionObject>
  /** Get notification by id */
  notification?: Maybe<Notification>
  /** Get notification counts of a user */
  notificationCounts?: Maybe<NotificationCounts>
  /** Get notifications of a user paginated */
  notificationsPaginated?: Maybe<NotificationsPaginated>
  /** Get personal information */
  personalData?: Maybe<PersonalData>
  /** Get user's pin_status */
  pinStatus?: Maybe<PinStatus>
  /** Refresh high security session validity */
  refreshHighSecuritySession?: Maybe<RefreshHighSecuritySession>
  /** Session validity */
  sessionValidity?: Maybe<SessionValidity>
  /** Get shifts for user from favur_db grouped by date */
  shiftsFavurByDate?: Maybe<Array<Maybe<ShiftsByDate>>>
  /** Get history shifts for user */
  shiftsHistoryLatestUpdate?: Maybe<Array<Maybe<Shift>>>
  /** Get one task by unique uuid */
  task?: Maybe<Task>
  /** Get the tasks counts */
  taskCounts?: Maybe<TaskCounts>
  /** Get task list by filtered and paginated */
  tasksPaginated?: Maybe<TasksObject>
  /** Get teamplan for user with teams */
  teamplanWithTeams?: Maybe<Array<Maybe<TeamplanDate>>>
  /**
   * Get unseen user announcements
   * @deprecated Use announcements instead, with seen = false. Remove when 3.5.9 is required.
   */
  userAnnouncements?: Maybe<Array<Maybe<UserAnnouncement>>>
  /** Get all user persons and and permissions */
  userPersonsAndPermissions?: Maybe<Array<Maybe<PersonIdWithPermissions>>>
  /**
   * Send FE version to BE
   * @deprecated version_number will no longer be supported. Remove when 3.3.5 is required.
   */
  versionNumber?: Maybe<Scalars['String']['output']>
  /** Check the current fe version against the minimum version required by the be */
  versionRequirement?: Maybe<VersionObject>
}

export type RootQueryTypeAbsencePlannerArgs = {
  endDate: Scalars['String']['input']
  shiftTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  showAbsences?: InputMaybe<Scalars['Boolean']['input']>
  showCmsEvents?: InputMaybe<Scalars['Boolean']['input']>
  showShiftUpdates?: InputMaybe<Scalars['Boolean']['input']>
  startDate: Scalars['String']['input']
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type RootQueryTypeAnnouncementsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  statuses?: InputMaybe<Array<InputMaybe<UserAnnouncementStatus>>>
}

export type RootQueryTypeBalanceOfficeOverviewArgs = {
  estimateFromDay: Scalars['utctimestamp']['input']
  taskFavurUuid: Scalars['String']['input']
}

export type RootQueryTypeBalancesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryTypeCmsContentGetArgs = {
  uuid?: InputMaybe<Scalars['ID']['input']>
}

export type RootQueryTypeCmsPaginatedContentListArgs = {
  contentType?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  eventTimeline?: InputMaybe<EventTimelineType>
  isAbsenceRestricted?: InputMaybe<Scalars['Boolean']['input']>
  ownerId?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  showAdmin?: InputMaybe<Scalars['Boolean']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryTypeCmsPaginatedFolderListArgs = {
  contentType?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['String']['input']>
  ownerId?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  uuid?: InputMaybe<Scalars['ID']['input']>
}

export type RootQueryTypeDocumentUploadLinkArgs = {
  documentType: Scalars['String']['input']
  filename: Scalars['String']['input']
}

export type RootQueryTypeDocumentsPaginatedArgs = {
  filters?: InputMaybe<DocumentFilters>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryTypeEffectiveShiftsFavurArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryTypeFindAllManagedPersonsArgs = {
  teamPermissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type RootQueryTypeFindAllTeamsArgs = {
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type RootQueryTypeGetAbsenceRequestConflictsArgs = {
  endDate: Scalars['String']['input']
  isManager?: InputMaybe<Scalars['Boolean']['input']>
  startDate: Scalars['String']['input']
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type RootQueryTypeGetAbsenceRequestsArgs = {
  date?: InputMaybe<Scalars['String']['input']>
  dateFrom?: InputMaybe<Scalars['String']['input']>
  dateTo?: InputMaybe<Scalars['String']['input']>
  personIds: Array<InputMaybe<Scalars['ID']['input']>>
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type RootQueryTypeGetManagedTaskCountsArgs = {
  taskTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type RootQueryTypeGetOwnerArgs = {
  ownerId: Scalars['ID']['input']
}

export type RootQueryTypeGetPersonArgs = {
  favurUuid: Scalars['String']['input']
}

export type RootQueryTypeGetPersonDayShiftsArgs = {
  date: Scalars['String']['input']
  personId: Scalars['ID']['input']
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type RootQueryTypeGetPersonValidationStatusArgs = {
  favurUuid: Scalars['String']['input']
}

export type RootQueryTypeGetTeamArgs = {
  id: Scalars['String']['input']
}

export type RootQueryTypeGetTenantArgs = {
  tenantId: Scalars['ID']['input']
}

export type RootQueryTypeImprovedBalancesArgs = {
  estimateFromDay: Scalars['utctimestamp']['input']
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type RootQueryTypeInvitationArgs = {
  code: Scalars['String']['input']
}

export type RootQueryTypeNativeVersionRequirementArgs = {
  nativeVersionNumber: Scalars['Int']['input']
  platform: Scalars['String']['input']
}

export type RootQueryTypeNotificationArgs = {
  notificationId?: InputMaybe<Scalars['ID']['input']>
}

export type RootQueryTypeNotificationsPaginatedArgs = {
  isManager: Scalars['Boolean']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryTypePersonalDataArgs = {
  includeDocuments?: InputMaybe<Scalars['Boolean']['input']>
}

export type RootQueryTypeShiftsFavurByDateArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  multiDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  startDate?: InputMaybe<Scalars['String']['input']>
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type RootQueryTypeShiftsHistoryLatestUpdateArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  multiDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  startDate?: InputMaybe<Scalars['String']['input']>
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  updatedStatuses?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type RootQueryTypeTaskArgs = {
  favurUuid: Scalars['String']['input']
  office?: InputMaybe<Scalars['Boolean']['input']>
}

export type RootQueryTypeTaskCountsArgs = {
  office: Scalars['Boolean']['input']
  showSecondView?: InputMaybe<Scalars['Boolean']['input']>
}

export type RootQueryTypeTasksPaginatedArgs = {
  filters?: InputMaybe<TaskFilters>
  limit?: InputMaybe<Scalars['Int']['input']>
  office: Scalars['Boolean']['input']
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type RootQueryTypeTeamplanWithTeamsArgs = {
  endDate: Scalars['String']['input']
  startDate: Scalars['String']['input']
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type RootQueryTypeVersionNumberArgs = {
  buildVersionNumber?: InputMaybe<Scalars['String']['input']>
}

export type RootQueryTypeVersionRequirementArgs = {
  buildVersionNumber?: InputMaybe<Scalars['String']['input']>
}

export type SecureConnectionCommentForFrontlinerNotification = {
  __typename?: 'SecureConnectionCommentForFrontlinerNotification'
  badge?: Maybe<Scalars['String']['output']>
  commentShort?: Maybe<Scalars['String']['output']>
  managerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type SecureConnectionCommentForManagerNotification = {
  __typename?: 'SecureConnectionCommentForManagerNotification'
  badge?: Maybe<Scalars['String']['output']>
  commentShort?: Maybe<Scalars['String']['output']>
  frontlinerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type SecureConnectionCompletedNotification = {
  __typename?: 'SecureConnectionCompletedNotification'
  frontlinerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
}

export type SecureConnectionNotification = {
  __typename?: 'SecureConnectionNotification'
  taskUuid?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
}

export type SessionValidity = {
  __typename?: 'SessionValidity'
  highSecSessionValidNow?: Maybe<Scalars['Boolean']['output']>
  highSecSessionValidUntil?: Maybe<Scalars['String']['output']>
  pinResetValidNow?: Maybe<Scalars['Boolean']['output']>
  pinResetValidUntil?: Maybe<Scalars['String']['output']>
  securitySetupValidNow?: Maybe<Scalars['Boolean']['output']>
  securitySetupValidUntil?: Maybe<Scalars['String']['output']>
  timestamp?: Maybe<Scalars['String']['output']>
}

export type Settings = {
  __typename?: 'Settings'
  dashboardEmployeeCards?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  inAppNotifications?: Maybe<InAppNotifications>
  pushNotifications?: Maybe<PushNotifications>
  seenTutorials?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type ShareUserData = {
  __typename?: 'ShareUserData'
  reviewerComment?: Maybe<Scalars['String']['output']>
  sentDataOn?: Maybe<Scalars['String']['output']>
  userComment?: Maybe<Scalars['String']['output']>
}

export type ShareUserDataAcceptedNotification = {
  __typename?: 'ShareUserDataAcceptedNotification'
  taskUuid?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
}

export type ShareUserDataCommentForFrontlinerNotification = {
  __typename?: 'ShareUserDataCommentForFrontlinerNotification'
  badge?: Maybe<Scalars['String']['output']>
  commentShort?: Maybe<Scalars['String']['output']>
  managerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ShareUserDataCommentForManagerNotification = {
  __typename?: 'ShareUserDataCommentForManagerNotification'
  badge?: Maybe<Scalars['String']['output']>
  commentShort?: Maybe<Scalars['String']['output']>
  frontlinerNameShort?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
}

export type ShareUserDataNotification = {
  __typename?: 'ShareUserDataNotification'
  taskUuid?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
}

export type Shift = {
  __typename?: 'Shift'
  costCenterName?: Maybe<Scalars['String']['output']>
  costCenterUuid?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  from?: Maybe<Scalars['String']['output']>
  hexBackgroundColor?: Maybe<Scalars['String']['output']>
  hexTextColor?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  insertedAt?: Maybe<Scalars['String']['output']>
  isManual?: Maybe<Scalars['Boolean']['output']>
  note?: Maybe<Scalars['String']['output']>
  percent?: Maybe<Scalars['Int']['output']>
  person?: Maybe<Person>
  personId?: Maybe<Scalars['Int']['output']>
  shiftAbs?: Maybe<Scalars['String']['output']>
  shortDescription?: Maybe<Scalars['String']['output']>
  tenantId?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
  timeOfDay?: Maybe<Scalars['String']['output']>
  timeType?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  until?: Maybe<Scalars['String']['output']>
  updateTaskId?: Maybe<Scalars['Int']['output']>
  updated?: Maybe<Scalars['Int']['output']>
  validated?: Maybe<Scalars['Boolean']['output']>
}

export type ShiftNotification = {
  __typename?: 'ShiftNotification'
  count?: Maybe<Scalars['Int']['output']>
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  dates?: Maybe<Scalars['Json']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type ShiftUpdateData = {
  __typename?: 'ShiftUpdateData'
  acknowledgedAt?: Maybe<Scalars['utctimestamp']['output']>
  publishedAt?: Maybe<Scalars['utctimestamp']['output']>
}

export type ShiftUpdateSeenNotification = {
  __typename?: 'ShiftUpdateSeenNotification'
  count?: Maybe<Scalars['Int']['output']>
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  taskUuid?: Maybe<Scalars['String']['output']>
  userName?: Maybe<Scalars['String']['output']>
  userNameShort?: Maybe<Scalars['String']['output']>
}

export type ShiftsByDate = {
  __typename?: 'ShiftsByDate'
  date?: Maybe<Scalars['String']['output']>
  tenants?: Maybe<Array<Maybe<ShiftsByTenantAndCostCenter>>>
}

export type ShiftsByTenantAndCostCenter = {
  __typename?: 'ShiftsByTenantAndCostCenter'
  costCenterName?: Maybe<Scalars['String']['output']>
  costCenterUuid?: Maybe<Scalars['String']['output']>
  hideUnverifiedEffectiveTimes?: Maybe<Scalars['Boolean']['output']>
  ownerId?: Maybe<Scalars['ID']['output']>
  shifts?: Maybe<Array<Maybe<Shift>>>
  tenantId?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
}

export type Stats = {
  __typename?: 'Stats'
  events?: Maybe<StatsEvents>
  newsletters?: Maybe<StatsNewsletters>
  ownerId: Scalars['ID']['output']
  pages?: Maybe<StatsPages>
}

export type StatsEvents = {
  __typename?: 'StatsEvents'
  past?: Maybe<Scalars['Int']['output']>
  upcoming?: Maybe<Scalars['Int']['output']>
}

export type StatsNewsletters = {
  __typename?: 'StatsNewsletters'
  amount?: Maybe<Scalars['Int']['output']>
  latestSent?: Maybe<Scalars['String']['output']>
}

export type StatsPages = {
  __typename?: 'StatsPages'
  amount?: Maybe<Scalars['Int']['output']>
}

export type SubcontentBlockData = {
  __typename?: 'SubcontentBlockData'
  previewImageFilename?: Maybe<Scalars['String']['output']>
  previewText?: Maybe<Scalars['String']['output']>
  subcontentUuid?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type Task = {
  __typename?: 'Task'
  comment?: Maybe<Scalars['String']['output']>
  favurUuid?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  insertedAt?: Maybe<Scalars['String']['output']>
  person?: Maybe<Person>
  personTriggeredBy?: Maybe<Person>
  shiftsUpdated?: Maybe<Array<Maybe<Shift>>>
  shiftsUpdatedHistory?: Maybe<Array<Maybe<Shift>>>
  status?: Maybe<Scalars['String']['output']>
  statusFe?: Maybe<Scalars['String']['output']>
  taskData?: Maybe<TaskData>
  taskMonthlySheet?: Maybe<TaskMonthlySheet>
  taskType?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
  timeline?: Maybe<Array<Maybe<TimelineItem>>>
  triggeredByPersonId?: Maybe<Scalars['ID']['output']>
  triggeredByUserName?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  userName?: Maybe<Scalars['String']['output']>
}

export type TaskCounts = {
  __typename?: 'TaskCounts'
  closed?: Maybe<Scalars['Int']['output']>
  pending?: Maybe<Scalars['Int']['output']>
  secondView?: Maybe<Scalars['Int']['output']>
  todo?: Maybe<Scalars['Int']['output']>
}

export type TaskData = AbsenceData | MonthlySheetData | ShareUserData | ShiftUpdateData

export type TaskFilters = {
  endDate?: InputMaybe<Scalars['String']['input']>
  internalTaskStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  startDate?: InputMaybe<Scalars['String']['input']>
  taskIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  taskStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  taskTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type TaskListAbsenceData = {
  __typename?: 'TaskListAbsenceData'
  dateFrom?: Maybe<Scalars['String']['output']>
  dateTo?: Maybe<Scalars['String']['output']>
  dates?: Maybe<Array<Maybe<AbsenceDateElement>>>
  reviewerComment?: Maybe<Scalars['String']['output']>
}

export type TaskListElement = {
  __typename?: 'TaskListElement'
  comment?: Maybe<Scalars['String']['output']>
  favurUuid?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  insertedAt?: Maybe<Scalars['String']['output']>
  person?: Maybe<Person>
  personId?: Maybe<Scalars['ID']['output']>
  personTriggeredBy?: Maybe<Person>
  shiftsUpdated?: Maybe<Array<Maybe<Shift>>>
  shiftsUpdatedHistory?: Maybe<Array<Maybe<Shift>>>
  status?: Maybe<Scalars['String']['output']>
  statusFe?: Maybe<Scalars['String']['output']>
  taskData?: Maybe<TaskListElementData>
  taskType?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
  timeline?: Maybe<Array<Maybe<TimelineItem>>>
  triggeredByPersonId?: Maybe<Scalars['ID']['output']>
  triggeredByUserName?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  userName?: Maybe<Scalars['String']['output']>
}

export type TaskListElementData = MonthlySheetData | ShareUserData | ShiftUpdateData | TaskListAbsenceData

export type TaskMonthlySheet = {
  __typename?: 'TaskMonthlySheet'
  document?: Maybe<Document>
  documentId?: Maybe<Scalars['ID']['output']>
  task?: Maybe<Task>
  taskId?: Maybe<Scalars['ID']['output']>
}

export type TasksObject = {
  __typename?: 'TasksObject'
  list?: Maybe<Array<Maybe<TaskListElement>>>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type Team = {
  __typename?: 'Team'
  id?: Maybe<Scalars['String']['output']>
  nameDe?: Maybe<Scalars['String']['output']>
  nameEn?: Maybe<Scalars['String']['output']>
  nameFr?: Maybe<Scalars['String']['output']>
  nameIt?: Maybe<Scalars['String']['output']>
  owner?: Maybe<Owner>
  ownerId?: Maybe<Scalars['Int']['output']>
  public?: Maybe<Scalars['Boolean']['output']>
  showTeamplanCostCenters?: Maybe<Scalars['Boolean']['output']>
  teamPermissions?: Maybe<Array<Maybe<PersonTeamPermission>>>
  tenant?: Maybe<Tenant>
  tenantId?: Maybe<Scalars['ID']['output']>
}

export type TeamTeamPermissionsArgs = {
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  removeSupervisors?: InputMaybe<Scalars['Boolean']['input']>
}

export type TeamplanCostCenters = {
  __typename?: 'TeamplanCostCenters'
  name?: Maybe<Scalars['String']['output']>
  persons?: Maybe<Array<Maybe<TeamplanPersons>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type TeamplanDate = {
  __typename?: 'TeamplanDate'
  date?: Maybe<Scalars['String']['output']>
  tenants?: Maybe<Array<Maybe<TeamplanTenants>>>
}

export type TeamplanPersons = {
  __typename?: 'TeamplanPersons'
  avatarImageUuid?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  isUser?: Maybe<Scalars['Boolean']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  personTeamPermissions?: Maybe<Array<Maybe<PersonTeamPermission>>>
  shifts?: Maybe<Array<Maybe<Shift>>>
}

export type TeamplanTenants = {
  __typename?: 'TeamplanTenants'
  costCenters?: Maybe<Array<Maybe<TeamplanCostCenters>>>
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type TeamsResult = {
  __typename?: 'TeamsResult'
  member?: Maybe<Array<Maybe<Team>>>
  public?: Maybe<Array<Maybe<Team>>>
}

export type TeamsResultMemberArgs = {
  isManager?: InputMaybe<Scalars['Boolean']['input']>
  memberPermissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type Tenant = {
  __typename?: 'Tenant'
  absencesBlockingPeriod?: Maybe<Scalars['Int']['output']>
  hideUnverifiedEffectiveTimes?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['ID']['output']>
  links?: Maybe<Array<Maybe<TenantLink>>>
  modules?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  name?: Maybe<Scalars['String']['output']>
  ownerId?: Maybe<Scalars['Int']['output']>
  package?: Maybe<Scalars['String']['output']>
  persons?: Maybe<Array<Maybe<Person>>>
  reminders?: Maybe<Array<Maybe<TenantReminder>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type TenantPersonsArgs = {
  notStatusIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type TenantLink = {
  __typename?: 'TenantLink'
  id?: Maybe<Scalars['ID']['output']>
  labelDe?: Maybe<Scalars['String']['output']>
  labelEn?: Maybe<Scalars['String']['output']>
  labelFr?: Maybe<Scalars['String']['output']>
  labelIt?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type TenantReminder = {
  __typename?: 'TenantReminder'
  first?: Maybe<Scalars['Int']['output']>
  isDefault?: Maybe<Scalars['Boolean']['output']>
  second?: Maybe<Scalars['Int']['output']>
  third?: Maybe<Scalars['Int']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type TenantReminderInput = {
  first?: InputMaybe<Scalars['Int']['input']>
  second?: InputMaybe<Scalars['Int']['input']>
  tenantId?: InputMaybe<Scalars['ID']['input']>
  third?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type TenantsNamesPersonsIds = {
  __typename?: 'TenantsNamesPersonsIds'
  favurUuid?: Maybe<Scalars['String']['output']>
  permissions?: Maybe<Permissions>
  personId?: Maybe<Scalars['Int']['output']>
  tenantConnectionStatus?: Maybe<Scalars['String']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
}

export type TextBlockData = {
  __typename?: 'TextBlockData'
  html?: Maybe<Scalars['String']['output']>
}

export type TimelineItem = {
  __typename?: 'TimelineItem'
  authorAvatarUrl?: Maybe<Scalars['String']['output']>
  authorFirstName?: Maybe<Scalars['String']['output']>
  authorId?: Maybe<Scalars['ID']['output']>
  authorIsSystem?: Maybe<Scalars['Boolean']['output']>
  authorLastName?: Maybe<Scalars['String']['output']>
  badge?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  document?: Maybe<Document>
  documentId?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  insertedAt?: Maybe<Scalars['String']['output']>
  isDataForm?: Maybe<Scalars['Boolean']['output']>
  status?: Maybe<Scalars['String']['output']>
  taskId?: Maybe<Scalars['ID']['output']>
  tenantName?: Maybe<Scalars['String']['output']>
}

export type User = {
  __typename?: 'User'
  avatarUrl?: Maybe<Scalars['String']['output']>
  birthDate?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  language?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  settings?: Maybe<Settings>
  userDataCollectionLastUpdate?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['Int']['output']>
}

export type UserAnnouncement = {
  __typename?: 'UserAnnouncement'
  announcement?: Maybe<Announcement>
  announcementId?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  insertedAt?: Maybe<Scalars['String']['output']>
  seen?: Maybe<Scalars['Boolean']['output']>
  seenAt?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['Int']['output']>
}

export enum UserAnnouncementStatus {
  Seen = 'SEEN',
  Unseen = 'UNSEEN',
}

export type UserSettings = {
  dashboardEmployeeCards?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  inAppNotifications?: InputMaybe<InputInAppNotifications>
  pushNotifications: InputPushNotifications
  seenTutorials?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ValidationStatus = {
  __typename?: 'ValidationStatus'
  checkedAt?: Maybe<Scalars['utctimestamp']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type VersionObject = {
  __typename?: 'VersionObject'
  currentVersion?: Maybe<Scalars['String']['output']>
  minimumVersionRequired?: Maybe<Scalars['String']['output']>
  recommendedVersion?: Maybe<Scalars['String']['output']>
  requiredVersion?: Maybe<Scalars['String']['output']>
  requirementMet?: Maybe<Scalars['Boolean']['output']>
}

export type GetInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetInfoQuery = {
  __typename?: 'RootQueryType'
  info?: { __typename?: 'Info'; applicationEnv?: string | null } | null
}

export type GetBankInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetBankInfoQuery = {
  __typename?: 'RootQueryType'
  bankInfo?: { __typename?: 'BankInfo'; iban?: string | null; bic?: string | null } | null
}

export type GetContactDataQueryVariables = Exact<{ [key: string]: never }>

export type GetContactDataQuery = {
  __typename?: 'RootQueryType'
  contactData?: {
    __typename?: 'ContactData'
    email?: string | null
    addresses?: Array<{
      __typename?: 'Address'
      type?: string | null
      firstName?: string | null
      lastName?: string | null
      address?: string | null
      additionalAddress?: string | null
      zipCode?: string | null
      city?: string | null
      country?: string | null
      phoneNumber?: string | null
    } | null> | null
  } | null
}

export type GetFamilyDataQueryVariables = Exact<{ [key: string]: never }>

export type GetFamilyDataQuery = {
  __typename?: 'RootQueryType'
  familyData?: {
    __typename?: 'FamilyData'
    civilStatus?: string | null
    civilStatusSince?: string | null
    partner?: {
      __typename?: 'FamilyMember'
      firstName?: string | null
      lastName?: string | null
      gender?: string | null
      birthDate?: string | null
      socialInsuranceNumber?: string | null
    } | null
    children?: Array<{
      __typename?: 'FamilyMember'
      firstName?: string | null
      lastName?: string | null
      gender?: string | null
      birthDate?: string | null
      socialInsuranceNumber?: string | null
    } | null> | null
  } | null
}

export type GetDocumentUploadLinkQueryVariables = Exact<{
  documentType: Scalars['String']['input']
  filename: Scalars['String']['input']
}>

export type GetDocumentUploadLinkQuery = {
  __typename?: 'RootQueryType'
  documentUploadLink?: { __typename?: 'DocumentUploadLink'; link?: string | null } | null
}

export type NativeVersionRequirementQueryVariables = Exact<{
  nativeVersionNumber: Scalars['Int']['input']
  platform: Scalars['String']['input']
}>

export type NativeVersionRequirementQuery = {
  __typename?: 'RootQueryType'
  nativeVersionRequirement?: {
    __typename?: 'NativeVersionObject'
    requiredVersion?: number | null
    requirementMet?: boolean | null
  } | null
}

export type VersionRequirementQueryVariables = Exact<{
  versionNumber?: InputMaybe<Scalars['String']['input']>
}>

export type VersionRequirementQuery = {
  __typename?: 'RootQueryType'
  versionRequirement?: {
    __typename?: 'VersionObject'
    recommendedVersion?: string | null
    requiredVersion?: string | null
  } | null
}

export type RefreshHighSecuritySessionQueryVariables = Exact<{ [key: string]: never }>

export type RefreshHighSecuritySessionQuery = {
  __typename?: 'RootQueryType'
  refreshHighSecuritySession?: {
    __typename?: 'RefreshHighSecuritySession'
    highSecSessionValidUntil?: string | null
  } | null
}

export type AbsencePlannerQueryVariables = Exact<{
  endDate: Scalars['String']['input']
  shiftTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
  showAbsences?: InputMaybe<Scalars['Boolean']['input']>
  startDate: Scalars['String']['input']
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
  showShiftUpdates?: InputMaybe<Scalars['Boolean']['input']>
  showCmsEvents?: InputMaybe<Scalars['Boolean']['input']>
}>

export type AbsencePlannerQuery = {
  __typename?: 'RootQueryType'
  absencePlanner?: Array<{
    __typename?: 'AbsencePlannerPersonDays'
    personId?: string | null
    dayBadges?: Array<{
      __typename?: 'DayBadges'
      date?: string | null
      personId?: string | null
      badges?: Array<{
        __typename?: 'Badge'
        badgeId?: string | null
        date?: string | null
        type?: string | null
        timeOfDay?: string | null
        hexTextColor?: string | null
        hexBackgroundColor?: string | null
        shortDescription?: string | null
        isManual?: boolean | null
        taskUuid?: string | null
        daysRequested?: Array<string | null> | null
        personId?: string | null
        shiftUpdateUuid?: string | null
        numberOfEvents?: number | null
        percent?: number | null
      } | null> | null
    } | null> | null
  } | null> | null
}

export type ListAllAbsencePlannerUserPersonsQueryVariables = Exact<{ [key: string]: never }>

export type ListAllAbsencePlannerUserPersonsQuery = {
  __typename?: 'RootQueryType'
  findAllPersons?: Array<{
    __typename?: 'Person'
    id?: string | null
    firstName?: string | null
    lastName?: string | null
    favurUuid?: string | null
    status?: string | null
    owner?: { __typename?: 'Owner'; id?: string | null; name?: string | null } | null
    tenant?: { __typename?: 'Tenant'; id?: string | null; name?: string | null } | null
    roles?: Array<{ __typename?: 'PersonRole'; role?: string | null } | null> | null
    user?: {
      __typename?: 'User'
      id?: number | null
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
  } | null> | null
}

export type GetCmsContentStatsQueryQueryVariables = Exact<{ [key: string]: never }>

export type GetCmsContentStatsQueryQuery = {
  __typename?: 'RootQueryType'
  cmsContentStats?: Array<{
    __typename?: 'Stats'
    ownerId: string
    pages?: { __typename?: 'StatsPages'; amount?: number | null } | null
    events?: { __typename?: 'StatsEvents'; upcoming?: number | null } | null
    newsletters?: { __typename?: 'StatsNewsletters'; latestSent?: string | null; amount?: number | null } | null
  } | null> | null
}

export type CmsContentGetQueryVariables = Exact<{
  contentUuid: Scalars['ID']['input']
}>

export type CmsContentGetQuery = {
  __typename?: 'RootQueryType'
  cmsContentGet?: {
    __typename?: 'Content'
    uuid?: string | null
    contentType?: string | null
    contentStatusType?: string | null
    createdAt?: string | null
    startDate?: string | null
    endDate?: string | null
    sentAt?: string | null
    isAbsenceRestricted?: boolean | null
    isWholeDay?: boolean | null
    restrictAbsenceComment?: string | null
    history?: Array<{
      __typename?: 'History'
      insertedAt?: string | null
      updatedAt?: string | null
      userFirstName?: string | null
      userLastName?: string | null
      avatarImageUuid?: string | null
      modificationType?: string | null
    } | null> | null
    audiences?: Array<{
      __typename?: 'Audience'
      audienceType?: string | null
      nameDe?: string | null
      nameEn?: string | null
      nameFr?: string | null
      nameIt?: string | null
      uuid?: string | null
    } | null> | null
    metadatas?: Array<{
      __typename?: 'Metadata'
      title?: string | null
      shortDescription?: string | null
      language?: string | null
    } | null> | null
  } | null
}

export type DocumentsPaginatedQueryVariables = Exact<{
  filters?: InputMaybe<DocumentFilters>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type DocumentsPaginatedQuery = {
  __typename?: 'RootQueryType'
  documentsPaginated?: {
    __typename?: 'DocumentsObject'
    totalCount?: number | null
    list?: Array<{
      __typename?: 'Document'
      id?: number | null
      documentType?: string | null
      documentUuid?: string | null
      favurUuid?: string | null
      status?: string | null
      data?: any | null
      lang?: string | null
      createdByMirus?: any | null
      person?: {
        __typename?: 'Person'
        id?: string | null
        status?: string | null
        tenant?: { __typename?: 'Tenant'; name?: string | null } | null
      } | null
      taskMonthlySheet?: {
        __typename?: 'TaskMonthlySheet'
        task?: { __typename?: 'Task'; favurUuid?: string | null } | null
      } | null
    } | null> | null
  } | null
}

export type DismissBasicDocumentNotificationsMutationVariables = Exact<{ [key: string]: never }>

export type DismissBasicDocumentNotificationsMutation = {
  __typename?: 'RootMutationType'
  dismissBasicDocumentNotifications?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type EmployeeCardDataQueryQueryVariables = Exact<{ [key: string]: never }>

export type EmployeeCardDataQueryQuery = {
  __typename?: 'RootQueryType'
  findAllPersons?: Array<{
    __typename?: 'Person'
    id?: string | null
    firstName?: string | null
    lastName?: string | null
    favurUuid?: string | null
    status?: string | null
    employeeNumber?: string | null
    tenant?: { __typename?: 'Tenant'; id?: string | null; name?: string | null } | null
    user?: {
      __typename?: 'User'
      id?: number | null
      firstName?: string | null
      lastName?: string | null
      avatarUrl?: string | null
    } | null
    costCenter?: { __typename?: 'PersonCostCenter'; name?: string | null } | null
  } | null> | null
}

export type GetPersonValidationStatusQueryVariables = Exact<{
  favurUuid: Scalars['String']['input']
}>

export type GetPersonValidationStatusQuery = {
  __typename?: 'RootQueryType'
  getPersonValidationStatus?: { __typename?: 'ValidationStatus'; status?: string | null } | null
}

export type InitRegisterMutationVariables = Exact<{
  invitationCode: Scalars['String']['input']
}>

export type InitRegisterMutation = {
  __typename?: 'RootMutationType'
  initRegister?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type AuthStartMutationVariables = Exact<{
  phone: Scalars['String']['input']
  appSignature?: InputMaybe<Scalars['String']['input']>
}>

export type AuthStartMutation = {
  __typename?: 'RootMutationType'
  authStart?: {
    __typename?: 'AuthStartResponse'
    auth?: number | null
    invitationCode?: string | null
    user?: { __typename?: 'User'; phoneNumber?: string | null } | null
  } | null
}

export type ResendSmsMutationVariables = Exact<{
  appSignature?: InputMaybe<Scalars['String']['input']>
}>

export type ResendSmsMutation = {
  __typename?: 'RootMutationType'
  resendSms?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type CreateShareUserDataRequestMutationVariables = Exact<{
  personIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  triggeredByPersonId: Scalars['ID']['input']
  comment?: InputMaybe<Scalars['String']['input']>
  userComment?: InputMaybe<Scalars['String']['input']>
}>

export type CreateShareUserDataRequestMutation = {
  __typename?: 'RootMutationType'
  createShareUserDataRequest?: {
    __typename?: 'CreateShareUserDataResponse'
    success?: boolean | null
    personsWithErrors?: Array<string | null> | null
  } | null
}

export type GetLowSecurityTenantNamesQueryVariables = Exact<{ [key: string]: never }>

export type GetLowSecurityTenantNamesQuery = {
  __typename?: 'RootQueryType'
  lowSecurityTenantNames?: Array<{
    __typename?: 'TenantsNamesPersonsIds'
    tenantName?: string | null
    personId?: number | null
  } | null> | null
}

export type GetTasksQueryVariables = Exact<{
  favurUuid: Scalars['String']['input']
}>

export type GetTasksQuery = {
  __typename?: 'RootQueryType'
  task?: {
    __typename?: 'Task'
    person?: {
      __typename?: 'Person'
      id?: string | null
      tenant?: { __typename?: 'Tenant'; name?: string | null } | null
    } | null
  } | null
}

export type TeamplanWithTeamsQueryVariables = Exact<{
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
}>

export type TeamplanWithTeamsQuery = {
  __typename?: 'RootQueryType'
  teamplanWithTeams?: Array<{
    __typename?: 'TeamplanDate'
    date?: string | null
    tenants?: Array<{
      __typename?: 'TeamplanTenants'
      id?: string | null
      name?: string | null
      costCenters?: Array<{
        __typename?: 'TeamplanCostCenters'
        uuid?: string | null
        name?: string | null
        persons?: Array<{
          __typename?: 'TeamplanPersons'
          id?: string | null
          firstName?: string | null
          lastName?: string | null
          isUser?: boolean | null
          avatarImageUuid?: string | null
          personTeamPermissions?: Array<{
            __typename?: 'PersonTeamPermission'
            supervisor?: boolean | null
            team?: {
              __typename?: 'Team'
              tenant?: { __typename?: 'Tenant'; id?: string | null; name?: string | null } | null
              owner?: { __typename?: 'Owner'; id?: string | null; name?: string | null } | null
            } | null
          } | null> | null
        } | null> | null
      } | null> | null
    } | null> | null
  } | null> | null
}

export type CreateAbsenceMutationVariables = Exact<{
  comment: Scalars['String']['input']
  data: NewTaskWorkflowAbsences
  personUuid: Scalars['String']['input']
  taskType: Scalars['String']['input']
  triggeredByPersonUuid: Scalars['String']['input']
}>

export type CreateAbsenceMutation = {
  __typename?: 'RootMutationType'
  createAbsence?: { __typename?: 'MutationOkTask'; success?: boolean | null; taskUuid?: string | null } | null
}

export type UpdateAbsenceStatusMutationVariables = Exact<{
  dates?: InputMaybe<Array<InputMaybe<InputAbsenceDateElement>> | InputMaybe<InputAbsenceDateElement>>
  internalComment?: InputMaybe<Scalars['String']['input']>
  reviewerComment?: InputMaybe<Scalars['String']['input']>
  status: Scalars['String']['input']
  taskUuid: Scalars['String']['input']
}>

export type UpdateAbsenceStatusMutation = {
  __typename?: 'RootMutationType'
  updateAbsenceStatus?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type ShareOwnUserDataMutationVariables = Exact<{
  personIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  userComment?: InputMaybe<Scalars['String']['input']>
}>

export type ShareOwnUserDataMutation = {
  __typename?: 'RootMutationType'
  shareOwnUserData?: {
    __typename?: 'CreateShareUserDataResponse'
    success?: boolean | null
    personsWithErrors?: Array<string | null> | null
  } | null
}

export type UpdateShiftUpdateStatusMutationVariables = Exact<{
  favurUuid: Scalars['String']['input']
  status: Scalars['String']['input']
}>

export type UpdateShiftUpdateStatusMutation = {
  __typename?: 'RootMutationType'
  updateShiftUpdateStatus?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type DismissByTaskMutationVariables = Exact<{
  taskUuid: Scalars['String']['input']
  isManager: Scalars['Boolean']['input']
}>

export type DismissByTaskMutation = {
  __typename?: 'RootMutationType'
  dismissByTask?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type UpdateShareUserDataRequestMutationVariables = Exact<{
  favurUuid: Scalars['String']['input']
  status: Scalars['String']['input']
  userComment?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateShareUserDataRequestMutation = {
  __typename?: 'RootMutationType'
  updateShareUserDataRequest?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type UpdateTeamPermissionsMutationVariables = Exact<{
  changes: MemberChanges
  permission: Scalars['String']['input']
  teamId: Scalars['String']['input']
}>

export type UpdateTeamPermissionsMutation = {
  __typename?: 'RootMutationType'
  updateTeamPermissions?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type DeleteTeamMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteTeamMutation = {
  __typename?: 'RootMutationType'
  deleteTeam?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type UpdateTeamNameMutationVariables = Exact<{
  id: Scalars['String']['input']
  nameEn: Scalars['String']['input']
  nameDe: Scalars['String']['input']
  nameFr: Scalars['String']['input']
  nameIt: Scalars['String']['input']
}>

export type UpdateTeamNameMutation = {
  __typename?: 'RootMutationType'
  updateTeamName?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type UpdateTeamPublicStatusMutationVariables = Exact<{
  id: Scalars['String']['input']
  public: Scalars['Boolean']['input']
}>

export type UpdateTeamPublicStatusMutation = {
  __typename?: 'RootMutationType'
  updateTeamPublicStatus?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type UpdateTeamShowTeamplanCostCentersStatusMutationVariables = Exact<{
  id: Scalars['String']['input']
  showTeamplanCostCenters: Scalars['Boolean']['input']
}>

export type UpdateTeamShowTeamplanCostCentersStatusMutation = {
  __typename?: 'RootMutationType'
  updateTeamShowTeamplanCostCentersStatus?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type CreateTeamMutationVariables = Exact<{
  nameDe: Scalars['String']['input']
  nameEn?: InputMaybe<Scalars['String']['input']>
  nameFr?: InputMaybe<Scalars['String']['input']>
  nameIt?: InputMaybe<Scalars['String']['input']>
  personId?: InputMaybe<Scalars['Int']['input']>
  tenantId?: InputMaybe<Scalars['ID']['input']>
}>

export type CreateTeamMutation = {
  __typename?: 'RootMutationType'
  createTeam?: {
    __typename?: 'Team'
    id?: string | null
    ownerId?: number | null
    nameDe?: string | null
    nameEn?: string | null
    nameFr?: string | null
    nameIt?: string | null
  } | null
}

export type StartDeletePersonMutationMutationVariables = Exact<{
  favurUuid: Scalars['String']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}>

export type StartDeletePersonMutationMutation = {
  __typename?: 'RootMutationType'
  startDeletePerson?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type DeleteUserQueryMutationVariables = Exact<{ [key: string]: never }>

export type DeleteUserQueryMutation = {
  __typename?: 'RootMutationType'
  deleteUser?: { __typename?: 'LogoutInfo'; auth?: number | null } | null
}

export type PersonalDataQueryVariables = Exact<{ [key: string]: never }>

export type PersonalDataQuery = {
  __typename?: 'RootQueryType'
  me?: {
    __typename?: 'Me'
    user?: {
      __typename?: 'User'
      firstName?: string | null
      lastName?: string | null
      birthDate?: string | null
      phoneNumber?: string | null
    } | null
  } | null
}

export type GetAvatarUploadLinksQueryVariables = Exact<{ [key: string]: never }>

export type GetAvatarUploadLinksQuery = {
  __typename?: 'RootQueryType'
  getAvatarUploadLinks?: {
    __typename?: 'GetAvatarUrlsResponse'
    profile?: string | null
    avatar32?: string | null
    avatar48?: string | null
    avatar64?: string | null
    avatar96?: string | null
    avatar128?: string | null
    avatar252?: string | null
    avatar504?: string | null
    imageUuid?: string | null
  } | null
}

export type DeleteAvatarMutationVariables = Exact<{ [key: string]: never }>

export type DeleteAvatarMutation = {
  __typename?: 'RootMutationType'
  deleteAvatar?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type UpdateUserAvatarUrlMutationMutationVariables = Exact<{
  imageUuid: Scalars['String']['input']
}>

export type UpdateUserAvatarUrlMutationMutation = {
  __typename?: 'RootMutationType'
  updateUserAvatarUrl?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export type EditTenantMutationVariables = Exact<{
  tenantId: Scalars['ID']['input']
  editTenant: EditTenant
}>

export type EditTenantMutation = {
  __typename?: 'RootMutationType'
  editTenant?: { __typename?: 'MutationOk'; success?: boolean | null } | null
}

export const GetInfoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'applicationEnv' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetInfoQuery, GetInfoQueryVariables>
export const GetBankInfoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBankInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetBankInfoQuery, GetBankInfoQueryVariables>
export const GetContactDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getContactData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetContactDataQuery, GetContactDataQueryVariables>
export const GetFamilyDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFamilyData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'familyData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'civilStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'civilStatusSince' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialInsuranceNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialInsuranceNumber' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetFamilyDataQuery, GetFamilyDataQueryVariables>
export const GetDocumentUploadLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDocumentUploadLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentUploadLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'link' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetDocumentUploadLinkQuery, GetDocumentUploadLinkQueryVariables>
export const NativeVersionRequirementDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'nativeVersionRequirement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nativeVersionNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nativeVersionRequirement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nativeVersionNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nativeVersionNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platform' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'platform' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'requiredVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requirementMet' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<NativeVersionRequirementQuery, NativeVersionRequirementQueryVariables>
export const VersionRequirementDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'versionRequirement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versionRequirement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'buildVersionNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'versionNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'recommendedVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requiredVersion' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<VersionRequirementQuery, VersionRequirementQueryVariables>
export const RefreshHighSecuritySessionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'refreshHighSecuritySession' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refreshHighSecuritySession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'highSecSessionValidUntil' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RefreshHighSecuritySessionQuery, RefreshHighSecuritySessionQueryVariables>
export const AbsencePlannerDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'absencePlanner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shiftTypes' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'showAbsences' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'showShiftUpdates' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'showCmsEvents' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'absencePlanner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showAbsences' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'showAbsences' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shiftTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shiftTypes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showShiftUpdates' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'showShiftUpdates' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showCmsEvents' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'showCmsEvents' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'personId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dayBadges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'personId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'badges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'badgeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'timeOfDay' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hexTextColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hexBackgroundColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isManual' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taskUuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'daysRequested' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'personId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shiftUpdateUuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfEvents' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<AbsencePlannerQuery, AbsencePlannerQueryVariables>
export const ListAllAbsencePlannerUserPersonsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listAllAbsencePlannerUserPersons' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findAllPersons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favurUuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'role' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ListAllAbsencePlannerUserPersonsQuery, ListAllAbsencePlannerUserPersonsQueryVariables>
export const GetCmsContentStatsQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCmsContentStatsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cmsContentStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'events' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'upcoming' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newsletters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'latestSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCmsContentStatsQueryQuery, GetCmsContentStatsQueryQueryVariables>
export const CmsContentGetDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'cmsContentGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contentUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cmsContentGet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contentUuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentStatusType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAbsenceRestricted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isWholeDay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictAbsenceComment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'insertedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarImageUuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modificationType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audiences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'audienceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nameDe' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nameEn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nameFr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nameIt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadatas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CmsContentGetQuery, CmsContentGetQueryVariables>
export const DocumentsPaginatedDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'documentsPaginated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentFilters' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentsPaginated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'person' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tenant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'documentUuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'favurUuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByMirus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taskMonthlySheet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'task' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'favurUuid' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DocumentsPaginatedQuery, DocumentsPaginatedQueryVariables>
export const DismissBasicDocumentNotificationsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'dismissBasicDocumentNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissBasicDocumentNotifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DismissBasicDocumentNotificationsMutation,
  DismissBasicDocumentNotificationsMutationVariables
>
export const EmployeeCardDataQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'employeeCardDataQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findAllPersons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favurUuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employeeNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'costCenter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<EmployeeCardDataQueryQuery, EmployeeCardDataQueryQueryVariables>
export const GetPersonValidationStatusDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPersonValidationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPersonValidationStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'favurUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetPersonValidationStatusQuery, GetPersonValidationStatusQueryVariables>
export const InitRegisterDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'initRegister' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitationCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initRegister' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitationCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<InitRegisterMutation, InitRegisterMutationVariables>
export const AuthStartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'authStart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'appSignature' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authStart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'appSignature' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'appSignature' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'auth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invitationCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<AuthStartMutation, AuthStartMutationVariables>
export const ResendSmsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resendSms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'appSignature' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendSms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'appSignature' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'appSignature' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ResendSmsMutation, ResendSmsMutationVariables>
export const CreateShareUserDataRequestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createShareUserDataRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'personIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'triggeredByPersonId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userComment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createShareUserDataRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'personIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'triggeredByPersonId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'triggeredByPersonId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userComment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userComment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personsWithErrors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CreateShareUserDataRequestMutation, CreateShareUserDataRequestMutationVariables>
export const GetLowSecurityTenantNamesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLowSecurityTenantNames' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lowSecurityTenantNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'tenantName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetLowSecurityTenantNamesQuery, GetLowSecurityTenantNamesQueryVariables>
export const GetTasksDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'favurUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'person' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetTasksQuery, GetTasksQueryVariables>
export const TeamplanWithTeamsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teamplanWithTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamplanWithTeams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costCenters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'persons' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isUser' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatarImageUuid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personTeamPermissions' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'supervisor' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'team' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tenant' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'owner' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<TeamplanWithTeamsQuery, TeamplanWithTeamsQueryVariables>
export const CreateAbsenceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAbsence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewTaskWorkflowAbsences' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'personUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'triggeredByPersonUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAbsence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'personUuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'triggeredByPersonUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'triggeredByPersonUuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskUuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CreateAbsenceMutation, CreateAbsenceMutationVariables>
export const UpdateAbsenceStatusDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAbsenceStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dates' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAbsenceDateElement' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'internalComment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reviewerComment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAbsenceStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dates' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dates' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalReviewerComment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'internalComment' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reviewerComment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reviewerComment' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskUuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateAbsenceStatusMutation, UpdateAbsenceStatusMutationVariables>
export const ShareOwnUserDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'shareOwnUserData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'personIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userComment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareOwnUserData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'personIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userComment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userComment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personsWithErrors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ShareOwnUserDataMutation, ShareOwnUserDataMutationVariables>
export const UpdateShiftUpdateStatusDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateShiftUpdateStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateShiftUpdateStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'favurUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateShiftUpdateStatusMutation, UpdateShiftUpdateStatusMutationVariables>
export const DismissByTaskDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'dismissByTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isManager' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismissByTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskUuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isManager' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isManager' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DismissByTaskMutation, DismissByTaskMutationVariables>
export const UpdateShareUserDataRequestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateShareUserDataRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userComment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateShareUserDataRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'favurUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userComment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userComment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateShareUserDataRequestMutation, UpdateShareUserDataRequestMutationVariables>
export const UpdateTeamPermissionsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTeamPermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'changes' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberChanges' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'permission' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTeamPermissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'changes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'changes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'permission' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'permission' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateTeamPermissionsMutation, UpdateTeamPermissionsMutationVariables>
export const DeleteTeamDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DeleteTeamMutation, DeleteTeamMutationVariables>
export const UpdateTeamNameDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTeamName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nameEn' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nameDe' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nameFr' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nameIt' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTeamName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nameEn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nameEn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nameDe' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nameDe' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nameFr' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nameFr' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nameIt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nameIt' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateTeamNameMutation, UpdateTeamNameMutationVariables>
export const UpdateTeamPublicStatusDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTeamPublicStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTeamPublicStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateTeamPublicStatusMutation, UpdateTeamPublicStatusMutationVariables>
export const UpdateTeamShowTeamplanCostCentersStatusDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTeamShowTeamplanCostCentersStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'showTeamplanCostCenters' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTeamShowTeamplanCostCentersStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showTeamplanCostCenters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'showTeamplanCostCenters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateTeamShowTeamplanCostCentersStatusMutation,
  UpdateTeamShowTeamplanCostCentersStatusMutationVariables
>
export const CreateTeamDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nameDe' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nameEn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nameFr' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nameIt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'personId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nameDe' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nameDe' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nameEn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nameEn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nameFr' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nameFr' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nameIt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nameIt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'personId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nameDe' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nameEn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nameFr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nameIt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CreateTeamMutation, CreateTeamMutationVariables>
export const StartDeletePersonMutationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startDeletePersonMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startDeletePerson' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'favurUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'favurUuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<StartDeletePersonMutationMutation, StartDeletePersonMutationMutationVariables>
export const DeleteUserQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUserQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'auth' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DeleteUserQueryMutation, DeleteUserQueryMutationVariables>
export const PersonalDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'personalData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PersonalDataQuery, PersonalDataQueryVariables>
export const GetAvatarUploadLinksDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAvatarUploadLinks' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAvatarUploadLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar32' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar48' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar64' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar96' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar128' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar252' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar504' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetAvatarUploadLinksQuery, GetAvatarUploadLinksQueryVariables>
export const DeleteAvatarDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAvatar' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DeleteAvatarMutation, DeleteAvatarMutationVariables>
export const UpdateUserAvatarUrlMutationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserAvatarUrlMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageUuid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserAvatarUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'imageUuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'imageUuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateUserAvatarUrlMutationMutation, UpdateUserAvatarUrlMutationMutationVariables>
export const EditTenantDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editTenant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'editTenant' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditTenant' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editTenant' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'editTenant' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<EditTenantMutation, EditTenantMutationVariables>
