import React from 'react'
import { Box } from '@mui/material'
import useCms from '../../../../../hooks/useCms'
import EventsButton from './EventsButton'
import FiltersButton from './FiltersButton'
import ManagerTasksButton from './ManagerTasksButton'
import UserTasksButton from './UserTasksButton'
import { iconTabsClasses } from './styles'

export interface IIconTabsProps {
  isAbsenceManager?: boolean
  taskCountTotal: number
}

const IconTabs: React.FC<IIconTabsProps> = ({ isAbsenceManager, taskCountTotal }) => {
  const { cmsEventsAccess } = useCms()

  return (
    <Box sx={iconTabsClasses.iconContainer}>
      {isAbsenceManager ? (
        <>
          <ManagerTasksButton taskCountTotal={taskCountTotal} />
          <UserTasksButton />
          <FiltersButton />
          {cmsEventsAccess && <EventsButton />}
        </>
      ) : (
        <>
          <FiltersButton />
          <UserTasksButton />
          {cmsEventsAccess && <EventsButton />}
        </>
      )}
    </Box>
  )
}

export default IconTabs
