import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import RadioFormGroup from 'redesign/components/molecules/RadioFormGroup'
import { Container } from './styles'
import { useOwnerSettings } from './useOwnerSettings'

const OwnerSettingsBlock: React.FC = () => {
  const { t } = useFavurTranslation()
  const { setSelectedOwnerId, selectedOwnerId, options } = useOwnerSettings()

  if (Object.keys(options).length < 2) return <></>

  return (
    <Container>
      <RadioFormGroup
        legend={t('absencePlanner.taskbar.filter.owners')}
        options={options}
        setSelectedValue={setSelectedOwnerId}
        selectedValue={selectedOwnerId}
      />
    </Container>
  )
}

export default OwnerSettingsBlock
