import { useState, useMemo, useCallback } from 'react'
import { PopoverOrigin } from '@mui/material'
import useAbsenceRequests from 'hooks/useAbsenceRequests'
import { usePersonDayShifts } from 'hooks/usePersonDayShifts'
import { getPopoverOrigin } from 'pages/AbsencePlanner/components/Grid/ShiftDialog/utils'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import { absenceRequestStatus } from 'pages/Tasks/AbsenceRequest/constants'
import type { TUseShiftDialog } from './types'
import { getPersonDayShiftParams, getTenantShiftsUpdateTaskIds } from './utils'

export const useShiftDialog = ({
  selectedShift,
  shownTypes,
  onClose,
  cursorClickPosition,
  isUser,
  userIsAbsenceManager,
}: TUseShiftDialog) => {
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [popoverOrigin, setPopoverOrigin] = useState<{
    anchorOrigin: PopoverOrigin
    transformOrigin: PopoverOrigin
  }>({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  })
  const { date, personId, types } = useMemo(() => getPersonDayShiftParams(selectedShift, shownTypes), [
    selectedShift,
    shownTypes,
  ])
  const { shifts, loading: shiftsLoading } = usePersonDayShifts({ date, personId, types }, (_newShits) => {
    if (isSmallScreen) {
      setIsDrawerOpen(true)
    } else {
      setIsPopoverOpen(true)
      setPopoverOrigin(getPopoverOrigin(cursorClickPosition))
    }
  })

  const shiftUpdateIds = useMemo(() => (isUser ? getTenantShiftsUpdateTaskIds(shifts) : []), [isUser, shifts])
  const { tasks: shiftUpdates, loading: shiftUpdatesLoading } = useShiftUpdates({ taskIds: shiftUpdateIds })

  const { tasks: absenceRequests } = useAbsenceRequests({
    skip: !personId || !userIsAbsenceManager,
    personIds: [personId as number],
    date,
    statuses: [absenceRequestStatus.approvedOffice, absenceRequestStatus.rejectedDeletionRequest],
  })
  const onCloseDialog = useCallback(() => {
    setIsDrawerOpen(false)
    setIsPopoverOpen(false)
    onClose()
  }, [onClose])

  return {
    date,
    isPopoverOpen,
    isDrawerOpen,
    popoverOrigin,
    shifts,
    shiftUpdates,
    shiftsLoading,
    shiftUpdatesLoading,
    absenceRequests,
    onCloseDialog,
    isSmallScreen,
  }
}
