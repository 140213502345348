import React from 'react'
import PlannedTimeCards from '../ShiftSection/PlannedTimeCards'
import { CardsContainer, Container } from '../styles'
import type { NoShiftTenantSectionProps } from './types'

const NoShiftTenantSection: React.FC<NoShiftTenantSectionProps> = ({
  filteredPersonsWithTenant,
  isPast,
  date,
  getAbsenceRequestsByTenantId,
  onTaskClick,
}) => {
  if (!filteredPersonsWithTenant || filteredPersonsWithTenant.length <= 0) return <></>

  return (
    <Container>
      <CardsContainer>
        {filteredPersonsWithTenant.map(({ tenant }) => {
          if (!tenant) return null

          return (
            <PlannedTimeCards
              isPast={isPast}
              date={date}
              tenant={tenant}
              absenceRequests={getAbsenceRequestsByTenantId(tenant.id)}
              onTaskClick={onTaskClick}
              groupedShifts={[]}
              key={`${date}-${tenant.name}`}
            />
          )
        })}
      </CardsContainer>
    </Container>
  )
}

export default NoShiftTenantSection
