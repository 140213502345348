import React from 'react'
import { useTheme } from '@mui/material'
import { Container, StyledDivider, Title } from './styles'
import type { IconAndTextBlockProps } from './types'

const IconAndTextBlock: React.FC<IconAndTextBlockProps> = ({
  color,
  gap,
  icon,
  lineThrough = false,
  overflowSingleLine = false,
  title,
  variant = 'subtitle1',
  titleTestId,
}) => {
  const theme = useTheme()

  return (
    <Container gap={gap}>
      {Boolean(lineThrough) && <StyledDivider color={theme.palette.text.secondary} />}
      {icon}
      <Title
        data-testid={titleTestId}
        overflowSingleLine={overflowSingleLine}
        color={color ?? theme.palette.text.primary}
        variant={variant}
      >
        {title}
      </Title>
    </Container>
  )
}

export default IconAndTextBlock
