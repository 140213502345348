import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const ChevronLeftDuo: React.FC<IconProps> = ({
  color = 'currentColor',
  fillOpacity,
  size = ICON_SIZE.medium,
  viewBox = ICON_VIEWBOX.medium,
  rotate,
}) => (
  <SvgWrapper viewBox={viewBox} size={size} rotate={rotate}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.707 7.293a1 1 0 0 1 0 1.414L8.414 12l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0Zm6 0a1 1 0 0 1 0 1.414L14.414 12l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0Z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  </SvgWrapper>
)

export default ChevronLeftDuo
