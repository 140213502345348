import React from 'react'
import { Typography, IconButton, Box } from '@mui/material'
import { ArrowLeftM } from 'icons'
import { useHistory } from 'react-router-dom'
import { classes } from './styles'

const PageHeader: React.FC<{ title: string }> = ({ title }) => {
  const history = useHistory()
  return (
    <Box sx={classes.headerContainer}>
      <IconButton sx={classes.headerIcon} onClick={() => history.goBack()} size="large">
        <ArrowLeftM />
      </IconButton>
      <Typography variant="h2">{title}</Typography>
    </Box>
  )
}

export default PageHeader
