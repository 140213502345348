import React from 'react'
import { Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventM } from 'icons'
import CompanyPagesM from 'icons/CompanyPagesM'
import NewsletterM from 'icons/NewsletterM'
import { DATE_YEAR_FORMAT, formatDateFromString } from 'pages/Cms/EventsList/EventCard/utils'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import palette from 'utils/theme/palette'
import useCms from '../../../../hooks/useCms'
import Section from '../components/Section'
import SectionCTA from '../components/SectionCTA'
import { ICompanyOverviewProps } from './types'

const CompanyOverview: React.FC<ICompanyOverviewProps> = ({ title, subtitle, ownerStats }) => {
  const { t, locale } = useFavurTranslation()
  const { cmsEventsAccess, cmsNewslettersAccess, cmsPagesAccess } = useCms()
  const history = useHistory()

  const latestNewsletter = ownerStats.newsletters?.latestSent
    ? formatDateFromString({ dateString: ownerStats.newsletters?.latestSent, format: DATE_YEAR_FORMAT, locale })
    : null

  const latestNewsletterSubtitle = latestNewsletter
    ? t('page.cms.overview.newsletter.subText', {
        date: latestNewsletter,
      })
    : undefined

  const upcomingEventsSubtitle =
    ownerStats.events?.upcoming && ownerStats.events?.upcoming > 0
      ? t('page.cms.overview.events.subText', {
          number: ownerStats.events?.upcoming,
        })
      : undefined

  return (
    <Box sx={{ padding: '16px' }}>
      <Section title={title} subtitle={subtitle}>
        {cmsEventsAccess && (
          <SectionCTA
            id="overview-event-block"
            title={t('page.cms.overview.events.title')}
            subtitle={upcomingEventsSubtitle}
            onClick={() => history.push(routes.cmsEventIndexOwner(ownerStats.ownerId))}
            icon={<EventM fill={palette.primary.main} />}
          />
        )}
        {cmsPagesAccess && (
          <SectionCTA
            id="overview-wiki-block"
            title={t('page.cms.overview.pages.title')}
            onClick={() => history.push(routes.cmsContentIndexOwner(ownerStats.ownerId))}
            icon={<CompanyPagesM stroke={palette.primary.main} />}
          />
        )}
        {cmsNewslettersAccess && (
          <SectionCTA
            id="overview-newsletter-block"
            title={t('page.cms.overview.newsletter.title')}
            subtitle={latestNewsletterSubtitle}
            onClick={() => history.push(routes.cmsNewsletterIndexOwner(ownerStats.ownerId))}
            icon={<NewsletterM fill={palette.primary.main} />}
          />
        )}
      </Section>
    </Box>
  )
}

export default CompanyOverview
