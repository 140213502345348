import React from 'react'
import { useTheme } from '@mui/material'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import { NewAbsenceButton } from 'redesign/components/atoms/Buttons/NewAbsenceButton'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import { DrawerHeaderWrapper } from 'redesign/components/molecules/DrawerHeaderWrapper'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import ContentCard from '../../../organisms/ContentCard'
import Header from '../../../organisms/Header'
import { SIDE_MENU_TRANSLATIONS } from '../../SideMenu/constants'
import type { MenuOptions, MenuPageProps } from '../../SideMenu/types'
import { getMainMenuTitle } from '../../SideMenu/utils'
import { sideMenuIcons } from '../../SideMenuIcons/constants'

const MainMenu: React.FC<MenuPageProps> = ({ onClose, pages, parentPage, setPage, t }) => {
  const { palette } = useTheme()

  return (
    <DrawerHeaderWrapper
      header={
        <Header
          middleContent={
            <HeaderLabel mainText={t('navigation.sideMenu.header')} subText={getMainMenuTitle(parentPage, t)} />
          }
          endContent={<CloseButton onClick={onClose} />}
        />
      }
      footer={<NewAbsenceButton t={t} onClick={() => setPage('newAbsenceRequest')} />}
    >
      {pages.map((page) => {
        const Icon = sideMenuIcons[page]

        return (
          <ContentCard
            key={page}
            header={{
              icon: <Icon color={palette.primary.main} size={ICON_SIZE.small} />,
              title: t(SIDE_MENU_TRANSLATIONS[page as MenuOptions]),
              showChevron: true,
            }}
            onClick={() => setPage(page)}
          />
        )
      })}
    </DrawerHeaderWrapper>
  )
}

export default MainMenu
