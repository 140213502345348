import React from 'react'
import SafeSpace from '../SafeSpace'
import { Backdrop, ModalAncor, StyledDrawer } from './styles'
import type { FullPageDrawerProps } from './types'

const FullPageDrawer: React.FC<FullPageDrawerProps> = ({ onClose, open, children, rootId }) => (
  <StyledDrawer anchor="right" open={open} onClose={onClose}>
    <Backdrop>
      <ModalAncor id={rootId}>
        <SafeSpace scrollOverflow>{children}</SafeSpace>
      </ModalAncor>
    </Backdrop>
  </StyledDrawer>
)

export default FullPageDrawer
