import type { TeamT, TeamWithUserPermissions } from 'types'
import type { GetAbsenceManagerTeamsValues } from './types'

export const getAbsenceManagerTeams = ({ isAbsenceManager, ownerMemberTeams }: GetAbsenceManagerTeamsValues) => {
  if (!isAbsenceManager) {
    return { teamsWithRights: [], teamsWithoutRights: [] }
  }

  return ownerMemberTeams.reduce<{
    teamsWithRights: TeamT[]
    teamsWithoutRights: TeamT[]
  }>(
    (acc, team) => {
      const teamWithUserPermissions = team as TeamWithUserPermissions

      if (teamWithUserPermissions.userPermissions && teamWithUserPermissions.userPermissions.absenceManager) {
        return {
          teamsWithRights: [...acc.teamsWithRights, team],
          teamsWithoutRights: acc.teamsWithoutRights,
        }
      }

      return {
        teamsWithRights: acc.teamsWithRights,
        teamsWithoutRights: [...acc.teamsWithoutRights, team],
      }
    },
    { teamsWithRights: [], teamsWithoutRights: [] },
  )
}
