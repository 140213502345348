import { PersonDayShiftParamsT } from 'hooks/usePersonDayShifts'
import type { SelectedShiftDataT, AbsencePlannerBadgeTypesT } from 'pages/AbsencePlanner/types'
import type { ShiftsByTenantCCT, PlannedShiftT, ShiftTypeT } from 'types'
import { newDateWithoutTime } from 'utils/date'

export const getTenantShiftsUpdateTaskIds = (tenantShifts?: ShiftsByTenantCCT[]) => {
  const taskIdSet = (tenantShifts ?? []).reduce((acc, tenant) => {
    const shifts = tenant.shifts as PlannedShiftT[]
    shifts.forEach((shift) => {
      if (shift.updateTaskId) {
        acc.add(shift.updateTaskId)
      }
    })

    return acc
  }, new Set<number>())

  return [...taskIdSet]
}

export const getPersonDayShiftParams = (
  data: SelectedShiftDataT | null,
  shownTypes: AbsencePlannerBadgeTypesT[],
): PersonDayShiftParamsT => {
  const shiftTypes = shownTypes.filter((type) => type !== 'absenceRequest').map((type) => type as ShiftTypeT)

  return {
    date: data ? data.date : newDateWithoutTime().toISOString(),
    personId: data ? Number(data.person.id) : undefined,
    types: shiftTypes,
  }
}
