import React from 'react'
import { useTheme } from '@mui/material'
import { ChevronRight } from 'redesign/components/atoms/Icon'
import IconAndTextBlock from '../IconAndTextBlock'
import { Container } from './styles'
import type { HeaderBlockProps } from './types'

const HeaderBlock: React.FC<HeaderBlockProps> = ({
  icon,
  showChevron,
  title,
  component,
  overflowSingleLine = false,
  titleTestId,
}) => {
  const theme = useTheme()

  return (
    <Container>
      <IconAndTextBlock titleTestId={titleTestId} icon={icon} title={title} overflowSingleLine={overflowSingleLine} />
      {showChevron ? <ChevronRight color={theme.palette.primary.main} /> : component}
    </Container>
  )
}

export default HeaderBlock
