import React, { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import ActionButtons from 'components/Buttons/ActionButtons'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useModule from 'hooks/useModule'
import useRolesViews from 'hooks/useRolesViews'
import useStateBackLink from 'hooks/useStateBackLink'
import { headerClasses } from 'pages/UserSettings/styles'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { SettingsPushNotificationsOptionsT } from 'types'
import useCms from '../../../../hooks/useCms'
import DisableDialog from './components/DisableDialog'
import NotificationsSettingsSection from './components/Section'
import { adminSettings, cmsSettings, communicationSettings, documentsSettings, workingHoursSettings } from './constants'
import { classes } from './styles'
import useNotificationSettings from './useNotificationSettings'

type SectionConfigT = {
  title: string
  settings: SettingsPushNotificationsOptionsT[]
  condition: boolean
  changeInApp?: boolean
}

const NotificationSettingsPage: React.FC = () => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { hasOfficeView } = useRolesViews()
  const { hasAccessToModule } = useModule()
  const { cmsAccess } = useCms()

  const { pushBackLinkOrDefault } = useStateBackLink()
  const {
    pushSwitchStates,
    inAppSwitchStates,
    loading,
    dialogOpen,
    handlePushChange,
    handleInAppChange,
    handleClose,
    disableAnyway,
    onSubmit,
  } = useNotificationSettings()

  const filterSettings = useCallback(
    (settingsList: { key: SettingsPushNotificationsOptionsT; module: string }[]) =>
      settingsList.filter(({ module }) => hasAccessToModule(module)).map(({ key }) => key),
    [hasAccessToModule],
  )

  const adminSettingsFiltered = useMemo(() => filterSettings(adminSettings), [filterSettings])
  const cmsSettingsFiltered = useMemo(() => filterSettings(cmsSettings), [filterSettings])
  const communicationSettingsFiltered = useMemo(() => filterSettings(communicationSettings), [filterSettings])
  const documentsSettingsFiltered = useMemo(() => filterSettings(documentsSettings), [filterSettings])
  const workingHoursSettingsFiltered = useMemo(() => filterSettings(workingHoursSettings), [filterSettings])

  const sections = useMemo(
    (): SectionConfigT[] => [
      {
        title: t('page.notificationsSettings.section.workingHours'),
        settings: workingHoursSettingsFiltered,
        condition: Boolean(workingHoursSettingsFiltered.length),
      },
      {
        title: t('page.notificationsSettings.section.documents'),
        settings: documentsSettingsFiltered,
        condition: Boolean(documentsSettingsFiltered.length),
      },
      {
        title: t('page.notificationsSettings.section.communication'),
        settings: communicationSettingsFiltered,
        condition: Boolean(communicationSettingsFiltered.length),
      },
      {
        title: t('page.notificationsSettings.section.admin'),
        settings: adminSettingsFiltered,
        condition: hasOfficeView && Boolean(adminSettingsFiltered.length),
        changeInApp: true,
      },
      {
        title: t('page.notificationsSettings.section.cms'),
        settings: cmsSettingsFiltered,
        condition: cmsAccess && Boolean(cmsSettingsFiltered.length),
      },
    ],
    [
      t,
      workingHoursSettingsFiltered,
      documentsSettingsFiltered,
      communicationSettingsFiltered,
      adminSettingsFiltered,
      hasOfficeView,
      cmsSettingsFiltered,
      cmsAccess,
    ],
  )

  return (
    <Page
      header={
        <BackwardLink
          onClickIcon={() => {
            pushBackLinkOrDefault(routes.userSettings)
          }}
          title={t('page.notifications.header.title')}
        />
      }
      sxHeader={headerClasses.subHeader}
      hideNativeNavigation
    >
      {loading ? (
        <SimpleLoading />
      ) : (
        <>
          <form onSubmit={onSubmit}>
            <Box sx={classes.sectionsContainer}>
              {sections.map(({ condition, settings, title, changeInApp }) =>
                condition ? (
                  <NotificationsSettingsSection
                    title={title}
                    settings={settings}
                    handlePushChange={handlePushChange}
                    pushSwitchStates={pushSwitchStates}
                    handleInAppChange={handleInAppChange}
                    inAppSwitchStates={inAppSwitchStates}
                    key={title}
                    changeInApp={changeInApp}
                  />
                ) : null,
              )}
              <ActionButtons
                cancelAction={() => history.push(routes.userSettings)}
                cancelLabel={t('page.userSettings.personalData.actions.cancel')}
                isFloating
                noNavigation
                isValidateSubmit
                validateLabel={t('page.userSettings.personalData.actions.save')}
              />
            </Box>
          </form>
          <DisableDialog handleClose={handleClose} dialogOpen={dialogOpen} disableAnyway={disableAnyway} />
        </>
      )}
    </Page>
  )
}

export default NotificationSettingsPage
