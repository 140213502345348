import React, { useMemo } from 'react'
import { useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import Popover from 'redesign/components/molecules/Popover'
import { formatDate } from 'redesign/shared/date'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import Drawer from '../Drawer'
import ShiftContent from '../ShiftContent'
import ShiftDialogHeader from './ShiftDialogHeader'
import { DrawerBody } from './styles'
import type { ShiftDialogProps } from './types'
import { useShiftDialog } from './useShiftDialog'

const ShiftDialog: React.FC<ShiftDialogProps> = ({
  dialogChildNode,
  selectedShift,
  shownTypes,
  onClose,
  cursorClickPosition,
  isUser,
  userIsAbsenceManager,
}) => {
  const {
    isPopoverOpen,
    isDrawerOpen,
    onCloseDialog,
    popoverOrigin,
    isSmallScreen,
    shiftsLoading,
    shiftUpdatesLoading,
    shifts,
    date,
    absenceRequests,
    shiftUpdates,
  } = useShiftDialog({
    selectedShift,
    shownTypes,
    onClose,
    cursorClickPosition,
    isUser,
    userIsAbsenceManager,
  })
  const { t } = useFavurTranslation()
  const { palette } = useTheme()

  const content = useMemo(
    () =>
      shiftsLoading || shiftUpdatesLoading ? (
        <LoadingSpinner />
      ) : (
        <ShiftContent
          shifts={shifts}
          selectedShift={selectedShift}
          absenceRequests={absenceRequests}
          date={date}
          onClickLink={() => {}}
          userIsAbsenceManager={userIsAbsenceManager}
          shiftUpdates={shiftUpdates}
        />
      ),
    [
      shiftsLoading,
      shiftUpdatesLoading,
      shifts,
      selectedShift,
      absenceRequests,
      date,
      userIsAbsenceManager,
      shiftUpdates,
    ],
  )

  if (isSmallScreen) {
    return (
      <Drawer
        open={isDrawerOpen}
        onClose={onCloseDialog}
        header={
          <ShiftDialogHeader
            title={selectedShift?.avatarData.avatarUserName || ''}
            subtitle={formatDate({ date, dateFormat: DATE_FORMAT_VARIANTS.longWithWeekday, t })}
            onClose={onCloseDialog}
            isSmallScreen
          />
        }
        showPuller
        backgroundColor={palette.common.backgroundApp}
      >
        <DrawerBody>{content}</DrawerBody>
      </Drawer>
    )
  }

  return (
    <Popover
      open={isPopoverOpen}
      onClose={onCloseDialog}
      dialogChildNode={dialogChildNode}
      popoverPosition={{
        anchorOrigin: popoverOrigin.anchorOrigin,
        transformOrigin: popoverOrigin.transformOrigin,
      }}
      title={
        <ShiftDialogHeader
          title={selectedShift?.avatarData.avatarUserName || ''}
          subtitle={formatDate({ date, dateFormat: DATE_FORMAT_VARIANTS.longWithWeekday, t })}
          onClose={onCloseDialog}
        />
      }
    >
      {content}
    </Popover>
  )
}

export default ShiftDialog
