import React, { useMemo } from 'react'
import { IconButton, useTheme } from '@mui/material'
import { ArrowLeftDoubleS, ArrowRightDoubleS } from 'icons'
import { ChevronLeftDuo } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import { avatarSideBarClasses } from './styles'
import type { ArrowIconProps } from './types'

const ArrowIcon: React.FC<ArrowIconProps> = ({ open, isRedesign, onClick }) => {
  const { palette } = useTheme()

  const Icon = useMemo(
    () => (open ? <ArrowLeftDoubleS fill={palette.primary.main} /> : <ArrowRightDoubleS fill={palette.primary.main} />),
    [open, palette.primary.main],
  )

  return (
    <IconButton
      onClick={onClick}
      sx={!isRedesign ? [avatarSideBarClasses.iconButton] : [avatarSideBarClasses.redesignedIcon]}
      disableRipple={!isRedesign}
    >
      {isRedesign ? (
        <ChevronLeftDuo color={palette.primary.main} size={ICON_SIZE.medium} rotate={open ? 0 : 180} />
      ) : (
        Icon
      )}
    </IconButton>
  )
}

export default ArrowIcon
