import React from 'react'
import { Typography } from '@mui/material'
import Switch from 'redesign/components/atoms/Switch'
import { Container, Group, HeaderWrapper, HelperText } from './styles'
import type { SwitchFormGroupProps } from './types'

const SwitchFormGroup: React.FC<SwitchFormGroupProps> = ({ options = [], disabled, error, helperText, legend }) => (
  <Container disabled={disabled} error={error}>
    {legend && (
      <HeaderWrapper>
        <Typography color="textPrimary" variant="overline">
          {legend}
        </Typography>
      </HeaderWrapper>
    )}
    <Group>
      {options.map((props) => (
        <Switch {...props} key={`${props?.key}`} />
      ))}
    </Group>
    {helperText && (
      <HelperText>
        <Typography variant="caption">{helperText}</Typography>
      </HelperText>
    )}
  </Container>
)

export default SwitchFormGroup
