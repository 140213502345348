import React from 'react'
import EventsSection from '../ShiftPlanSections/EventsSection'
import ShiftSection from '../ShiftPlanSections/ShiftSection'
import type { ShiftDataProps } from './types'
import { useShiftData } from './useShiftData'

const ShiftData: React.FC<ShiftDataProps> = ({ avatarData, date, events = [], shiftData, tenant }) => {
  const { groupedShifts, areThereShifts, showEvents, isPast } = useShiftData({
    avatarData,
    date,
    events,
    shiftData,
  })

  return (
    <>
      {showEvents && <EventsSection eventsLoading={false} events={events} date={date} />}
      {areThereShifts && (
        <ShiftSection
          date={date}
          isPast={isPast}
          shiftData={shiftData}
          tenant={tenant}
          absenceRequests={[]}
          groupedShifts={groupedShifts}
          onTaskClick={() => {}}
          showShiftUpdateAlert={false}
          shiftsSectionLoading={false}
          shiftUpdateDaysCount={0}
          inAbsencePlanner
        />
      )}
    </>
  )
}

export default ShiftData
