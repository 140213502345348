import { styled } from '@mui/material'

export const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
  height: 48px;
`

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`
