import { useCallback, useMemo } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'
import type { TeamT } from 'types'
import { getAbsenceManagerTeams } from './getAbsenceManagerTeams'
import { getTeamsSorted } from './getTeamsSorted'

export const useAbsenceTeamSettings = () => {
  const { isAbsenceManager } = useSideMenuContext()
  const { shownTeams, setShownTeams, ownerMemberTeams, ownerPublicTeams, isMultiTenant } = useFilterContext()
  const { t, language } = useFavurTranslation()

  const handleCheckChange = useCallback(
    (team: TeamT, show: boolean) =>
      setShownTeams(show ? shownTeams.filter((shownTeam) => shownTeam !== team.id) : shownTeams.concat(team.id)),

    [setShownTeams, shownTeams],
  )

  const showPublicTeams = ownerPublicTeams && ownerPublicTeams.length > 0

  const { teamsWithRights, teamsWithoutRights } = useMemo(
    () => getAbsenceManagerTeams({ isAbsenceManager, ownerMemberTeams }),
    [isAbsenceManager, ownerMemberTeams],
  )

  const managerTeamsOptions = {
    primaryTeams: {
      teams: getTeamsSorted({ teams: teamsWithRights, language, shownTeams, handleCheckChange, isMultiTenant }),
      title: t('absencePlanner.taskbar.filter.showTeamsManager'),
    },
    secondaryTeams: {
      teams: getTeamsSorted({
        teams: [...teamsWithoutRights, ...ownerPublicTeams],
        language,
        shownTeams,
        handleCheckChange,
        isMultiTenant,
      }),
      title: t('absencePlanner.taskbar.filter.showOtherTeamsManager'),
    },
  }

  const frontlinerTeamsOptions = {
    primaryTeams: {
      teams: getTeamsSorted({ teams: ownerMemberTeams, language, shownTeams, handleCheckChange, isMultiTenant }),
      title: t('absencePlanner.taskbar.filter.showTeams'),
    },
    secondaryTeams: {
      teams: getTeamsSorted({ teams: ownerPublicTeams, language, shownTeams, handleCheckChange, isMultiTenant }),
      title: t('absencePlanner.taskbar.filter.showOtherTeams'),
    },
  }

  const options = isAbsenceManager ? managerTeamsOptions : frontlinerTeamsOptions

  return {
    options,
    isAbsenceManager,
    showPublicTeams,
  }
}
