import React from 'react'
import { FormLabel, Typography } from '@mui/material'
import Radio from 'redesign/components/atoms/Radio'
import { Container, Group, HeaderWrapper } from './styles'
import type { RadioFormGroupProps } from './types'

const RadioFormGroup: React.FC<RadioFormGroupProps> = ({
  options = [],
  disabled,
  legend,
  selectedValue,
  setSelectedValue,
  size = 'medium',
  color = 'primary',
}) => (
  <Container disabled={disabled}>
    {legend && (
      <HeaderWrapper>
        <FormLabel component="legend">
          <Typography color="textPrimary" variant="overline">
            {legend}
          </Typography>
        </FormLabel>
      </HeaderWrapper>
    )}
    <Group>
      {options.map((option) => (
        <Radio
          key={option.value}
          size={size}
          color={color}
          value={option.value}
          label={option.label}
          checked={selectedValue === option.value}
          onChange={() => setSelectedValue(option.value)}
        />
      ))}
    </Group>
  </Container>
)

export default RadioFormGroup
