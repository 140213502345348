import React from 'react'
import FramedIcon from 'redesign/components/atoms/FramedIcon'
import { DocumentPDF } from 'redesign/components/atoms/Icon'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import Card from 'redesign/components/organisms/Card'
import ContentCard from '../../../organisms/ContentCard/ContentCard'
import { DOCUMENT_CARD_IDS } from './constants'
import { DocumentProps } from './types'
import { useDocument } from './useDocument'

const DocumentCard: React.FC<DocumentProps> = ({ document, openMonthlySheetDialog }) => {
  const { documentLabel, contentData, isNew, displaying, isViewing, displayFileCallback } = useDocument({
    document,
    openMonthlySheetDialog,
  })

  if (isViewing) {
    return (
      <Card>
        <LoadingSpinner />
      </Card>
    )
  }

  return (
    <ContentCard
      dataTestId={DOCUMENT_CARD_IDS.document}
      header={{
        icon: <FramedIcon icon={DocumentPDF} />,
        title: documentLabel,
        titleTestId: 'document-title',
      }}
      isNew={isNew}
      onClick={() => !displaying && displayFileCallback()}
      footerProps={{ ...contentData, mainTextVariant: 'body1', endTextVariant: 'body2' }}
    />
  )
}

export default DocumentCard
