import { css, styled } from '@mui/material'
import { Z_INDEX_DRAWER_HEADER } from 'redesign/shared/zIndex/constants'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor?: string }>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr auto;
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`

export const HeaderContainer = styled('div')`
  z-index: ${Z_INDEX_DRAWER_HEADER};
  background-color: ${({ theme }) => theme.palette.common.white};
  overflow-x: hidden;
`

export const ContentWrapper = styled('div')`
  width: 100%;
  overflow-y: scroll;
`
