import { IconButton, styled } from '@mui/material'

export const ShiftHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSmallScreen',
})<{ isSmallScreen: boolean }>`
  position: relative;
  width: 100%;
  min-height: ${({ isSmallScreen, theme }) => (isSmallScreen ? theme.spacing(7) : theme.spacing(9))};
  gap: ${({ theme }) => theme.spacing(2)};
  box-sizing: border-box;
`

export const TextContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`

export const DrawerBody = styled('div')`
  /* As drawer is setting these borders, it needs to be overwritten */
  & .MuiPaper-root {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    width: 100% !important;
    padding: 1rem !important;
    box-sizing: border-box;
  }
`
