import type { MenuOptions } from './types'

export const PARENT_PAGES = {
  shiftPlan: 'shiftPlan',
  absencePlan: 'absencePlan',
} as const

export const SIDE_MENU_OPTIONS = {
  mainMenu: 'mainMenu',
  events: 'events',
  personalAbsences: 'personalAbsences',
  adminAbsences: 'adminAbsences',
  personalShiftUpdates: 'personalShiftUpdates',
  adminShiftUpdates: 'adminShiftUpdates',
  settings: 'settings',
  newAbsenceRequest: 'newAbsenceRequest',
} as const

export const SIDE_MENU_TRANSLATIONS = {
  [SIDE_MENU_OPTIONS.mainMenu]: 'navigation.sideMenu.header',
  [SIDE_MENU_OPTIONS.events]: 'navigation.sideMenu.events',
  [SIDE_MENU_OPTIONS.personalAbsences]: 'navigation.sideMenu.absenceRequest.frontliner',
  [SIDE_MENU_OPTIONS.adminAbsences]: 'navigation.sideMenu.absenceRequest.manager',
  [SIDE_MENU_OPTIONS.personalShiftUpdates]: 'navigation.sideMenu.shiftUpdate.frontliner',
  [SIDE_MENU_OPTIONS.adminShiftUpdates]: 'navigation.sideMenu.shiftUpdate.manager',
  [SIDE_MENU_OPTIONS.settings]: 'page.shifts.sideMenu.settings',
  [SIDE_MENU_OPTIONS.newAbsenceRequest]: 'navigation.sideMenu.button.absenceRequest',
} as const

export const SHIFT_PLAN_CONFIGURATION: MenuOptions[] = [
  SIDE_MENU_OPTIONS.personalShiftUpdates,
  SIDE_MENU_OPTIONS.settings,
]

export const ADMIN_SHIFT_PLAN_CONFIGURATION: MenuOptions[] = [
  SIDE_MENU_OPTIONS.adminShiftUpdates,
  SIDE_MENU_OPTIONS.personalShiftUpdates,
  SIDE_MENU_OPTIONS.settings,
]

export const ABSENCE_PLAN_CONFIGURATION: MenuOptions[] = [
  SIDE_MENU_OPTIONS.personalAbsences,
  SIDE_MENU_OPTIONS.settings,
]

export const ADMIN_ABSENCE_PLAN_CONFIGURATION: MenuOptions[] = [
  SIDE_MENU_OPTIONS.adminAbsences,
  SIDE_MENU_OPTIONS.personalAbsences,
  SIDE_MENU_OPTIONS.settings,
]

export const SIDE_MENU_ROOT_ID = 'side-menu-root-id'
