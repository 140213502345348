import React from 'react'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import { ContentWrapper } from 'redesign/components/molecules/DrawerHeaderWrapper/styles'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import Header from '../../../organisms/Header'
import type { SubpageDetailProps } from '../types'

// TBD: Update component according to design specifications
const TaskDetail: React.FC<SubpageDetailProps> = ({ onClose, t, isDrawer, onGoBack }) => (
  <ContentWrapper>
    <Header
      startContent={<BackButton onGoBack={onGoBack} />}
      middleContent={<HeaderLabel mainText={t('absencePlanner.taskbar.frontlinerTasks.title')} />}
      endContent={isDrawer && <CloseButton onClick={onClose} />}
    />
  </ContentWrapper>
)

export default TaskDetail
